import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useFormikContext,useField } from "formik";

export default function RowRadioButtonsGroup({ name, values, label, ...otherProps }) {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();
  return (
    <FormControl  className="bg-white flex rounded">
      <FormLabel  id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup onChange={(event) => setFieldValue(name, event.target.value, true)} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
        {values?.map((x) => (
          <FormControlLabel className="text-black" value={x.id} control={<Radio />} label={x.value} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
