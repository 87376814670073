import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import FullContainer from "./FullContainer";
import Container from "./Container";

export default function index({ data }) {
  return (
    <FullContainer className="mt-12 lg:mt-16">
      <Container>
        <h2 className="heading1 mb-2">FAQs</h2>
        <div className="flex flex-col items-center w-full mt-5">
          {data.map((item, key) => {
            return (
              <Menu
                key={key}
                as="div"
                className="relative inline-block text-left w-full max-w-3xl mb-2 rounded-lg bg-footer bg-cover"
              >
                <div>
                  <Menu.Button className="w-full grid grid-cols-accordian items-center rounded-lg text-left p-3 px-5">
                    {item.question}
                    <ChevronDownIcon className="h-5 w-5 ml-5" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-1000"
                  enterFrom="transform opacity-0 translate-y-50"
                  enterTo="transform opacity-100 translate-y-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="rounded-b-lg">
                    <div className="flex flex-col items-center h-full">
                      <div className="w-full p-5 flex items-center justify-between">
                        {item.answer}
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            );
          })}
        </div>
      </Container>
    </FullContainer>
  );
}
