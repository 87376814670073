import React, { useState } from 'react';
import OpenAI from 'openai';

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

//console.log('key; ', process.env.REACT_APP_OPENAI_API_KEY)

const compatibilityCodeMQL5 = `
// Add these lines at the top of the EA code
#import "kernel32.dll"
int GetLastError(void);
#import

#import "Wininet.dll"
int InternetOpenW(string, int, string, string, int);
int InternetConnectW(int, string, int, string, string, int, int, int);
int HttpRequestW(int, string, string, int, string, int, string, int);
int InternetOpenUrlW(int, string, string, int, int, int);
int InternetReadFile(int, uchar &sBuffer[], int, int& OneInt);
int InternetCloseHandle(int);
bool DeleteUrlCacheEntryW(string);
#import

string webUrl="https://api.dantisai.com/license/verify/";  // Replace with your licensing API URL

// Add this function to the EA code
bool verify(string product)
{
   string content;
   long account = AccountInfoInteger(ACCOUNT_LOGIN);
   string request = webUrl+"?account="+IntegerToString(account)+"&product="+product;

   string cookie=NULL,headers;
   char post[],result[];
   int res=WebRequest("GET",request,cookie,NULL,500,post,0,result,headers);
   if(res==-1)
   {
      Print("Error in WebRequest. Error code  =",GetLastError());
      return false;
   }
   else
   {
      if(res==200)
      {
         content = CharArrayToString(result);
         if(content == "active")
         {
            return true;
         }
         else
         {
            return false;
         }
      }
      else
      {
         PrintFormat("Downloading '%s' failed, error code %d",request,res);
         return false;
      }
   }
}

// Modify the OnInit and OnTimer functions to call the verify function
int OnInit()
{
   if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
   {
      auth = verify(sample_product);
      if(auth == true)
      {
         Comment("Active");
      }
      if(auth == false)
      {
         Comment("Inactive. Contact Provider to Activate");
         ExpertRemove();
      }
   }
   EventSetTimer(1000);
   return(INIT_SUCCEEDED);
}

void OnTimer()
{
   if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
   {
      auth = verify(sample_product);
      if(auth == true)
      {
         Comment("Active");
      }
      if(auth == false)
      {
         Comment("Inactive. Contact Provider to Activate");
         ExpertRemove();
      }
   }
}
`;

const compatibilityCodeMQL4 = `
#property copyright "Copyright 2023, MetaQuotes Ltd."
#property link      "https://www.mql5.com"
#property version   "1.00"
#property strict

string webUrl="https://api.dantisai.com/license/verify/";  // Replace with your licensing API URL

// Add this function to the EA code
bool verify(string product)
{
   string content;
   long account = AccountNumber();
   string request = webUrl+"?account="+IntegerToString(account)+"&product="+product;

   string cookie=NULL,headers;
   char post[],result[];
   int res=WebRequest("GET",request,cookie,NULL,500,post,0,result,headers);
   if(res==-1)
   {
      Print("Error in WebRequest. Error code  =",GetLastError());
      return false;
   }
   else
   {
      if(res==200)
      {
         content = CharArrayToString(result);
         if(content == "active")
         {
            return true;
         }
         else
         {
            return false;
         }
      }
      else
      {
         PrintFormat("Downloading '%s' failed, error code %d",request,res);
         return false;
      }
   }
}

// Modify the OnInit and OnTimer functions to call the verify function
int OnInit()
{
   if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
   {
      bool auth = verify("sample_product");
      if(auth == true)
      {
         Comment("Active");
      }
      if(auth == false)
      {
         Comment("Inactive. Get or Renew License on Dantis AI to Activate");
         ExpertRemove();
      }
   }
   EventSetTimer(1000);
   return(INIT_SUCCEEDED);
}

void OnTimer()
{
   if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
   {
      bool auth = verify("sample_product");
      if(auth == true)
      {
         Comment("Active");
      }
      if(auth == false)
      {
         Comment("Inactive. Get or Renew License on Dantis AI to Activate");
         ExpertRemove();
      }
   }
}

bool isNewDay()
{
   static datetime newDayBar;
   datetime curbar = iTime(Symbol(),PERIOD_D1,0);
   if(newDayBar!=curbar)
   {
      newDayBar=curbar;
      return (true);
   }
   else
   {
      return (false);
   }
}`;

function EAModifier() {
  const [originalCode, setOriginalCode] = useState('');
  const [updatedCode, setUpdatedCode] = useState('');
  const [language, setLanguage] = useState('MQL5');

  async function modifyEACode() {
    const compatibilityCode = language === 'MQL5' ? compatibilityCodeMQL5 : compatibilityCodeMQL4;

    const completion = await openai.chat.completions.create({
      messages: [
        {"role": "system", "content": `You are a Metaquotes software developer and an ${language} expert at Dantis AI. `},
        {"role": "user", "content": `You are to make this customers ${language} code "${originalCode}" compatible with Dantis AI to manage their licensing. Here is Dantis AI's compatibility code "${compatibilityCode}", insert the right portions of the Dantis AI's compatibility code in the right places in the customer's code. Make the insertions in the code and show me the complete compilable code. Print out the whole code and do not use abbreviations or shortcuts.`},
        //{"role": "user", "content": `Here is my ${language} code: ${originalCode}. Please modify this code to add the following compatibility code in the appropriate places: ${compatibilityCode}`},
        //{"role": "user", "content": `Please modify this code to add the following compatibility code in the appropriate places: ${compatibilityCode}`},
      ],
      model: "gpt-4-turbo",
    });
    console.log(completion.choices[0].message.content);

    const updatedCode = completion.choices[0].message.content;

    setUpdatedCode(updatedCode);
  }

  function copyCode() {
    navigator.clipboard.writeText(updatedCode);
  }

  return (
    <div className='bg-glass' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '800px', width: '100%', margin: '0 auto', padding: '20px', borderRadius: '15px' }}>
      <h1 style={{ color: 'white', marginBottom: '20px', fontSize: '20px' }}>Make your Tool compatible with Dantis AI for FREE</h1>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
          <input type="radio" id="mql5" name="language" value="MQL5" checked={language === 'MQL5'} onChange={e => setLanguage(e.target.value)} />
          <label for="mql5" style={{ color: 'white'}}>MT5</label>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px' }}>
          <input type="radio" id="mql4" name="language" value="MQL4" checked={language === 'MQL4'} onChange={e => setLanguage(e.target.value)} />
          <label for="mql4" style={{ color: 'white'}}>MT4</label>
        </div>
      </div>
      <textarea 
        placeholder="Paste or type your code here..."
        onChange={e => setOriginalCode(e.target.value)} 
        style={{ color: 'black', width: '100%', minHeight: '200px', marginBottom: '20px', padding: '10px', borderRadius: '5px' }} 
      />
      <button className='capitalize btnSecondary5' style={{ color: 'white', backgroundColor: '#007BFF', padding: '10px 20px', borderRadius: '5px', marginBottom: '20px' }} onClick={modifyEACode}>Modify</button>
      <textarea 
        value={updatedCode} 
        readOnly 
        style={{ color: 'black', width: '100%', minHeight: '200px', padding: '10px', borderRadius: '5px' }} 
      />
      <button className=' btnSecondary3 mt-4' style={{ color: 'white',  padding: '10px 20px', borderRadius: '5px', marginBottom: '20px' }} onClick={copyCode}>Copy Code</button>
    </div>
  );
  
    
}

export default EAModifier;