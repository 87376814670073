import React from "react";
import { Container, FullContainer, Navbar } from "../components";
import ImageHoverChange from "../components/ImageHoverChange";

function Privacy() {
  return (
    <FullContainer className="mt-0">
      <Navbar />
      <img
        src="/img/hero.webp"
        className="absolute top-0 left-0 h-full w-full"
        alt=""
      />
      <Container>
        <div className="grid grid-cols-1 flex-col items-center justify-left">
          <div>
            <div className="flex flex-col items-center text-left">
              <h1 className="text-3xl font-bold uppercase my-12">Privacy Policy</h1>
              <p>
              Dantis AI is a publisher. You understand that no content published as part of the Dantis AI Trading Services constitutes a recommendation that any particular investment, security, portfolio of securities, transaction or investment strategy is suitable for any specific person. You further understand that none of the creators or providers of our Services or their affiliates will advise you personally concerning the nature, potential, value or 
              suitability of any particular investment, security, portfolio of securities, transaction, investment strategy or other matter. Accordingly, do not attempt to contact them seeking personalized investment advice, which they cannot provide. To the extent any of the content published as part of the Services may be deemed to be investment advice, such information is impersonal and not tailored to the investment needs of any specific person.<br/><br/>

              You understand that the views expressed in the Trading Services are the authors’ own opinions. The Trading Services may contain opinions from time to time with regard to securities mentioned in other Trading Services, and that those opinions may be different from those obtained by using another portion of the Trading Services. Trading in securities (including, without limitation, stocks, options, ETFs and bonds) involves risk and volatility. 
              Past results are not necessarily indicative of future performance. Neither Dantis AI nor any of its data or content providers shall be liable for any errors or delays in the content, or for any actions taken in reliance thereon. In addition as markets change continuously, previously published information and data may not be current and should not be relied upon.<br/><br/>

              You understand that performance data is supplied by sources believed to be reliable, that the calculations therein are made using such data, and that such calculations are not guaranteed by these sources, the information providers, or any other person or entity, and may not be complete. Dantis AI makes no representations regarding Third-Party Content, nor is Dantis AI liable for Third-Party Content.<br/><br/>

              Before selling or buying any investment, You should consult with a qualified broker or other financial professional to verify pricing information.<br/><br/>

              To the extent any of our Trading Services involves a model portfolio or an actual portfolio of investments, as described below, such portfolio of investments is chosen by the author in accordance with their stated investment strategy. Your actual results may differ from results reported for the portfolio for many reasons, including, without limitation:<br /><br/>

              -Performance results for the portfolio do not reflect actual trading commissions that you may incur; <br /><br/>
              -Performance results for the portfolio do not account for the impact, if any, of certain market factors, such as lack of liquidity, that may affect your results; <br /><br/>
              -The investments chosen for the portfolio may be volatile, and server, delivery delays and other factors may cause the price you obtain to differ substantially from the price at the time of the alert;
              you may not have the capital to trade as frequently as the portfolio; <br /><br/>
              -The size and timing of a subscriber’s purchase or sale of a stock may affect the price of the stock. <br /><br/>
              -Certain of our products may contain a model portfolio that provides investment ideas and/or provides information regarding investments. Each product is available for informational purposes only. The information, research, and opinions contained in our products have been obtained or derived from sources believed to be reliable, 
              but we cannot guarantee its accuracy and completeness nor the opinions based thereon. You should not rely solely upon the research herein for purposes of transacting securities or other investments, and you are encouraged to conduct your own research and due diligence, and to seek the advice of a qualified securities professional 
              before you make any investment. None of the information contained in our products constitutes, or is intended to constitute a recommendation by us of any particular security or trading strategy or a determination by us that any security or trading strategy is suitable for any specific person. Investing in the stocks chosen in any of Dantis AI’ products is risky and speculative. 
              Trading in any security can result in immediate and substantial losses of the money invested. It is recommended that you only invest funds that are not allocated for other purposes, such as retirement savings, student loans, mortgages, education, or debt reduction.
              </p>
            </div>
          </div>

          <ImageHoverChange
            className="mx-auto flex"
            image={"/img/ABOUT-PIC.webp"}
            hoverImage="/img/ABOUT-PIChover.webp"
          />
        </div>
      </Container>
    </FullContainer>
  );
}

export default Privacy;
