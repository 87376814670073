import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../../store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaTrash } from 'react-icons/fa';
import CheckoutForm from "../Stripe/CheckoutForm";
import formatUnixTimestamp from "../../utils/functions";
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);
const LicenseList = [
  {
    id: "1",
    product_name: "10",
    allowed_account: "1201"
  },
  {
    id: "1",
    product_name: "10",
    allowed_account: "1201"
  },
  {
    id: "1",
    product_name: "10",
    allowed_account: "1201"
  },
]


function Market() {
  const { token } = store.getState().auth;
  const [Licenses, setLicenses] = useState(LicenseList);
  console.log('Licenses',Licenses);
  const [inputValue, setInputValue] = useState('');
  const [customerInfo, setCustomerInfo] = useState({});
  console.log("Here is customer info", customerInfo);
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [expireDate, setExpireDate] = useState(null);
  const history = useHistory();

  const getUserLicenses = () => {
    console.log("Token: ", token);
    axios.get(`${process.env.REACT_APP_API_URL}/license/license`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log('This is license data', response.data);
        console.log("Licenses fetched successfully");

        // Fetch product details for each license
        const licensesWithProductNames = response.data.map(license => {
          return axios.get(`${process.env.REACT_APP_API_URL}/product/products/${license.product_code}`, {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          })
            .then(productResponse => {
              console.log(`Product details for license ${license.license_code}:`, productResponse.data);
              // Add the product_name to the license
              license.product_name = productResponse.data.product_name;
              license.product_file = productResponse.data.product_file;
              license.admin_id = productResponse.data.admin_id;

              // Fetch subscription information for each license
              return fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionInformation`, {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                  authorization: token,
                },
                body: JSON.stringify({
                  subscriptionId: license.subscriptionId,
                }),
              })
                .then(res => res.json())
                .then(data => {
                  console.log(`Subscription information for license ${license.license_code}:`, data);
                  // Set expiration date for the license
                  license.expireDate = data.current_period_end;
                  return license;
                })
                .catch(error => {
                  console.error("There was an error fetching the subscription information!", error);
                  return license;
                });
            })
            .catch(error => {
              console.error("There was an error fetching the product details!", error);
              return license;
            });
        });

        // Wait for all product and subscription details to be fetched, then update the state
        Promise.all(licensesWithProductNames)
          .then(licenses => {
            setLicenses(licenses);
          });
      })
      .catch(error => {
        console.error("There was an error fetching licenses!", error);
      });
  };


  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        // Update state with customer info
        setCustomerInfo(response.data);
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
      });
  }, []);

  /*const getUserLicenses = () => {
    console.log("Token: ", token);
    axios.get(`${process.env.REACT_APP_API_URL}/license/license`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log(response.data);
        console.log("licenses fetched successfully");
        setLicenses(response.data);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  }*/
  // Initialize an empty object for input values
  const [inputValues, setInputValues] = useState({});

  const handleAccountUpdate = (license_code) => {
    // Implement your edit logic here
    console.log(`Editing product at index ${license_code}`);
    axios.put(`${process.env.REACT_APP_API_URL}/license/license`,
      {
        license_code: license_code,
        account_number: inputValues[license_code]
      }, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log(response.data)
        if (!response.data.error) {
          alert(`Product License ${response.data}`);
        }
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    // Fetch products from the database when the component mounts
    getUserLicenses();
  }, []);


  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAccountUpdate();
    }
  };
  // Function to handle license cancellation
  const cancelLicense =async (license) => {
    // console.log(license.subscriptionId);
    const { token, account } = store.getState().auth;
    const subscriptionId = license.subscriptionId;
    

    const  confirm = window.confirm("Are you sure you want to cancel this license ? If you confirm license will deleted and it cannot be undone.");

    if(confirm){
      console.log(subscriptionId);
      axios.post(`${process.env.REACT_APP_API_URL}/deleteSubscription`,
      {subscriptionId}
      , {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      })
        .then(response => {
          window.alert(response.data.message);
          console.log(response.data.canceledSubscription);
        })
        .catch(error => {
          console.error("There was an error!", error);
          alert('Failed to create license!');
        });
    }


    // axios.delete(`{process.env.REACT_APP_API_URL}/license/license/${license_id}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     authorization: token,
    //   },
    // })
    //   .then(response => {
    //     console.log('Success:', response.data);
    //     // Update LicenseInfo or handle response as needed
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
  };

  // stripe
  // stripe handler
  const [clientSecret, setClientSecret] = useState("");
  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#212d63',
      colorText: '#ffffff',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  const startPaymentProcess = async () => {

    setShowModal(true)
    await fetch(`${process.env.REACT_APP_API_URL}/create-cloud-subscription`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
      body: JSON.stringify({
        email: customerInfo.email,
        promotekit_referral: window.promotekit_referral, // promo referral added
      }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);

        console.log('this is new account data', clientSecret)
      }).catch((err) => {
        // setProcess(false)
      })


  };


  // saved card handle
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [productData, setProductData] = useState({});
  console.log('Saved payment methods', savedPaymentMethods);
  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };
  const stripe = useStripe();
  const handlePayWithSavedCard = async (id) => {
    const result = window.confirm("Are you sure you want pay with this card , This cannot be undone?");

    if (result === true) {
      // User clicked OK
      console.log(id);

      await fetch(`${process.env.REACT_APP_API_URL}/create-cloud-subscription-saved-card`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          email: customerInfo.email,
          promotekit_referral: window.promotekit_referral, // promo referral added
          paymentMethodId: id,
        }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
      })
        .then((res) => res.json())
        .then( async (data) => {
          setClientSecret(data.clientSecret);
          if (
            data.clientSecret
          ) {
            const { paymentIntent } = await stripe.handleCardPayment(data.clientSecret);
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              alert("Error: " + result.error.message);
              //console.log(result.error.message);

              // this.setState({
              //     isLoading: false
              // })
            } else {
              // The payment has been processed!
              if (paymentIntent.status === 'succeeded') {
            
                window.alert("Successfully purchased")
                setShowModal(false);
                history.push("/customer/cloudsetup");
              
              }
            }
            
          }
          console.log('this is new account data', clientSecret)
        }).catch((err) => {
          // setProcess(false)
        })



    } else {
      // User clicked Cancel or closed the dialog
      // Do nothing or handle accordingly
    }
  };

  const renderCardIcon = (brand) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return <FaCcVisa size={30} />;
      case 'mastercard':
        return <FaCcMastercard size={30} />;
      case 'amex':
        return <FaCcAmex size={30} />;
      default:
        return null;
    }
  };
  // Function to handle payment with saved card
  // 
  //

  const takeToCloud = async () => {
    // Navigate to the "Pop-up" page
    if (customerInfo.cloudSubscriptionStatus === true){
      history.push("/customer/cloudsetup");
    }else{
     await startPaymentProcess();
    }
  };

  const takeToCloud1 = (adminId) => {
    // Navigate to the "community" page
      history.push("/customer/commview", { adminId: adminId });
  };


  /*const handleDownload = (filePath) => {
    window.open(filePath);
  };*/
  /*const handleDownload = (filePath) => {
    // Construct the full URL to the file
    console.log(filePath);
    const fileUrl = `${process.env.REACT_APP_API_URL}/download/${filePath}`;

    // Create an anchor element
    const link = document.createElement('a');
    link.href = fileUrl;

    // Set the download attribute
    link.download = filePath?.split('/').pop(); // Use the file name from the filePath

    // Append the anchor element to the body
    document.body.appendChild(link);

    // Programmatically click the anchor element
    link.click();

    // Clean up: remove the anchor element from the body
    document.body.removeChild(link);
  };*/
  
  const handleDownload  = (path, urlPrefix = `${process.env.REACT_APP_API_URL}/download/`) => {
    console.log(path);
    const timestamp = Date.now();
    const fileUrl = `${urlPrefix}${path}?nocache=${timestamp}`;
  
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = path?.split('/').pop();
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*const handleDownload1 = (path = '/DantisLicense', urlPrefix = `${process.env.REACT_APP_API_URL}`) => {
    console.log(path);
    const timestamp = Date.now();
    const fileUrl = `${urlPrefix}${path}?nocache=${timestamp}`;
  
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = path?.split('/').pop();
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };*/
  const handleDownload1 = (paths = ['/DantisLicense', '/DantisLicensemt4'], urlPrefix = `${process.env.REACT_APP_API_URL}`) => {
    paths.forEach(path => {
      console.log(path);
      const timestamp = Date.now();
      const fileUrl = `${urlPrefix}${path}?nocache=${timestamp}`;
  
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = path?.split('/').pop();
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  
  
  

  return (
    <>
      <table className='w-full'>
      <thead className='bg-glass'>
        <tr>
          <th className='text-left'>Product Name</th>
          <th className='text-left'>Use Dantis Cloud</th>
          <th className='text-left'>Creator Community</th>
          <th className='text-left'>Allowed Account</th>
          <th className='text-left'>Edit Account ID</th>
          <th className='text-left'>Cancel</th>
          <th className='text-left'>Download file</th>
          <th className='text-left'>Expires On</th>
        </tr>
      </thead>
      <tbody>
        {Licenses.map((license, index) => (
          <tr key={index} className='bg-dsecondray'>
            <td className='p-6'>{license.product_name}</td>
            <td className='p-6'>
            <button type="button" className='capitalize btnSecondaryShadow animate-pulse' onClick={() => {
              alert("Please Add a new setup to deploy this tool!");
              takeToCloud();
            }}>
                Deploy
              </button>
            </td>
            <td className='p-6'>
            <button type="button" className='capitalize btnSecondary' onClick={() => takeToCloud1(license.admin_id)}>
              Community
            </button>
            </td>
            <td className='p-6'>{license.allowed_account}</td>
            <td className='p-6'>
              <form>

                <input
                  type="text"
                  style={{ color: 'black' }}
                  // Use the license_id as the key to get the corresponding input value
                  value={inputValues[license.license_id] || ''}
                  onChange={e => {
                    // Update the input value for this license_id
                    setInputValues({
                      ...inputValues,
                      [license.license_id]: e.target.value
                    });
                  }}
                  onKeyDown={handleKeyPress}
                />

                <br />
                <button type="button" className='btnSecondary4' onClick={() => handleAccountUpdate(license.license_id)}>
                  Submit
                </button>
              </form>
            </td>
            <td className='p-6'>
              <button
                type="submit" className='btnSecondary3' onClick={()=>cancelLicense(license)}>
                Cancel license
              </button>
            </td>
            <td className='p-6'>
              <button
                type="submit" className='btnSecondary3' onClick={() => {
                  handleDownload(license.product_file);
                  setTimeout(() => {
                    handleDownload1();
                  }, 5000); // Wait for 5 seconds before starting the second download
                }}>
                Download
              </button>
            </td>
            <td className='p-6'>
              {formatUnixTimestamp(license.expireDate)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
      {/* Payment modal */}
      {showModal ? (
        <>
          {/* Backdrop */}
          <div className="fixed inset-0 z-50 bg-black opacity-25"></div>

          {/* Modal container */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto flex-wrap fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-lg">
              {/* Modal content */}
              <div className="border border-gray-200 bg-[#1e142e] rounded-lg shadow-lg outline-none focus:outline-none">
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b border-solid border-gray-200 rounded-t">
                  <h3 className="text-xl md:text-2xl font-semibold">Make Payment</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 hover:text-black hover:bg-gray-100 rounded-full focus:outline-none focus:bg-gray-100 focus:text-black"
                    onClick={() => setShowModal(false)}
                  >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                {/* Body */}
                <div className="p-4">
                  <div className="mb-4">
                    {/* Payment method selection */}
                    <div className="flex flex-col space-y-2">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                        onClick={() => {
                          handlePaymentMethodSelect('saved')
                          fetch(`${process.env.REACT_APP_API_URL}/getPaymentMethods`, {
                            method: "POST",
                            headers: {
                              "content-type": "application/json",
                              authorization: token,
                            },
                            body: JSON.stringify({

                              email: customerInfo.email,

                            }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
                          })
                            .then((res) => res.json())
                            .then((data) => {
                              if (data.paymentMethods.length === 0) {
                                window.alert("No payment methods,Please pay with new card")

                              }
                              setSavedPaymentMethods(data.paymentMethods)


                            }).catch((err) => {
                              // setProcess(false)
                            })
                        }}
                      >
                        Pay with saved card
                      </button>
                      <button
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                        onClick={() => handlePaymentMethodSelect('new')}
                      >
                        Pay with new card
                      </button>
                      {
                        selectedPaymentMethod === null ? <></> :
                          <>
                            <button
                              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                              onClick={() => handlePaymentMethodSelect(null)}
                            >
                              Back
                            </button>
                          </>
                      }

                    </div>

                    {/* Saved card selection */}
                    {/* Saved card selection */}
                    {selectedPaymentMethod === 'saved' && (
                      <div className="flex flex-col space-y-2 mt-4">
                        {savedPaymentMethods?.map((method) => (
                          <div
                            onClick={() => {
                              handlePayWithSavedCard(method.id)

                            }}
                            className="border justify-between flex space-x-3  items-center cursor-pointer p-1 rounded-md border-l-white" key={method.id}>
                            {renderCardIcon(method.brand)} {/* Render card icon */}

                            <span className="">Ending with {method.last4} Expires at {method.expMonth}/{method.expYear}</span>
                            {/* <button> <FaTrash></FaTrash></button> */}
                          </div>



                        ))}
                      </div>
                    )}
                  </div>
                  {/* Payment form for new card */}
                  {selectedPaymentMethod === 'new' && clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm clientSecretNew={clientSecret} email={customerInfo.email} userName={customerInfo.first_name} />
                    </Elements>
                  )}
                </div>

                {/* Footer */}
                <div className="flex items-center justify-end p-4 border-t border-solid border-gray-200 rounded-b">
                  <button
                    className="text-red-500 bg-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-2 rounded hover:bg-gray-100 focus:bg-gray-100"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
    
  );

}

export default Market;