import { Link } from "react-router-dom";
import { FullContainer } from "../components";
import { useState } from 'react';

export default function Broker() {
  const [selectedLogo, setSelectedLogo] = useState(null);
  return (
    <FullContainer>
      <div className="grid lg:grid-cols-2 w-full min-h-screen">
        <div className="bg-broker bg-cover bg-center"></div>
        <div className="flex flex-col items-center justify-center p-10">
          <p className="heading flex items-center gap-2">
            Second Step <img src="/img/step2.webp" alt="" />
          </p>
          <h1 className="heading1 mt-5 capitalize text-center">
            Select a Platform
          </h1>

          <h5 className="mt-6 mb-6 text-sm text-center">Click <strong className="heading3 mt-5 capitalize">DANTIS AI</strong> to use our proprietary Systems or Click <strong className="heading3 mt-5 capitalize">DANTIS LICENSING</strong> for all other Products:</h5>
          <div className="flex gap-x-10 mt-5 justify-center">
            <img
              src="/img/DantisAi.png"
              alt="Dantis AI"
              className={`logo ${selectedLogo === 'windows' ? 'selected' : ''}`}
              onClick={() => setSelectedLogo('windows')}
            />
            <img
              src="/img/DantisLicense.png"
              alt="Dantis Licensing"
              className={`logo ${selectedLogo === 'mac' ? 'selected' : ''}`}
              onClick={() => setSelectedLogo('mac')}
            />
          </div>
          <Link className="mt-10 btnPrimary" to={selectedLogo === 'windows' ? "/login" : "/porc"}>Next</Link>
          <Link className="mt-5 text-primary underline" to="/">Go back</Link>
        </div>
      </div>
    </FullContainer>
  );
}
