import { Link, Navigate } from "react-router-dom";
import Textfield from "./TextField";
import Button from "./Button";
import BasicSelect from "./Dropdown";
import DatePicker from "./DatePicker";
import RadioButton from "./RadioButton";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import countryList from "react-select-country-list";

import { useState, useMemo } from "react";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";

const INITIAL_FORM_STATE_TEST = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  password: "",
  repassword: "",
  country: "",
  toggle: false, // Add this line
};

const FORM_VALIDATION = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  phone_number: Yup.string(),
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  repassword: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  toggle: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'), // Add this line
});

export default function CustomerSignup({ showPopup }) {
  const history = useHistory();
  const countries = countryList()
    .getData()
    .map((x) => {
      return { id: x.value, value: x.label };
    });

  return (
    <div>
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE_TEST,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, actions) => {
          console.log("Form Values:", values);

          // Check if all required fields are filled
          if (!values.first_name || !values.last_name || !values.email || !values.password || !values.phone_number || !values.country || values.toggle === undefined) {
            alert("Please fill all required fields");
            return;
          }

          // Check if phone number is in international format
          if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(values.phone_number)) {
            alert("Please provide phone number in international format");
            return;
          }

          // Check if terms and conditions are accepted
          if (!values.toggle) {
            alert("Kindly accept terms and conditions");
            return;
          }

          // Check if password meets the required validation format
          const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(values.password);
          if (!passwordValidation) {
            alert("Password must contain at least one uppercase, one lowercase, one special character (!@#$%^&*) and one number.");
            return;
          }

          // Check if passwords match
          if (values.repassword != values.password) {
            alert("Unmatched password");
            return;
          }

          // If all checks pass, send the request to the server
          console.log("Axios Request Data:", {
            url: `${process.env.REACT_APP_API_URL}/partner/pcsignup`,
            data: values,
          });

          const response = await axios
            .post(`${process.env.REACT_APP_API_URL}/partner/pcsignup`, values)
            .then(function (res) {
              console.log(res)
              if (!res.data.error) {
                alert("Signed up, please login now");
                history.push("/partner/login");
              } else {
                alert(res.data.error)
              }
            })
            .catch(function (err) {
              alert(JSON.stringify(err.response.data))
            });

          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        }}

      >
        <Form className='flex items-center justify-center flex-col w-full'>
          <div className='grid grid-cols-2 w-full gap-y-1 gap-x-3'>
            <Textfield name='first_name' label='First Name' />
            <Textfield name='last_name' label='Last Name' />
            <Textfield name='phone_number' label='Phone Number' />
            <BasicSelect name='country' label='Country' values={countries} />
          </div>
          <div className='grid grid-cols-1 gap-y-1 gap-x-1 w-full mt-1'>
            <Textfield name='email' label='Email' />
          </div>
          <div className='grid grid-cols-2 gap-y-1 gap-x-3 w-full mt-1'>
            <Textfield name='password' label='Password' type='password' />
            <Textfield name='repassword' label='Re-enter Password' type='password' />
          </div>
          <label>
            <Field type="checkbox" name="toggle" />
            <a href="url">Term and Conditions</a>
          </label>
          <Button className='mt-5 btnPrimary' type='submit'>
            Sign Up
          </Button>
        </Form>
      </Formik>
      <div className='text-center mt-3 capitalize'>
        Already have an account?{" "}
        <span>
          <Link
            to='/plogin'
            onClick={showPopup}
            className='text-primary underline'>
            Login
          </Link>{" "}
        </span>
      </div>
      <div className='text-center mt-3 capitalize'>
        <span>
          <Link to='/' onClick={showPopup} className='text-primary underline'>
            Go back
          </Link>{" "}
        </span>
        to home page
      </div>
    </div>
  );
}
