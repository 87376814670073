import { Link } from "react-router-dom";

export default function Start() {
  return (
    <div className="bg-start full-container">
      <h1 className="heading1 capitalize">
        welcome to <span className="text-primary">Dantis AI</span> 
      </h1>
      <img
        src="/img/ic_round-downloading.png"
        className="img-responsive mt-5"
        alt=""
      />
      <Link className="btnPrimary mt-5" to="/">Let's go</Link>
    </div>
  );
}

/*
import { Link } from "react-router-dom";
import { FullContainer } from "../components";

export default function Start() {
  return (
    <FullContainer className="w-full h-screen bg-start bg-cover bg-center lg:justify-end py-28">
      <h1 className="heading1 capitalize">
        welcome to <span className="text-primary">Dantis AI</span> 
      </h1>
      <img src="/img/ic_round-downloading.png" className="mt-5 max-w-full h-auto" alt="" />
      <Link className="btnPrimary mt-5" to="/">Let's go</Link>
    </FullContainer>
  );
}
*/