import React from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "./PartnerDropdown";
import { PartnerSideMenu } from "./PartnerMenu";
import { FiSettings } from "react-icons/fi";
import { BsChatDots } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import authSlice from "../../../store/slices/auth";
import store from "../../../store";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

const PartnerSidebar = ({ show_navlabel }) => {
  // Filter out the items you don't want to display
  const filteredMenu = PartnerSideMenu.filter(
    (item) => item.to !== "/dashboard/profit" && !item.to.includes("dantis-ai")
  );

  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  // Function to handle logout
  const handleLogout = () => {
    dispatch(authSlice.actions.Logout());
    history.push("/");
    console.log("handle Logout Finished");
  };

  // Keep track of the active menu item even on page refreshes
  useEffect(() => {
    const path = location.pathname;
    const label = PartnerSideMenu.find((item) => item?.to == path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, [location.pathname]);

  return (
    <>
      <div className='px-2 pt-7 bg-sidebar bg-cover bg-center'>
      <img className='mx-auto' src='/img/Dantis-logo.png' alt='' />
        <div className='mt-10 flex flex-col justify-between h-screen'>
          <div>
        {/* Use filteredMenu instead of sideMenu in the map function */}
        {PartnerSideMenu.map((item, key) => {
          const { label, to, Icon, children, onClick } = item;
          const isActive = activeMenuItem === item.label;
          return (
            <div key={key} className='sidebar-item'>
              {item?.children ? (
                <Dropdown
                  show_navlabel={show_navlabel}
                  label={label}
                  activeMenuItem={activeMenuItem}
                  setActiveMenuItem={setActiveMenuItem}
                  to={to}
                  Icon={Icon}
                  options={children}
                />
              ) : (
                <Link
                  className={`pageLink ${isActive ? "bg-dprimary" : ""}`}
                  title={label}
                  to={to}
                  onClick={() => setActiveMenuItem(item.label)}>
                  <Icon />
                  {show_navlabel && <span className='ml-3'>{label}</span>}
                </Link>
              )}
              </div>
            );
          })}
        </div>

        <div>
          <div>
            <Link class='pageLink'>
              <BsChatDots /> <span className='ml-3'>Support </span>
            </Link>
          </div>

          {/*<div>
            <Link class='pageLink'>
              <FiSettings /> <span className='ml-3'>Account Setting</span>
            </Link>
          </div>*/}

          <div>
            <Link class='pageLink text-red-400'>
              <BiLogOut />
              <span className='ml-3' onClick={handleLogout}>
                Logout
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
);
};

export default PartnerSidebar;



/*import { Link, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";
import { sideMenu } from "./Menu";
import { FiSettings } from "react-icons/fi";
import { BsChatDots } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import authSlice from "../../../store/slices/auth";
import store from "../../../store";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = ({ show_navlabel }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  //const handlepayment = () => {
  //  window.location.replace('https://checkout.square.site/merchant/MLTW8WPQPJKFP/checkout/LZSG43TJIOSUNBQO6OJU22MV?src=embd');
  //};
  const handleLogout = () => {
    dispatch(authSlice.actions.Logout());
    history.push("/");
    console.log("handle Logout Finished");
  };
  //to keep track of the active menu item even page refreshes
  useEffect(() => {
    const path = location.pathname;
    const label = sideMenu.find((item) => item?.to == path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, []);

  return (
    <>
      <div className='px-2 pt-7  bg-sidebar bg-cover bg-center'>
        <img className='mx-auto' src='/img/Dantis-logo.png' alt='' />
        <div className='mt-10 flex flex-col justify-between h-screen'>
          <div>
            {sideMenu.map((item, key) => {
              const { label, to, Icon, children, onClick } = item;
              const isActive = activeMenuItem === item.label;
              return (
                <div key={key} className='sidebar-item'>
                  {item?.children ? (
                    <Dropdown
                      show_navlabel={show_navlabel}
                      label={label}
                      activeMenuItem={activeMenuItem}
                      setActiveMenuItem={setActiveMenuItem}
                      to={to}
                      Icon={Icon}
                      options={children}
                    />
                  ) : (
                    <Link
                      className={`pageLink ${isActive ? "bg-dprimary" : ""}`}
                      title={label}
                      to={to}
                      onClick={() => setActiveMenuItem(item.label)}>
                      <Icon />

                      {show_navlabel && <span className='ml-3'>{label}</span>}
                    </Link>
                  )}
                </div>
              );
            })}
          </div>

          <div>
            <div>
              <Link class='pageLink'>
                <BsChatDots /> <span className='ml-3'>Support </span>
              </Link>
            </div>

            <div>
              <Link class='pageLink'>
                <FiSettings /> <span className='ml-3'>Account Setting</span>
              </Link>
            </div>

            <div>
              <Link class='pageLink text-red-400'>
                <BiLogOut />
                <span className='ml-3' onClick={handleLogout}>
                  Logout
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
*/