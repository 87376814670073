import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

import {
  DantisAi,
  DashMain,
  Footer,
  Payment,
  DashAdmin,
  Setup,
  AdminSetup,
  AccountSettings,
  PartnerProduct,
  PartnerLicenses,
  PartnerCustomer,
  CustomerProducts,
  CustomerLicenses,
  CustomerPayment,
  CustomerCloud,
  PartnerAdmin, Product, Market, CustomerDashboardC, PartnerPayment, CommunityPage, CommunityView, Marketplace
} from "./components";

import {
  AccountId,
  Broker,
  PartnerDashboard,
  CustomerDashboard,
  Dashboard,
  Home,
  Login, Login2,
  Page404,
  Privacy,
  Signup, Signup2,
  Start,
  Blog,
  Documentations,
  CSignup,
  PSignup, CLogin, PLogin, MT5WebTerminal, PorC, FAQ, Marketpage, ProductPage1, Trade
} from "./pages";

import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
//import FAQ from "./pages/FAQ";
import Terms from "./pages/Terms";

import ProtectedRoute from "./routes/ProtectedRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "react-stripe-js";
const LoaderDash = lazy(() => import("./components/DashboardComponents/LoaderDash"));
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#212d63',
      colorText: '#ffffff',
    },
  };
  const options = {
    // clientSecret,
    appearance,
  };

  return (
    <>
      <Elements options={options} stripe={stripePromise}>
        {isLoading ? (
          <LoaderDash />
        ) : (
          <PersistGate persistor={persistor} loading={null}>
            <Suspense fallback={<LoaderDash />}>
              <Router>
                <Switch>
                  {/* Licensing Partner Login/Sign up routes */}

                  <Route path="/partner/login" component={PLogin} />
                  <Route path="/partner/signup" component={PSignup} />
                  <Route path="/partner">
                    <PartnerDashboard>
                      <Route exact path="/partner/partnerdashboard" component={PartnerAdmin} />
                      <Route path="/partner/customers" component={PartnerCustomer} />
                      <Route path="/partner/products" component={PartnerProduct} />
                      <Route path="/partner/licenses" component={PartnerLicenses} />
                      <Route path="/partner/payment" component={PartnerPayment} />
                      <Route path="/partner/community" component={CommunityPage} />
                      <Route path="/partnerdashboard/webterminal" component={MT5WebTerminal} />
                    </PartnerDashboard>
                  </Route>
                  {/* Licensing Customer Login/Sign up routes */}

                  <Route path="/customer/signup" component={CSignup} />
                  <Route path="/customer/login" component={CLogin} />
                  <Route path="/customer">
                    <CustomerDashboard>
                      <Route path="/customer/customerdashboard" component={CustomerDashboardC} />
                      <Route path="/customer/products" component={CustomerProducts} />
                      <Route path="/customer/licenses" component={CustomerLicenses} />
                      <Route path="/customer/payment" component={CustomerPayment} />
                      <Route path="/customer/market" component={Market} />
                      <Route path="/customer/cloudsetup" component={CustomerCloud} />
                      <Route path="/customer/commview" component={CommunityView} />
                    </CustomerDashboard>
                  </Route>
                  {/* Once the user login */}
                  {auth.account ? (

                    < Dashboard >
                      <Route exact path="/dashboard">
                        {!auth.account.isAdmin ? <DashMain /> : <DashAdmin />}
                      </Route>
                      <Route path="/dashboard/dantis-ai" component={DantisAi} />
                      <Route path="/dashboard/setup" component={Setup} />
                      <Route path="/dashboard/Adminsetup" component={AdminSetup} />
                      <Route path="/dashboard/profit" component={AccountSettings} />
                      <Route path="/dashboard/subscription" component={Payment} />
                      <Route path="*" element={<Redirect to="/dashboard" replace />} />
                    </Dashboard>
                  ) : (
                    <> {/* Normal User Setup  For Login and Signup*/}
                      <Route path="/login" component={Login} />
                      <Route path="/loginmain" component={Login2} />
                      <Route exact path="/" component={Home} />
                      <Route path="/signup" component={Signup} />
                      <Route path="/signupmain" component={Signup2} />
                      <Route path="/selection" component={Broker} />
                      <Route path="/trade" component={Trade} />
                      <Route path="/about" component={AboutUs} />
                      <Route path="/contact" component={ContactUs} />
                      <Route exact path="/faq" component={FAQ} />
                      <Route path="/privacy" component={Privacy} />
                      <Route path="/plogin" component={PLogin} />
                      <Route path="/clogin" component={CLogin} />
                      <Route path="/porc" component={PorC} />
                      <Route path="/marketplace" component={Marketpage} />
                      <Route path="/product/:productCode" component={ProductPage1} />

                      <Route path="/Terms" component={Terms} />
                      <Route path="/start" component={Start} />
                      <Route path="/documentations" component={Documentations} />
                      <Route path="/blog" component={Blog} />

                      <Footer />
                    </>
                  )}
                  <Route path="*" component={() => <Redirect to="/404" replace />} />
                </Switch>
              </Router>
            </Suspense>
          </PersistGate >
        )
        }
      </Elements>
      
    </>
  );
}
