import Container from "./Container";
import FullContainer from "./FullContainer";

export default function Specials() {
  return (
    <FullContainer className="bg-specials bg-cover bg-top mt-20 py-16">
      <Container>
        <div className="grid lg:grid-cols-specials">
          <div></div>
          <div>
            <p className="heading2 mb-3">
              BECOME A <span className="liner_gradient">DANTIS CLOUD</span> AFFILIATE
            </p>
            <span className="liner_gradient">Join us and earn <strong>30%</strong> on referred payments! </span> <br />
            <br />
            <br />
            <button className="btnSecondary mt-5">
              <a href="https://dantisai.promotekit.com" target="_blank" rel="noopener noreferrer">Join Now</a>
            </button>
          </div>
          <div></div>
        </div>
      </Container>
    </FullContainer>
  );
}
