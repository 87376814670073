import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import FormData from 'form-data';
import axios from "axios";
import store from "../../store";


const ProductList = [
  {
    product_name: "License 1",
    product_code: "Test Code",
    price: "10",
  },
  {
    product_name: "License 1",
    product_code: "Test Code",
    price: "10",
  },
  {
    product_name: "License 1",
    product_code: "Test Code",
    price: "10",
  },
]


function Product() {
  const { token } = store.getState().auth;
  const [fetched, setFetching] = useState(false);
  const [connectedAccountStatus,setConnectedAccountStatus]= useState(false);
  const [products, setProducts] = useState(ProductList);
  const [email,setEmail]=useState('');
  console.log(email)
  console.log(products);
  const [successMessage, setSuccessMessage] = useState('');
  const history = useHistory();



  // Generate a unique random 7-digit number
  const generateProductCode = () => {
    return Math.floor(1000000 + Math.random() * 9000000);
  };
  // Initialize product with a unique product_code
  const [product, setProduct] = useState([]);

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });

  };

  const getAccountInfo = async () => {
    const { token, account } = store.getState().auth;
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/partner/adminInfo`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    );
    const { email, first_name, last_name, phone_number, totalLicenses, pending, available } = res?.data;
   setEmail(email);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/getAccountId`,
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const { connectedAccountId } = response.data;
      const connectedAID=connectedAccountId;
    if(connectedAccountId === false){
      setConnectedAccountStatus(false);
    } else if (typeof (connectedAccountId) === "string"){
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/accountStatus`,
        { connectedAccountId: connectedAID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const { status } = response.data;
      console.log('Connected id status', status);
      if(status){
        setConnectedAccountStatus(true);
      }
    } else{
      setConnectedAccountStatus(false);
    }

  }

  useEffect(() => {
    
    getAccountInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('This is product', product);

      // Making POST request to add a new product
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/products`,
        product,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      // Check the Adding Product Response
      const { result, productId } = response.data;
      console.log({result})
      console.log(response.data);
    console.log('Trying to add product to db')
     const resNew=  await axios.post(
        `${process.env.REACT_APP_API_URL}/product/update`,
         { 
          product_code:result.product_code,
           productId ,
           email:email,
          },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const {status}= resNew.data;

      if(status){
        window.alert("Uploaded")
      }

      // Alert when operation completes
      window.alert("Operation completed! Refresh to see.");

      // Additional logic after successful POST request, if needed

    } catch (error) {
      console.error("There was an error!", error);
      console.log(`Error Adding New Product`);
      setSuccessMessage('Failed to add Product');
    }
  };


  /*const handleRemove = (index) => {
    const updatedProductList = [...ProductList];
    updatedProductList.splice(index, 1);
    setProduct(updatedProductList);
  };*/

// Define your handleRemove function
const handleRemove = async (index) => {
  try {
      const product = products[index];
      const productCode = product.product_code;

      // Send an HTTP DELETE request to the specified endpoint using Axios
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/product/products/${productCode}`, {
          headers: {
              'content-type': 'application/json',
              Authorization: token, // Replace 'token' with your actual token value
          },
      });

      if (response.status === 200) {
          console.log('Item removed successfully');
          // Alert when operation completes
          window.alert("Operation completed! Refresh to see.");
      } else {
          console.error('Error removing item:', response.statusText);
          // Handle error (e.g., show an error message to the user)
      }
  } catch (error) {
      console.error('Error removing item:', error);
      // Handle error (e.g., show an error message to the user)
  }
};

  const [selectedImage, setSelectedImage] = useState();
  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  // Add a new piece of state to store the selected file
  const [selectedFile, setSelectedFile] = useState();

  // Add a new function to handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Modify your handleEdit function to use the selected file
  const handleEdit = async (event, index) => {
    // Prevent the form from causing a page reload
    event.preventDefault();

    const product = products[index];
    const productCode = product.product_code;
    console.log(productCode);

    // Create a new FormData instance
    const data = new FormData();

    // Append the other product details to the form data
    data.append('product_code', product.product_code);
    data.append('product_name', product.product_name);
    data.append('type', product.type);
    data.append('price', product.price);
    data.append('product_file', selectedFile);

    // Send a PUT request to the endpoint with the product code in the URL and the
    // form data in the body. Replace 'http://localhost:3000' with your actual
    // server URL.
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/product/products/${product.product_code}`, data, {
      headers: {
        Authorization: token,
      },
    });

    console.log(response.data);
    // Add an alert to notify when the operation completes
    alert('Operation completed! Refresh to see');
  };

  const fetchProducts = () => {
    try {
      console.log(`Authorization Token ${token}`);
      axios.get(
        `${process.env.REACT_APP_API_URL}/product/products`,
        {
          headers: {
            "content-type": "application/json",
            authorization: token,
          },
        }
      ).then(response => {
        // Check the Adding Product Response
        console.log(response.data);
        setProducts(response.data)
      })
        .catch(error => {
          console.log(`Error Fetching Product List`);
          console.log(error)
        })

    } catch (err) {
      console.error("There was an error!", err);
    }
  }
  useEffect(() => {
    // Fetch products here and set to state
    fetchProducts();
  }, []);

// Modify your handleEdit function to use the selected file
const handleEdit1 = async (event, index) => {
  // Prevent the form from causing a page reload
  event.preventDefault();

  const product = products[index];
  const productCode = product.product_code;
  console.log(productCode);

  // Create a new FormData instance
  const data = new FormData();

  // Append the other product details to the form data
  data.append('product_code', product.product_code);
  data.append('product_name', product.product_name);
  data.append('type', product.type);
  data.append('price', product.price);
  data.append('image_url', selectedImage);
  

  // Send a PUT request to the endpoint with the product code in the URL and the
  // form data in the body. Replace 'http://localhost:3000' with your actual
  // server URL.
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/product/products/${product.product_code}`, data, {
    headers: {
      Authorization: token,
    },
  });

  console.log(response.data);
  // Add an alert to notify when the operation completes
  alert('Operation completed! Refresh to see');
};

const [details, setDetails] = useState();
const handleDetailsChange = (event, index) => {
  setProductDetails({ ...productDetails, [index]: event.target.value });
};

// Modify your handleEdit function to use the selected file
const handleEdit2 = async (event, index) => {
  // Prevent the form from causing a page reload
  event.preventDefault();

  const product = products[index];
  const productCode = product.product_code;
  console.log(productCode);

  // Create a new FormData instance
  const data = new FormData();

  // Append the other product details to the form data
  data.append('product_code', product.product_code);
  data.append('product_name', product.product_name);
  data.append('type', product.type);
  data.append('price', product.price);
  data.append('details', productDetails[index]);  // Use productDetails[index] instead of details
  

  // Send a PUT request to the endpoint with the product code in the URL and the
  // form data in the body. Replace 'http://localhost:3000' with your actual
  // server URL.
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/product/products/${product.product_code}`, data, {
    headers: {
      Authorization: token,
    },
  });

  console.log(response.data);
};
 
// Initialize an empty object for visibility state
const [codeVisibility, setCodeVisibility] = useState({});
// Initialize an empty object for details state
const [productDetails, setProductDetails] = useState({});
const [showMore, setShowMore] = useState(false);

/*const takeToModifier = () => {
  //window.location.replace('http://localhost:3000/Setup');
  // Navigate to the "Setup" page
  history.push("/documentations");
};*/
const takeToModifier = () => {
  // Open the "documentations" page in a new tab
  const url = window.location.origin + "/documentations";
  window.open(url, '_blank');
};
const [selectedColumn, setSelectedColumn] = useState(null);

  return (
    <>
     {/*}*/}
        {/* Temporarily bypass the account status check by commenting it out */}
        {connectedAccountStatus ? 
        <>
            <FullContainer className="mt-0" style={{ width: '100%' }}>
              <Container style={{ width: '100%' }}>
                <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                  Products (with temp Samples)
                </h1>
                <div className="grid lg:grid-cols-1 gap-2 w-full mb-6">
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='col-span-12'>
                    <form onSubmit={handleSubmit}>
                      <div style={{ display: 'inline-block', textAlign: 'center' }} className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
                        <ul className='list-disc grid grid-cols-1 capitalize'>
                          <li className='flex flex-col md:flex-row items-center justify-center'>
                            <div className='tooltip'>
                              <p className='mr-2'>Product Name</p>
                              <span className='tooltiptext'>This is the name of your product. Add MT4 or MT5 after the name.</span>
                            </div>
                            <input type="text" name="product_name" value={product.product_name} onChange={handleInputChange} placeholder="Dantis Scalper MT5" className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                          </li>
                          <li className='flex flex-col md:flex-row items-center justify-center'>
                            <div className='tooltip'>
                              <p className='mr-2'>Type</p>
                              <span className='tooltiptext'>Expert Advisor or Indicator</span>
                            </div>
                            <select name="type" value={product.type} onChange={handleInputChange} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }}>
                              <option value="">Select a type</option>
                              <option value="Expert Advisor">Expert Advisor</option>
                              <option value="Indicator">Indicator</option>
                            </select>
                          </li>
                          <li className='flex flex-col md:flex-row items-center justify-center'>
                              <div className='tooltip'>
                                  <p className='mr-2'>Platform</p>
                                  <span className='tooltiptext'>MT5 or MT4</span>
                              </div>
                              <select name="platform" value={product.platform} onChange={handleInputChange} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }}>
                                  <option value="">Select a platform</option>
                                  <option value="MT5">MT5</option>
                                  <option value="MT4">MT4</option>
                              </select>
                          </li>
                          <li className='flex flex-col md:flex-row items-center justify-center'>
                            <div className='tooltip'>
                              <p className='mr-2'>Price</p>
                              <span className='tooltiptext'>This is the price of your product in $$$</span>
                            </div>
                            <input type="text" name="price" value={product.price} onChange={handleInputChange} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                          </li>
                          <li className='flex flex-col md:flex-row items-center justify-center'>
                            <div className='tooltip'>
                              <p className='mr-2'>Interval</p>
                              <span className='tooltiptext'>Subscription Interval</span>
                            </div>
                            <select name="interval" value={product.interval} onChange={handleInputChange} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }}>
                              <option value="">Select a type</option>
                              <option value="day">Daily</option>
                              <option value="week">Weekly</option>
                              <option value="month">Monthly</option>
                              <option value="onetime">One Time</option>
                            </select>
                          </li>
                          <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                            <button
                              type="submit" className='btnSecondary3'>
                              Add Product
                            </button>
                          </li>
                          <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                            file, details, and Cover Image upload will be done later.
                          </li>
                        </ul>
                      </div>
                    </form>
                    {successMessage && <p>{successMessage}</p>}
                  </div>
                </div>
              </Container>
            </FullContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px' }} className='mb-6'>
            <div style={{ display: 'inline-block', textAlign: 'center' }} className='p-3 md:p-4 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
              <li className='flex flex-col mt-1 md:flex-row items-center justify-center'>
                For Subscription Intervals, ensure tool compatibilty before file upload
              </li>
              <li className='flex flex-col mt-2 md:flex-row items-center justify-center'>
               <button className='btnSecondary3' onClick={takeToModifier}> AI modifier </button>
              </li>
            </div>
            </div>

            <div style={{ overflowX: 'auto', margin: '20px' }}>
            <div style={{ 
              position: 'sticky', 
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '10px',
              borderRadius: '5px'
            }}>
              <form onChange={(e) => setSelectedColumn(e.target.value)}>
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="actions" /> Actions
                </label >
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="file" /> File
                </label>
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="uploadFile" /> Upload File
                </label>
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="image" /> Image
                </label>
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="uploadImage" /> Upload Image
                </label>
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="details" /> Details
                </label>
                <label style={{ margin: '0 10px' }}>
                  <input type="checkbox" value="updateDetails" /> Update Details
                </label>
                {/* Add more checkboxes for each column */}
              </form>
            </div>
      <table className='w-full'>
        <thead className='bg-glass'>
          <tr>
            <th className='text-left'>Product Name</th>
            <th className='text-left'>Product Page</th>
            <th className='text-left'>Product Code</th>
            <th className='text-left'>Price</th>
            {selectedColumn === 'actions' && <th className='text-left'>Actions</th>}
            {selectedColumn === 'file' && <th className='text-left'>File</th>}
            {selectedColumn === 'uploadFile' && <th className='text-left'>Upload File</th>}
            {selectedColumn === 'image' && <th className='text-left'>Image</th>}
            {selectedColumn === 'uploadImage' && <th className='text-left'>Upload Image</th>}
            {selectedColumn === 'details' && <th className='text-left'>Details</th>}
            {selectedColumn === 'updateDetails' && <th className='text-left'>Update Details</th>}
              </tr>
              </thead>
              <tbody>
                {products?.map((product, index) => (
                  <tr key={index} className='bg-dsecondray'>
                    <td className='p-6'>{product.product_name}</td>
                    <td className='p-6'>
                      <a href={`${process.env.REACT_APP_DOMAIN_URL}product/${product.product_code}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                        Link
                      </a>
                    </td>
                    <td className='p-6'>
                    <button className='btnSecondary4' onClick={() => setCodeVisibility({ ...codeVisibility, [index]: !codeVisibility[index] })}>
                      {codeVisibility[index] ? 'Hide' : 'View'}
                    </button>
                    {codeVisibility[index] && <p>{product.product_code}</p>}
                    </td>
                    <td className='p-6'>${product.price}</td>
                    {selectedColumn === 'actions' && (
                      <td className='p-6'>
                        <button onClick={() => handleRemove(index)}>Remove</button>
                      </td>
                    )}
                    {selectedColumn === 'file' && <td className='p-6'>{product.original_name}</td>}
                    {selectedColumn === 'uploadFile' && (
                      <td className='p-6'>
                        <form onSubmit={(e) => handleEdit(e, index)}>
                          <input type="file" onChange={handleFileChange} />
                          <button type="submit" className='btnSecondary4'>Upload</button>
                        </form>
                      </td>
                    )}
                    {selectedColumn === 'image' && (
                      <td className='p-6'>
                        {product.image_url && 
                          <a href={`${process.env.REACT_APP_API_URL}/${product.image_url}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                            Image
                          </a>
                        }
                      </td>
                    )}
                    {selectedColumn === 'uploadImage' && (
                      <td className='p-6'>
                        <form onSubmit={(e) => handleEdit1(e, index)}>
                          <input type="file" accept=".png, .jpg, .jpeg" onChange={handleImageChange} /> {/* New file input for image upload */}
                          <button type="submit" className='btnSecondary4'>Upload</button>
                        </form>
                      </td>
                    )}
                    {selectedColumn === 'details' && (
                      <td className='p-6'>
                        {showMore ? product.details : product.details.split('\n').slice(0, 2).join('\n')}
                        <a href="#" onClick={() => setShowMore(!showMore)} style={{ textDecoration: 'underline', fontSize: 'bold' }}>
                          {showMore ? 'Less' : 'More'}
                        </a>
                      </td>
                    )}
                    {selectedColumn === 'updateDetails' && (
                      <td className='p-6'>
                        <form onSubmit={(e) => handleEdit2(e, index)}>
                          <textarea 
                            style={{
                              color: 'black', 
                              overflow: 'hidden', 
                              resize: 'none', 
                              height: '20px'
                            }}
                            onFocus={(e) => e.target.style.height ='150px'}
                            onBlur={(e) => e.target.style.height ='20px'}
                            rows="4" 
                            cols="50" 
                            maxLength="500" 
                            value={details} 
                            onChange={(e) => handleDetailsChange(e, index)}  
                          /> {/* New textarea for updating details */}
                          <button type="submit" className='btnSecondary4'>Update</button>
                        </form>
                      </td>
                    )}
                  </tr>
                ))}
                </tbody>
            </table>
            </div>
        </>:
        <>
            <FullContainer className="mt-0" style={{ width: '100%' }}>
              <Container style={{ width: '100%' }}>
                <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                  Please go to payment page and confirm the stripe onboarding.
                </h1>
               
              </Container>
            </FullContainer>
        </>
     }
    </>
  );
}

export default Product;

