import { Link } from "react-router-dom";
import Container from "./Container";
import FullContainer from "./FullContainer";

export default function Footer() {
  return (
    <FullContainer className='bg-footer bg-cover bg-center mt-16'>
      <Container className='space-y-6 py-16'>
        <div className='flex flex-col-reverse lg:flex-row items-center justify-between w-full'>
          <div className='flex flex-col lg:flex-row lg:items-center gap-3 lg:gap-5 uppercase mt-4 w-full' style={{ width: '90vw' }}>
            <Link to='/home'>Home</Link>
            <Link to='/about'>About Us</Link>
            <Link to='/contact'>Contact Us</Link>
            <Link to='/faq'>Cloud</Link>
            <Link to='/privacy'>Privacy</Link>
            <Link to='/terms'>Terms of Use</Link>
            <Link to='/blog'>Blog</Link>
            <Link to='/documentations'>Docs</Link>
            <Link to='/login'>Sign In</Link>
          </div>
          <div className='flex items-center space-x-3 py-2 w-full'>
            <a
              href='https://www.instagram.com/dantis.ai/'
              target='_blank'
              rel='noreferrer'>
              <img src='/img/icons/ig.png' className='socialIcon' alt='' />
            </a>
            <a href='https://www.twitter.com/dantisai' target='_blank' rel='noreferrer'>
              <img src='/img/icons/tw.png' className='socialIcon' alt='' />
            </a>
            <a href='https://www.linkedin.com/company/dantisai/?viewAsMember=true' target='_blank' rel='noreferrer'>
              <img src='/img/icons/in.png' className='socialIcon' alt='' />
            </a>
          </div>
        </div>
        <div className='w-full grid lg:grid-cols-2'>
          <div>
            <h2 className='text-xl uppercase'>
              information & high risk warning:
            </h2>
            <p className='text-gray-400 mt-2'>
              We do not provide Investment advise. Invest wisely and be informed of the inherent potential risk
              involved in using artificial intelligence for finance trading and
              that we are not liable for any mishaps that might occur.
            </p>
          </div>
          <div className='flex flex-col lg:items-end mt-10 lg:mt-0'>
            <p>admin@dantisai.com</p>
            <img src='/img/footer-logo.png' className='m-2 w-24' alt='' />
          </div>
        </div>
        <p className='text-gray-500'>© 2023 dantis AI . All rights reserved</p>
      </Container>
    </FullContainer>
  );
}
