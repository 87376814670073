import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import FormData from 'form-data';
import axios from "axios";
import store from "../../store";


function Payment() {
// fetch the seller email and connected account id from the database
  const [email, setEmail] = useState('');
  const [accountStatus,setAccountStatus]=useState(null);
  const [onboardingLink,setOnboardingLink]=useState(null);
  const [loginLink,setLoginLink]=useState(false);
  const [connectedAccountId,setConnectedAccountId] =useState(false);
  const [loading,setLoading] = useState(false);
  console.log({
    email,
    accountStatus,
    onboardingLink,
    loginLink,
  })

  const getAccountInfo = async () => {
    const { token, account } = store.getState().auth;
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/partner/adminInfo`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    );
    const { email, first_name, last_name, phone_number, totalLicenses, pending, available } = res?.data;
   setEmail(email);

   if(email){
     setLoading(true);
     axios.post(
       `${process.env.REACT_APP_API_URL}/getAccountId`,
       {
         email
       },
       {
         headers: {
           "content-type": "application/json",
           // Authorization: token,
         },
       }
     ).then(response => {
       if (response.data.connectedAccountId === false) {
         axios.post(
           `${process.env.REACT_APP_API_URL}/onboard-connected-account`,
           {
             email
           },
           {
             headers: {
               "content-type": "application/json",
               // Authorization: token,
             },
           }
         ).then(response => {
           setLoading(false)
           setConnectedAccountId(response.data.accountId)
           setOnboardingLink(response.data.onboardingLink)
         })
       } else {
         const gotConnectedAccountId = response.data.connectedAccountId;
         try {
           axios.post(
             `${process.env.REACT_APP_API_URL}/accountStatus`,
             {
               connectedAccountId: gotConnectedAccountId
             },
             {
               headers: {
                 "content-type": "application/json",
                 // Authorization: token,
               },
             }
           ).then(response => {
             // Check the Adding Product Response
             console.log(response.data)
             switch (response?.data?.status) {
               case true:
                 setAccountStatus(true);
                 //generate a login link
                 axios.post(
                   `${process.env.REACT_APP_API_URL}/loginStripe`,
                   {
                     connectedAccountId: gotConnectedAccountId
                   },
                   {
                     headers: {
                       "content-type": "application/json",
                       // Authorization: token,
                     },
                   }
                 ).then(response => {
                   setLoading(false);
                   setLoginLink(
                     response.data.loginLink.url
                   )
                 })

                 break;
               case false:
                 setAccountStatus(false);
                 setOnboardingLink(response.data.accountLink.url)
                 setLoading(false)
                 break;
               default:
                 setAccountStatus(null)
                 setLoading(false)
                 break;
             }
           })
             .catch(error => {
               console.log(error.message)

             })

         } catch (err) {
           console.error("There was an error!", err);
         }

       }
     })
   }

  }

  useEffect(() => {
    
    getAccountInfo();
  }, []);

  

  const handleOnboarding =(e) => {
    e.preventDefault();
    window.location.href=onboardingLink;
  };
  const handleStripeLogin =(e) => {
    e.preventDefault();
    window.location.href =loginLink;
  };



    return (
        <>
        <h1>Email:{email}</h1>
         {
          loading ? <>
            <Container style={{ width: '100%' }}>
              <h1 className="text-3xl animate-bounce font-bold uppercase text-center mb-10 mt-8">
                Please wait a moment...
              </h1>
              

            </Container>
          </> : <>

              <FullContainer className="mt-0" style={{ width: '100%' }}>
                {
                  accountStatus ? <>
                    <Container style={{ width: '100%' }}>
                      <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                        Your account is activated
                      </h1>
                      <div className='col-span-12'>
                        <form onSubmit={handleStripeLogin}>
                          <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
                            <ul className='list-disc grid grid-cols-1 capitalize'>
                              <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                                <button
                                  type="submit" className='btnSecondary3'>
                                  Login to stripe dashboard
                                </button>
                              </li>
                            </ul>
                          </div>
                        </form>
                      </div>

                    </Container>
                  </> :
                    <>
                      <Container style={{ width: '100%' }}>
                        <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                          Begin Onboarding
                        </h1>
                        <div className="grid lg:grid-cols-1 gap-2 w-full mb-6">
                          <div className='col-span-12'>
                            <form onSubmit={handleOnboarding}>
                              <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
                                <ul className='list-disc grid grid-cols-1 capitalize'>
                                  <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                                    <button
                                      type="submit" className='btnSecondary3'>
                                      Onboard
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Container>
                    </>
                }
              </FullContainer>

          </>
         }
        </>
      );
    }
    
    export default Payment;