import Container from "./Container";
import FullContainer from "./FullContainer";
import ImageHoverChange from "./ImageHoverChange";

export default function Autonomous() {
  return (
    <FullContainer className="">
      <Container>
        <div className="flex flex-col-reverse lg:grid grid-cols-2">
          <div className="flex flex-col justify-center p-10">
            <h2 className="heading2 mb-3">Autonomous</h2>
            <p>
              Analysis and trading are completely adapted and automated by Dantis
              AI. 
              DANTIS AI WORKS WHILE YOU LIVE LIFE!.
            </p>
          </div>
          <ImageHoverChange
            image={"/img/pic2.webp"}
            hoverImage="/img/pic2hover.webp"
          />
        </div>
      </Container>
    </FullContainer>
  );
}
