import React, { useEffect } from "react";
import { Container, FullContainer, Navbar } from "../components";
import ImageHoverChange from "../components/ImageHoverChange";

function AboutUs() {
  useEffect(() => {
    // Dynamically create a script element for the chatbot
    const script = document.createElement("script");
    script.id = "64f00959494bff9a99310a24";
    script.src = "https://app.droxy.ai/chat.min.js";
    script.defer = true;

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this effect runs only once
  
  return (
    <FullContainer className="mt-0">
      <Navbar />
      <img
        src="/img/hero.webp"
        className="absolute top-0 left-0 h-full w-full"
        alt=""
      />
      <Container>
        <div className="grid grid-cols-1 flex-col items-center justify-center">
          <div>
          <div className="flex flex-col bg-[#302D41] py-5 px-10">
            <div className="flex flex-col items-center text-left">
              <h1 className="text-3xl font-bold uppercase my-12">About Dantis</h1>
              <p className="leading-relaxed mb-8 pl-4">
                Welcome to <strong className="text-primary">Dantis</strong> —the future of streamlined forex trading! 
              </p>
              <h2 className="text-2xl font-bold my-4">Our Story</h2>
              <p className="leading-relaxed mb-8 pl-4">
                At Dantis, we believe that trading should be efficient, accessible, and empowering. Our journey began with a simple question: How can we revolutionize the trading experience for both beginners and seasoned traders?
              </p>
              <h2 className="text-2xl font-bold my-4">What We Offer</h2>
              <p className="leading-relaxed mb-8 pl-4">
                <strong className="text-primary">Effortless Cloud Hosting:</strong> Say goodbye to local installations and server headaches. With Dantis, hosting your MT4/MT5 platforms on the cloud is as easy as a <strong className="underline">single click</strong>. Trade from anywhere, anytime. <br/>
                <strong className="text-primary">Seamless Tool Marketplace:</strong> Discover a curated selection of trading tools—Expert Advisors (EAs) and indicators—all in one place. <strong className="underline">Subscribe effortlessly</strong> and let us handle delivery and subscription management. <br/>
                <strong className="text-primary">Global Accessibility:</strong> Your trading environment is just a <strong className="underline">URL away</strong>. Access your cloud setup from any corner of the world. Trade on your terms, without limitations.
              </p>
              <h2 className="text-2xl font-bold my-4">Our Commitment</h2>
              <p className="leading-relaxed mb-8 pl-4">
                Dantis is more than a platform; it’s a community. We’re committed to simplifying your trading journey, enhancing security, and fostering collaboration. Join us as we redefine forex trading.
                Ready to trade smarter? Let’s get started!
                Learn more about Dantis
                Dantis: Where innovation meets opportunity.
              </p>
            </div>
          </div>
          </div>

          <ImageHoverChange
            className="mx-auto flex"
            image={"/img/ABOUT-PIC.webp"}
            hoverImage="/img/ABOUT-PIChover.webp"
          />
        </div>
      </Container>
    </FullContainer>
  );
}

export default AboutUs;
