import { FaHeadset, FaTh } from "react-icons/fa";

import { BiLogOut } from "react-icons/bi";
import { HiOutlineChartBar, HiOutlineCash } from "react-icons/hi";

export const PartnerSideMenu = [
  {
    to: "/partner/partnerdashboard",
    label: "Dashboard",
    Icon: FaTh,
    permission: ["all"],
  },
  {
    to: "/partner/products",
    label: "Products",
    Icon: HiOutlineChartBar,
    permission: ["all"],
  },
  
  {
    to: "/partner/licenses",
    label: "Licenses",
    Icon: HiOutlineCash,
    permission: ["all"],
    active: false,
  },/*
  {
    to: "/partner/customers",
    label: "Customers",
    Icon: BiLogOut,
    permission: ["all"],
    active: false,
  },*/
  {
    to: "/partner/payment",
    label: "Payment",
    Icon: HiOutlineCash,
    permission: ["all"],
    active: false,
  },
  {
    to: "/partner/community",
    label: "Community",
    Icon: FaHeadset,
    permission: ["all"],
    active: false,
  },
];
