import React, { useEffect, useState } from "react";
import {
    CardElement,
    CardElementComponent,
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm(props) {
    const { email, userName, clientSecretNew } = props;
    const stripe = useStripe();
    const elements = useElements();
    console.log('This is client secret', clientSecretNew);
    const [selectedEmail, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [saveCard, setSaveCard] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            `${clientSecretNew}`
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!"); //manage operations when payment succeeded (confirm booking reserve or something with your requirements)
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, clientSecretNew]);


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (!stripe || !elements) {
    //         return;
    //     }

    //     setIsLoading(true);

    //     try {
    //         // Get the PaymentElement
    //         const cardElement = elements.getElement(CardElement);

    //         // Create payment method from card element
    //         const { paymentMethod, error } = await stripe.createPaymentMethod({
    //             type: 'card',
    //             card: cardElement,
    //             billing_details: {
    //                 email: email
    //             }
    //         });

    //         if (error) {
    //             throw new Error(error.message);
    //         }

    //         // Confirm the payment with the payment method and payment intent client secret
    //         const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecretNew, {
    //             payment_method: paymentMethod.id,
    //             setup_future_usage: saveCard ? 'on_session' : null
    //         });

    //         if (confirmError) {
    //             throw new Error(confirmError.message);
    //         }

    //         if (paymentIntent.status === "succeeded") {
    //             setMessage("Payment successful!");
    //         } else {
    //             setMessage("Payment failed.");
    //         }
    //     } catch (error) {
    //         setMessage(error.message);
    //     }

    //     setIsLoading(false);
    // };

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        setIsLoading(true);
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_DOMAIN_URL}`,
            },
        });

        setIsLoading(false);
        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setMessage(error.message);
            setIsLoading(false);

        } else {
            setIsLoading(false);

            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            {/* <CardElement id="card-element" options={paymentElementOptions} /> */}
            <PaymentElement id="payment" options={paymentElementOptions} />
            {/* Checkbox to save card */}
            {/* <div>
                <input
                    type="checkbox"
                    id="save-card"
                    name="save-card"
                    checked={saveCard}
                    onChange={(e) => setSaveCard(e.target.checked)}
                />
                <label htmlFor="save-card">Save this card for future purchases</label>
            </div> */}
            <button
                className={`capitalize ${isLoading ? 'btnSecondary bg-green-400' : 'btnSecondary'} mt-10`}                
                disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? 'Processing..' : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}