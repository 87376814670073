import { useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import store from "../../store";
//import store from "../store";
import axios from "axios";
import { useHistory } from "react-router-dom";


export default function PartnerAdmin() {
  const [accounts, setAccounts] = useState([]);
  const [fetched, setFetching] = useState(false);
  const [AccountInfo, setAccountInfo] = useState({
    FullName: "",
    pendingBalance: "0",
    availableBalance:"0",
  })



  const [LicenseInfo, SetLicenseInfo] = useState([]);

  const getLicenseInfo = async () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/license/license`,
          {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          }
        );
        console.log('Server response:', res.data);
        if (res.data && typeof res.data === 'object' && res.data.constructor === Object) {
          SetLicenseInfo([res.data]);
        } else if (Array.isArray(res.data)) {
          SetLicenseInfo(res.data);
        }
      } catch (err) {
        console.error("There was an error!", err);
      }
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };

  const getAccountInfo = async()=>{
    const { token, account } = store.getState().auth;
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/partner/adminInfo`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    );
    const { email, first_name, last_name, phone_number, totalLicenses, pending, available }=res?.data;
    setAccountInfo({
      FullName:`${first_name} ${last_name}`,
      availableBalance:available?.amount || 0,
      pendingBalance:pending?.amount || 0,
    })
   
  }

  useEffect(() => {
    getLicenseInfo();
    getAccountInfo();
  }, []);

  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/product/products`,
          {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          }
        );
        console.log('Server response:', res.data);
        if (Array.isArray(res.data)) {
          setProducts(res.data);
        }
      } catch (err) {
        console.error("There was an error!", err);
      }
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };





  useEffect(() => {
    getProducts();
  }, []);

  // Calculate the total balance
  const totalBalance = accounts.reduce((total, account) => total + Number(account.accountBalance), 0);
  // Calculate the number of users
  const numUsers = accounts.length; //REPLACE WITH THE COUNT OF THE ACTIVE USERS. USE THE COUNT OF THE DB STATUS TABLE WITH "TRADING"


  // Initialize history
  const history = useHistory();

  const takeToBroker = () => {
    //window.location.replace('http://localhost:3000/Setup');
    // Navigate to the "Setup" page
    history.push("/partner/products");
  };

  const takeToCustomer = () => {
    //window.location.replace('http://localhost:3000/Setup');
    // Navigate to the "Setup" page
    history.push("/customer/login");
  };

  return (
    <>
      <div className='px-5 py-6'>
      <div style={{ position: 'relative' }} className='mt-1 mb-5'>
          <button
            style={{ position: 'absolute', right: '20px' }}
            className='capitalize btnSecondaryShadow bg-glass1'
            onClick={takeToCustomer}
          >
            Switch to Customer
          </button>
        </div>
        <div className='grid md:grid-cols-12 gap-x-3 mt-16 gap-y-6'>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Hello {AccountInfo.FullName} </h1>
            <p className='capitalize mt-2'>Your Subscriptions pending balance is </p>
            <h4 className='text-2xl font-bold '>
              ${AccountInfo.pendingBalance / 100}
            </h4>
            <p className='capitalize mt-5'>Your Subscriptions available balance is </p>
            <h4 className='text-2xl font-bold '>
              ${AccountInfo.availableBalance / 100}
            </h4>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Users</h1>
            <p className='capitalize mt-2'>Number of Active license </p>
            <h4 className='text-2xl font-bold mt-10'>{LicenseInfo.length}</h4>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Products</h1>
            <p className='capitalize mt-2'>
              Number of tools{" "}
            </p>
            <h4 className='text-2xl font-bold mt-10'>{products.length}</h4>
          </div>
        </div>


        <div className='grid md:grid-cols-1 gap-x-3 gap-y-6 px-6 py-8'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ borderBottom: '2px solid color' }}>
                  <TableCell style={{ fontWeight: 'bold' }}>Customer ID</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>License ID</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Account ID</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">Product Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {LicenseInfo.map((row) => (
                  <TableRow key={row.license_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.customer_id}
                    </TableCell>
                    <TableCell align="right">{row.license_id}</TableCell>
                    <TableCell align="right">{row.allowed_account}</TableCell>
                    <TableCell align="right">{row.product_code}</TableCell>
                  </TableRow>
                ))}
                {/* Add the total count row */}
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row">
                    Total Licenses
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">{LicenseInfo.length}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='col-span-12 px-6 py-4'>
          <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
            <div className='grid grid-cols-1 md:grid-cols-3 capitalize'>
              <div className='md:col-span-1'>
                <div className='md:col-span-1'>
                  <p>
                    <button
                      className='capitalize btnSecondary mt-10' onClick={takeToBroker}>
                      Products
                    </button>
                  </p>
                </div>
              </div>
              {/* This is a map function that iterates over the 'products' array. For each product, it creates a new div that spans 1 column on medium and larger screens.*/}

            </div>
          </div>
        </div>

      </div>

    </>
  );
};