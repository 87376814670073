import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Field, useField } from "formik";

export default function BasicSelect({ name, label, values, ...otherProps }) {
  const [field, mata] = useField(name);

  const config = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };
  if (mata && mata.touched && mata.error) {
    config.error = true;
    config.helperText = mata.error;
  }
  return (
    <FormControl fullWidth className="bg-white flex rounded">
      <InputLabel>{label}</InputLabel>
      <Select {...field}>
        {values?.map((x)=>(<MenuItem value={x.id}>{x.value}</MenuItem>))}
      </Select>
    </FormControl>
  );
}
