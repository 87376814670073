import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormikContext,useField } from "formik";

export default function FirstComponent({ name, ...otherProps }) {
  const { setFieldValue } = useFormikContext();


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker onChange={(value) => (setFieldValue(name, value.$d, true))} className="bg-white flex rounded" />
    </LocalizationProvider>
  );
}
