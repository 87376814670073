import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar, EAModifier } from "../components";
import ImageHoverChange from "../components/ImageHoverChange";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';

export default function Documentations() {
    const [filter, setFilter] = useState('');
  
    useEffect(() => {
      // Dynamically create a script element for the chatbot
      const script = document.createElement('script');
      script.id = '64f00959494bff9a99310a24';
      script.src = 'https://app.droxy.ai/chat.min.js';
      script.defer = true;
  
      // Append the script to the document's head
      document.head.appendChild(script);
  
      // Clean up by removing the script when the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    }, []); // Empty dependency array to ensure this effect runs only once
  
    const handleSearchClick = () => {
      // Handle search click
      console.log('Search button clicked');
    };
    //const CodeBlock = () => {
      const codeString = () => { 
        return `
        //+------------------------------------------------------------------+
        //|                                              DantisLicenseEA.mq5 |
        //|                                  Copyright 2023, MetaQuotes Ltd. |
        //|                                             https://www.mql5.com |
        //+------------------------------------------------------------------+
        #property copyright "admin@dantisai.com"
        #property link      "https://www.mql5.com"
        #property version   "1.00"
        #include "..\\Libraries\\DantisLicense.mq5"
        #import "DantisLicense.ex5"
        bool verify();
        #import
        string sample_product = "92cbd91c-e5b5-44f5-b3b2-a72ed9ee7187";
        bool auth = false;
        //+------------------------------------------------------------------+
        //| Expert initialization function                                   |
        //+------------------------------------------------------------------+
        int OnInit()
          {
        //---
          if(!TerminalInfoInteger(TERMINAL_DLLS_ALLOWED))
            {
              Alert("DLL call is not allowed. Experts cannot run.");
              ExpertRemove();
            }
          if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
            {
              auth = verify(sample_product);
              if(auth == true)
                {
                Comment("Active");
                }
              if(auth == false)
                {
                Comment("Inactive. Contact Provider to Activate");
                ExpertRemove();
                }
            }
            EventSetTimer(1000);
        //---
          return(INIT_SUCCEEDED);
          }
        //+------------------------------------------------------------------+
        //| Expert deinitialization function                                 |
        //+------------------------------------------------------------------+
        void OnDeinit(const int reason)
          {
        //---
          }
        //+------------------------------------------------------------------+
        //| Expert tick function                                             |
        //+------------------------------------------------------------------+
        void OnTick()
          {
        //---
          }
        //+------------------------------------------------------------------+
        //+------------------------------------------------------------------+
        //+------------------------------------------------------------------+
        //|                                                                  |
        //+------------------------------------------------------------------+
        void OnTimer()
          {
          if(!TerminalInfoInteger(TERMINAL_DLLS_ALLOWED))
            {
              Alert("DLL call is not allowed. Experts cannot run.");
              ExpertRemove();
            }
          if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
            {
              auth = verify(sample_product);
              if(auth == true)
                {
                Comment("Active");
                }
              if(auth == false)
              {
              Comment("Inactive. Contact Provider to Activate");
              ExpertRemove();
              }
          }
        }
      //+------------------------------------------------------------------+`
    };
    
    const codeString1 = () => { 
      return `
      //+------------------------------------------------------------------+
      //|                                              DantisLicenseEA.mq4 |
      //|                                  Copyright 2023, MetaQuotes Ltd. |
      //|                                             https://www.mql5.com |
      //+------------------------------------------------------------------+
      #property copyright "Copyright 2023, MetaQuotes Ltd."
      #property link      "https://www.mql5.com"
      #property version   "1.00"
      #property strict

      #include "..\Libraries\DantisLicense.mq4"
      #import "DantisLicense.ex4"
      bool verify();
      #import


      string sample_product = "92cbd91c-e5b5-44f5-b3b2-a72ed9ee7187";
      bool auth = false;
      //+------------------------------------------------------------------+
      //| Expert initialization function                                   |
      //+------------------------------------------------------------------+
      int OnInit()
        {
      //---
            if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
          {

            auth = verify(sample_product);
            if(auth == true)
              {
              Comment("Active");
              }
            if(auth == false)
              {
              Comment("Inactive. Contact Provider to Activate");
              ExpertRemove();
              }
          }
          EventSetTimer(1000);
      //---
        return(INIT_SUCCEEDED);
        }
      //+------------------------------------------------------------------+
      //| Expert deinitialization function                                 |
      //+------------------------------------------------------------------+
      void OnDeinit(const int reason)
        {
      //---
        
        }
      //+------------------------------------------------------------------+
      //| Expert tick function                                             |
      //+------------------------------------------------------------------+
      void OnTick()
        {
      //---
        
        }
      //+------------------------------------------------------------------+
      void OnTimer()
        {

        if(MQLInfoInteger(MQL_OPTIMIZATION) == 0)
          {

            auth = verify(sample_product);
            if(auth == true)
              {
              Comment("Active");
              }
            if(auth == false)
              {
              Comment("Inactive. Contact Provider to Activate");
              ExpertRemove();
              }
          }
        }`
        };
  
    const handleDownload = (path = '/DantisLicensemt4', urlPrefix = `${process.env.REACT_APP_API_URL}`) => {
      console.log(path);
      const timestamp = Date.now();
      const fileUrl = `${urlPrefix}${path}?nocache=${timestamp}`;
    
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = path?.split('/').pop();
    
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleDownload1 = (path = '/DantisLicense', urlPrefix = `${process.env.REACT_APP_API_URL}`) => {
      console.log(path);
      const timestamp = Date.now();
      const fileUrl = `${urlPrefix}${path}?nocache=${timestamp}`;
    
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = path?.split('/').pop();
    
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <FullContainer className='mt-0'>
        <Navbar />
        
        <Container>
          <div className='grid grid-cols-1 flex-col items-start justify-center'>
            <div>
              <div className='flex flex-col items-start text-left'>
              <h1 className='text-3xl font-bold uppercase text-center w-full my-12'>Documentation for Tool Creators</h1>
                <h2 className='text-2xl font-bold items-center justify-center text-center my-5'>For Marketplace Tool Upload</h2>
                <EAModifier />
                <h2 className='text-2xl font-bold my-6 mt-20'>**The version below works with Dantislicense.ex4/ex5 in the /library which is provided to your customers automatically.**</h2>
                <h2 className='text-2xl font-bold my-6 mt-20'>MT4</h2>
                <h3 className='text-xl my-4 pl-4'>Expert Advisors or Indicators</h3>
                <p className='pl-8'> 
                  STEP ONE - {/*Download the "DantisLicense.ex4"
                  <button onClick={() => handleDownload('/DantisLicense.ex4')} className='btnSecondary3'>
                    Download File
                  </button>
                  and place it in the your MT4 "MQL4/Library" directory <br/>
                  STEP TWO - */}COPY, PASTE, and tailor the code below to use Dantis AI as a creator.  <br/>
                  **The Dantislicense.ex4 is provided to your customers automatically.**
                  <SyntaxHighlighter language="javascript" style={solarizedlight}>
                    {codeString1()}
                  </SyntaxHighlighter>
                </p>

                <h2 className='text-2xl font-bold my-6'>MT5</h2>
                <h3 className='text-xl my-4 pl-4'>Expert Advisors or Indicators</h3>
                <p className='pl-8'> 
                  STEP ONE - {/*Download the "DantisLicense.ex5"
                  <button onClick={() => handleDownload1('/DantisLicense.ex5')} className='btnSecondary3'>
                    Download File
                  </button>
                  and place it in the your MT5 "MQL5/Library" directory <br/>
                  STEP TWO - */}COPY, PASTE, and tailor the code below to use Dantis AI as a creator.  <br/>
                  **The Dantislicense.ex5 is provided to your customers automatically.**
                  <SyntaxHighlighter language="javascript" style={solarizedlight}>
                    {codeString()}
                  </SyntaxHighlighter>
                </p>

              </div>
            </div>
            <ImageHoverChange
              className='mx-auto flex'
              image={'/img/ABOUT-PIC.webp'}
              hoverImage='/img/ABOUT-PIChover.webp'
            />
          </div>
        </Container>
      </FullContainer>
    );
  };
