import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../../store";


const LicenseList = [
  {
    id: "1",
    customer_id: "Test Code",
    product_name: "10",
    allowed_account: "1201"
  },
  {
    id: "1",
    customer_id: "Test Code",
    product_name: "10",
    allowed_account: "1201"
  },
  {
    id: "1",
    customer_id: "Test Code",
    product_name: "10",
    allowed_account: "1201"
  },
]


function Market() {
  const { token } = store.getState().auth;
  const [licenses, setLicenses] = useState(LicenseList);
  const history = useHistory();


  const fetchLicenses = () => {
    console.log("Token: ",  token);
    axios.get(`${process.env.REACT_APP_API_URL}/license/license`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log(response.data)
        setLicenses(response.data);
        console.log("Licenses: ", response.data);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  }
  useEffect(() => {
    // Fetch products from the database when the component mounts
    fetchLicenses();
  }, []);

  const createLicense = (product) => {
    // Prepare the license data
    const licenseData = {
      admin_id: product.admin_id,
      product_name: product.product_name,
      customer_id: '', // Set this value
      allowed_account: null, // Set this value
    };

    // Send a POST request to the "/license" endpoint
    axios.post(`${process.env.REACT_APP_API_URL}/license/license`, licenseData, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        alert('License has been created successfully!');
      })
      .catch(error => {
        console.error("There was an error!", error);
        alert('Failed to create license!');
      });
  };

  return (


    <table className='w-full'>
      <thead className='bg-glass'>
        <tr>
          <th className='text-left'>No.</th>
          <th className='text-left'>Customer ID</th>
          <th className='text-left'>Product Code</th>
          <th className='text-left'>Allowed Account</th>
        </tr>
      </thead>
      <tbody>
        {licenses.map((license, index) => (
          <tr key={index} className='bg-dsecondray'>
            <td className='p-6'>{index + 1}</td>
            <td className='p-6'>{license.customer_id}</td>
            <td className='p-6'>{license.product_code}</td>
            <td className='p-6'>{license.allowed_account}</td>
          </tr>
        ))}
      </tbody>
    </table>

  );
}

export default Market;
