import React, { useEffect } from 'react';
import { Container, FullContainer, Navbar } from "../components";

function ContactUs() {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.id = '64f00959494bff9a99310a24';
    script.src = 'https://app.droxy.ai/chat.min.js';
    script.defer = true;

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <FullContainer className="mt-0">
           <img
        src="/img/hero.webp"
        className="absolute top-0 left-0 h-full w-full -z-10"
        alt=""
      />
      <Navbar />
      <Container>
        <div className="flex flex-col-reverse lg:grid grid-cols-contact gap-10 items-center">
          <img src="img/pic-contact.png" className="-z-20" alt="" />
          <div className="">
            <h1 className="text-3xl font-bold uppercase my-6">Contact Us</h1>
            <p className="my-6">
            TRY our AI Chatbot!<br />
            We would love to hear from you! Please reach out to us and we will
            get back to you as soon as possible
            </p>
            {/* Formspark Form */}
            <form
              action="https://submit-form.com/XWY0G1m0"
              method="POST"
              data-formspark-id="XWY0G1m0"
            >
              <div className="grid grid-cols-1 w-full gap-y-1 gap-x-3">
              <input type="text" name="Full Name" placeholder="Full Name" />
              <input type="text" name="Email" placeholder="Email" />
              <textarea
                name="Message"
                className="block h-[300px] w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Message"
              ></textarea>
              </div>
              <button className="btnSecondary flex mt-5 ml-auto" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Container>
    {/* Add a media query for smaller screens */}
    <style>
        {`
          @media (max-width: 768px) {
            .grid-cols-contact {
              grid-template-columns: 1fr; // Full width for small screens
            }
          }
        `}
      </style>
    </FullContainer>
  );
}

export default ContactUs;