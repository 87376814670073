import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import store from "../../store";
import { Helmet } from 'react-helmet';
import { setAuthTokens, setGuest } from "../../store/slices/auth"
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaTrash } from 'react-icons/fa';
import CheckoutForm from "../Stripe/CheckoutForm";
import { FaFacebookF, FaTwitter, FaInstagram, FaTiktok, FaShareAlt, FaTelegram } from 'react-icons/fa'; // Import social media icons
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);


function ProductPage() {
  const { productCode } = useParams();
  console.log('productCode: ', productCode);
  const { token, isGuest } = store.getState().auth; // Retrieve isGuest from the store
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  // Define loginAsGuest as a const
    const loginAsGuest = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/guest-login`)
        .then(response => {
            // The response will contain the access and refresh tokens
            const { accessToken, refreshToken } = response.data;

            // Dispatch the setAuthTokens action to save the tokens in your Redux state and local storage
            dispatch(setAuthTokens({ token: accessToken, refreshToken: refreshToken }));

            // Dispatch the setGuest action to set isGuest to true in your Redux state and local storage
            dispatch(setGuest(true));
        })
        .catch(error => {
            console.error("There was an error!", error);
        });
    };

    // Use useEffect to call loginAsGuest when the component mounts
    useEffect(() => {
        loginAsGuest();
    }, []);

    const authHeader = `Bearer ${isGuest ? 'guest' : token}`;

    useEffect(() => {
      if (!token) {
        // If the token is not available, return early
        return;
      }
    
      const authHeader = `Bearer ${isGuest ? 'guest' : token}`;
    
      // Fetch the specific product from the database when the component mounts
      axios.request({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/product/products/${productCode}`,
        headers: {
          "content-type": "application/json",
          authorization: authHeader,
        },
        data: {
          productCode: productCode,
        },
      })
      .then(async response => {
        console.log('Fetched product data: ', response.data);
    
        // Fetch admin info for the product
        const adminResponse = await axios.get(`${process.env.REACT_APP_API_URL}/partner/adminInfo/${response.data.admin_id}`, {
          headers: {
            "content-type": "application/json",
            authorization: token,
          },
        });
        console.log(`Fetched admin info for product ${response.data.admin_id}:`, adminResponse.data);
    
        // Add admin info to product
        const productWithAdminInfo = { ...response.data, admin: adminResponse.data };
    
        setProduct(productWithAdminInfo);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
    }, [token, productCode]); // Add 'productCode' as a dependency here
    

    /*// Add 'token' and 'productCode' as dependencies to the useEffect hook
    useEffect(() => {
        if (!token) {
        // If the token is not available, return early
        return;
        }

        const authHeader = `Bearer ${isGuest ? 'guest' : token}`;

        // Fetch the specific product from the database when the component mounts
        axios.request({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/product/products/${productCode}`,
            headers: {
                "content-type": "application/json",
                authorization: authHeader,
            },
            data: {
                productCode: productCode,
            },
        })
        .then(response => {
            console.log('Fetched product data: ', response.data);
            setProduct(response.data);
        })
        .catch(error => {
            console.error("There was an error!", error);
        });
    }, [token, productCode]); // Add 'productCode' as a dependency here
    */

    // Check if product data is still loading
    if (!product) {
        return <div>Loading...</div>;
    }

    const handleLicenseClick = (product) => {
      localStorage.setItem('productCode', product.product_code);
      history.push({
        pathname: '/customer/login',
        state: { from: `/customer/market/${product.product_code}` }
      });
    };

      return (
        <div className='p-2 md:p-5 mt-20'>
          <div className='grid md:grid-cols-12 gap-x-3 gap-y-6 mt-20 pt-20 mb-20 pb-20' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', maxWidth: window.innerWidth <= 500 ? '90vw' : '50vw' }}>
            <div className='col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-2 p-3 md:p-6 rounded-lg bg-glass' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '100%' }}>
              {product.image_url && 
                <img src={`${process.env.REACT_APP_API_URL}/${product.image_url}`} alt={product.product_name} style={{ width: '100%', height: '100%' }} />
              }
              <div className='mt-5' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  <h1 className='text-2xl'>{product.product_name}</h1>
                  <p className='capitalize'>{product.type}</p>
                  <p className='capitalize'>{product.platform}</p>
                </div>
                <h4 className='text-2xl font-bold'>
                  ${product.price}<span className='text-xl font-normal'>/{product.interval}</span>
                </h4>
              </div>
              <p className='capitalize mt-4 mb-1'>By {product.admin.first_name} {product.admin.last_name}</p>
              <div className='mt-8 mb-4' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <button className='capitalize btnSecondary5 animate-pulse' onClick={handleLicenseClick}>
                  License
                </button>
                <div className='mt-8' style={{ textAlign: 'left' }}>
                  <h2>Product Details:</h2>
                  <p>{product.details}</p>
                </div>
                <div className='mt-8' style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                  <FaFacebookF onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')} />
                  <FaTwitter onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`, '_blank')} />
                  <FaInstagram onClick={() => window.open(`https://www.instagram.com/`, '_blank')} />
                  <FaTiktok onClick={() => window.open(`https://www.tiktok.com/`, '_blank')} />
                  <FaTelegram onClick={() => window.open(`https://telegram.me/share/url?url=${window.location.href}`, '_blank')} />
                  <FaShareAlt onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    window.alert('URL has been copied to clipboard!');
                    }} />
                </div>
              </div>
            </div>
          </div>
      
          <Helmet>
            <title>{product.product_name}</title>
            <meta property="og:title" content={product.product_name} />
            <meta property="og:description" content={product.details} />
            <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/${product.image_url}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={product.product_name} />
            <meta name="twitter:description" content={product.details} />
            <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/${product.image_url}`} />
          </Helmet>
        </div>
      );                  
    }

export default ProductPage;

