import { FullContainer, CustomerLoginForm } from "../components";

export default function CLogin() {
  return (
    <FullContainer>
      <div className="grid lg:grid-cols-2 w-full min-h-screen">
        <div className="bg-signup bg-cover bg-center"></div>
        <div className="flex flex-col items-center justify-center p-10">
          <h1 className="heading1 mt-5 capitalize text-center">Sign In</h1>
          <p className="mt-5 text-center text-gray-400 mb-10">
            Welcome back! Please select your <strong className="heading3 mt-5 capitalize">ROLE</strong> to access your account.
          </p>
          <CustomerLoginForm />
        </div>
      </div>
    </FullContainer>
  );
}
