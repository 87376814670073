import Container from "./Container";
import FullContainer from "./FullContainer";
import ImageHoverChange from "./ImageHoverChange";

export default function Reports() {
  return (
    <FullContainer>
      <Container>
        <div className="grid lg:grid-cols-2">
          <ImageHoverChange
            image={"/img/pic4.webp"}
            hoverImage="/img/pic4hover.webp"
          />
          <div className="flex flex-col justify-center p-10">
            <h2 className="heading2 mb-3">Detailed Reports</h2>
            <p>
              Complete transparency on trades, earnings, withdrawals, rankings,
              and referrals.
            </p>
          </div>
        </div>
      </Container>
    </FullContainer>
  );
}