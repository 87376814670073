import FullContainer from "./FullContainer";
import Navbar from "./Navbar";
import React, { useState, useEffect } from 'react';
//import LazySpline from '@splinetool/react-spline';
import { Application } from '@splinetool/runtime';
import Spline from "@splinetool/react-spline";
import Container from "./Container";
import { Link } from "react-router-dom";

export default function BannerTrade() {
  /*const [splineLoaded, setSplineLoaded] = useState(false);

  const handleSplineLoad = () => {
    setSplineLoaded(true);
  };
  useEffect(() => {
    const canvas = document.getElementById('canvas3d');
    const app = new Application(canvas);
    app.load('https://prod.spline.design/6YsvP7hv4jVtoL8T/scene.splinecode').then(() => setSplineLoaded(true));
  }, []);*/
  return (
    <FullContainer className="h-screen w-full relative">
      <div className="w-full absolute top-0 left-0 h-full">
      {/*{!splineLoaded && (
          <img
            src="/img/banner.png" 
            className="absolute top-0 left-0 h-full w-full"
            alt=""
          />
        )}
        <canvas id="canvas3d" className="absolute top-0 left-0 h-full w-full"></canvas>*/}
        {/*<img
          src="/img/banner.png"
          className="absolute top-0 h-full w-full"
          alt=""
        />*/}
        {/*{!splineLoaded && (
          <img
            src="/img/banner.png" 
            className="absolute top-0 left-0 h-full w-full"
            alt=""
          />
        )}
        <Spline scene="https://prod.spline.design/6YsvP7hv4jVtoL8T/scene.splinecode" 
        onLoad={handleSplineLoad}
      />*/}
      </div>
      <img
            src="/img/about-bg.webp"
            className="absolute top-0 left-0 h-full w-full"
            alt=""
        /> 
      <div className="absolute w-full top-0">
        <Navbar />
      </div>
      <div className="flex flex-col items-center upppercase mt-28 z-10 absolute top-0 text-center p-10 lg:p-0">
        <h1 className="text-3xl font-bold italic uppercase mt-5 lg:mt-12">
        Trade Smartly with {" "}
          <span className="text-primary">Dantis AI</span>
        </h1>
        <button className="btnPrimary mt-9" onClick={() => window.gtag('event', 'click', { 'event_category': 'Button', 'event_label': 'Get Started for Free', })}><Link to="/loginmain">Try 7 days for Free</Link>
        </button>
      </div>
      <div className="flex flex-col items-center justify-center lg:w-9/12 text-2xl absolute bottom-0">
        <h1 className="text-sm lg:text-xl font-bold  uppercase mt-5 lg:mt-12 text-center pb-2">
          Trusted by {" "}
          <span className="text-primary">1000+</span> creators and subscribers worldwide.
        </h1>

        {/*<div className="flex flex-wrap justify-center w-full">
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/GCPlogo.png" className="h-7" alt="" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/trustpilot.jpg" className="h-7" alt="" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/exness.png" className="h-7" alt="" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/aafxlogo.png" className="h-7" alt="" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/mt5logo.png" className="h-7" alt="" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/amlogo.png" className="h-7" alt="" />
          </div>
        </div>
        */}
        <div className="flex flex-wrap justify-center w-full">
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/GCPlogo.png" className="h-7" alt="Google Cloud Platform" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/trustpilot.jpg" className="h-7" alt="Trust Pilot 5-stars" />
          </div>
          <div className="loginFormField flex items-center justify-center bg-white m-2 w-1/3 sm:w-auto">
            <img src="./img/stripelogo.png" className="h-7" alt="Stripe" />
          </div>
        </div>
      </div>

    </FullContainer>
  );
}
