import { Bars3Icon } from "@heroicons/react/24/outline";
import { BsSearch } from "react-icons/bs";
import { BiBell } from "react-icons/bi";

export default function DashboardHead({ show_navlabel, toggleNavbar }) {
  const user = "usama";
  return (
    <header className="w-full flex items-center">
      <div className="nav border-b border-[#2A2737] flex w-full bg-black p-6 justify-between items-center">
        <div className="flex">
          {show_navlabel ? (
            <Bars3Icon onClick={toggleNavbar} className="text-primary w-5" />
          ) : (
            <Bars3Icon onClick={toggleNavbar} className="text-primary/50 w-5" />
          )}
          <h1 className="text-3xl text-[#AAA0C7]">Dashboard</h1>
        </div>
        <div className="flex gap-4">
          <div className="flex lg:w-[400px] rounded-full items-center gap-2 py-3 px-6 bg-[#1F1D25]">
            <BsSearch className="text-white" />
            <input
              className="px-2 w-full bg-transparent rounded-full outline-none"
              type="text"
              placeholder="Search for anything"
            />
          </div>

          <button className="bg-[#1F1D25] p-3 rounded-full">
            <BiBell className="text-white  text-2xl" />
          </button>
         
        </div>
      </div>
    </header>
  );
}
//<button>
//<img src="img/user.png" alt="" />
//</button>