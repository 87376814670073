import React, { useEffect } from 'react';
import {
  About,
  Accordian,
  Autonomous,
  BannerTrade,
  Dantis,
  Navbar,
  Reports,
  Specials,
  Support,
} from "../components";
import data from "../siteData.json";
import { Helmet } from 'react-helmet';

export default function Trade() {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.id = '64f00959494bff9a99310a24';
    script.src = 'https://app.droxy.ai/chat.min.js';
    script.defer = true;

    // Append the script to the document's head
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-8T9WDH6YR2');

    // Clean up the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div style={{ maxHeight: '450px', overflow: 'hidden', boxShadow: '0px 10px 15px 5px rgba(128, 0, 128, 0.15)' }}>
        <BannerTrade />
      </div>
      <Dantis />
      <Autonomous />
      <Specials />
      <Support />
      <Reports />

      <Accordian data={data.faqs} />

      {/*<Banner />
      <About />
      <Dantis />
      <Autonomous />
      <Specials />
      <Support />
      <Reports />
      <Accordian data={data.faqs} />*/}

      {/* Add a media query for smaller screens */}
      <style>
        {`
          @media (max-width: 768px) {
            .home-container {
              padding: 20px; // Adjust the padding for smaller screens
            }
          }
        `}
      </style>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Dantis AI. AI for finance.</title>
      <meta name="description" content="This is a web app about AI in finance, trading. Make money from day 1. Eliminates the learning curve in trading." />
      <meta name="keywords" content="
        finance AI, 
        AI trading, 
        trading algorithms, 
        algorithmic trading, 
        expert advisor, 
        trading advisor, 
        financial advisor, 
        trading indicator, 
        technical indicators, 
        cloud trading, 
        cloud-based trading, 
        MetaTrader, 
        MetaTrader 4, 
        MetaTrader 5, 
        MT4, 
        MT5, 
        forex trading, 
        stock trading, 
        commodities trading, 
        futures trading, 
        trading platform, 
        automated trading, 
        robo-advisor, 
        financial technology, 
        fintech, 
        trade execution, 
        backtesting, 
        trading strategy, 
        risk management, 
        market analysis, 
        trading signals, 
        trading software, 
        trading tools, 
        day trading, 
        swing trading, 
        scalping, 
        position trading, 
        high-frequency trading, 
        algorithmic trading software, 
        trading system, 
        trading bot, 
        AI trading bot, 
        machine learning trading, 
        deep learning trading, 
        quantitative trading, 
        trading analytics, 
        predictive analytics, 
        trading data, 
        big data trading, 
        cloud computing, 
        SaaS, 
        PaaS, 
        IaaS, 
        cloud services, 
        cloud technology, 
        cloud infrastructure, 
        cloud storage, 
        cloud security, 
        cloud applications, 
        cloud solutions, 
        cloud networking, 
        cloud data, 
        cloud database, 
        cloud software, 
        cloud systems, 
        cloud architecture, 
        cloud migration, 
        cloud integration, 
        cloud optimization, 
        cloud scalability, 
        cloud performance, 
        cloud strategy, 
        cloud transformation, 
        digital transformation, 
        cloud providers, 
        cloud platforms, 
        public cloud, 
        private cloud, 
        hybrid cloud, 
        multi-cloud, 
        cloud computing companies, 
        cloud industry, 
        cloud trends, 
        cloud innovation, 
        cloud technologies, 
        cloud IT, 
        cloud resources, 
        cloud management, 
        cloud computing technology, 
        cloud computing services, 
        cloud computing solutions, 
        cloud computing systems, 
        cloud computing applications, 
        cloud computing platforms, 
        cloud computing infrastructure, 
        cloud computing software, 
        cloud computing security, 
        cloud computing advantages, 
        cloud computing benefits, 
        cloud computing trends, 
        cloud computing future, 
        cloud computing industry, 
        cloud computing market, 
        cloud computing growth, 
        cloud computing business, 
        cloud computing strategy, 
        cloud computing challenges, 
        cloud computing issues, 
        cloud computing risks, 
        cloud computing impact, 
        cloud computing insights, 
        cloud computing news, 
        cloud computing research, 
        cloud computing reports, 
        cloud computing studies, 
        cloud computing statistics, 
        cloud computing facts, 
        cloud computing analysis, 
        cloud computing predictions, 
        cloud computing forecasts, 
        cloud computing projections, 
        cloud computing developments, 
        cloud computing breakthroughs, 
        cloud computing advancements, 
        cloud computing progress, 
        cloud computing evolution, 
        cloud computing revolution, 
        cloud computing transformation, 
        cloud computing innovation, 
        cloud computing exploration, 
        cloud computing experimentation, 
        cloud computing investigation, 
        cloud computing examination, 
        cloud computing observation, 
        cloud computing discovery, 
        cloud computing findings, 
        cloud computing conclusions, 
        cloud computing results, 
        cloud computing outcomes, 
        cloud computing success, 
        cloud computing achievements, 
        cloud computing accomplishments, 
        cloud computing milestones, 
        cloud computing victories, 
        cloud computing triumphs, 
        cloud computing wins, 
        cloud computing gains, 
        cloud computing improvements, 
        cloud computing enhancements, 
        cloud computing upgrades, 
        cloud computing updates, 
        cloud computing changes, 
        cloud computing modifications, 
        cloud computing alterations, 
        cloud computing adjustments, 
        cloud computing amendments, 
        cloud computing corrections, 
        cloud computing fixes, 
        cloud computing solutions, 
        cloud computing remedies, 
        cloud computing cures, 
        cloud computing treatments, 
        cloud computing therapies, 
        cloud computing interventions, 
        cloud computing actions, 
        cloud computing measures, 
        cloud computing steps, 
        cloud computing procedures, 
        cloud computing methods, 
        cloud computing techniques, 
        cloud computing approaches, 
        cloud computing strategies, 
        cloud computing tactics, 
        cloud computing plans, 
        cloud computing schemes, 
        cloud computing programs, 
        cloud computing projects, 
        cloud computing initiatives, 
        cloud computing efforts, 
        cloud computing endeavors, 
        cloud computing enterprises, 
        cloud computing ventures, 
        cloud computing operations, 
        cloud computing activities, 
        cloud computing tasks, 
        cloud computing jobs, 
        cloud computing duties, 
        cloud computing responsibilities, 
        cloud computing roles, 
        cloud computing functions, 
        cloud computing services, 
        cloud computing works, 
        cloud computing deeds, 
        cloud computing performances, 
        cloud computing executions, 
        cloud computing implementations, 
        cloud computing applications, 
        cloud computing uses, 
        cloud computing utilizations, 
        cloud computing employments, 
        cloud computing exploitations, 
        cloud computing manipulations, 
        cloud computing controls, 
        cloud computing managements, 
        cloud computing administrations, 
        cloud computing supervisions, 
        cloud computing directions, 
        cloud computing guidances, 
        cloud computing instructions, 
        cloud computing teachings, 
        cloud computing trainings, 
        cloud computing educations, 
        cloud computing learnings, 
        cloud computing studies, 
        cloud computing researches, 
        cloud computing investigations, cloud computing examinations, 
        cloud computing inspections, 
        cloud computing checks, 
        cloud computing audits, 
        cloud computing reviews, 
        cloud computing assessments, 
        cloud computing evaluations, 
        cloud computing appraisals, 
        cloud computing analyses, 
        cloud computing explorations, 
        cloud computing experimentations, 
        cloud computing tests, 
        cloud computing trials, 
        cloud computing probations, 
        cloud computing practices, 
        cloud computing exercises, 
        cloud computing drills, 
        cloud computing rehearsals, 
        cloud computing preparations, 
        cloud computing arrangements, 
        cloud computing organizations, 
        cloud computing coordinations, 
        cloud computing integrations, 
        cloud computing unifications, 
        cloud computing combinations, 
        cloud computing mergers, 
        cloud computing fusions, 
        cloud computing amalgamations, 
        cloud computing consolidations, 
        cloud computing incorporations, 
        cloud computing absorptions, 
        cloud computing assimilations, 
        cloud computing adaptations, 
        cloud computing conformations, 
        cloud computing compliances, 
        cloud computing obediences, 
        cloud computing submissions, 
        cloud computing surrenders, 
        cloud computing yields, 
        cloud computing resignations, 
        cloud computing relinquishments, 
        cloud computing abandonments, 
        cloud computing desertions, 
        cloud computing departures, 
        cloud computing exits, 
        cloud computing withdrawals, 
        cloud computing retreats, 
        cloud computing retirements, " />
        </Helmet>
        </div>
  );
}
