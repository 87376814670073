import React from "react";
import { Container, FullContainer, Navbar } from "../components";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function FAQ() {
  return (
    <FullContainer className="mt-0 ">

      <Navbar />
      <Container>
        <h1 className="text-3xl font-bold uppercase text-center mb-10">
          Dantis Cloud ☁️
        </h1>

        <div className="grid lg:grid-cols-3 gap-5 mt-10">
          
          {[
            { title: "DANTIS CLOUD", price: "$1 for first 7 days", price2: "then $24.99/month", price3: "add multiple setups, pay less!", features: ["💚 24/7 Global Accessibility", "💚 Cloud-Based Efficiency", "💚 Upload EA at setup", "💚 Pre-Installed MetaTrader", "💚 1-Click Setup", "💚 Optimized for Success"] },
            { title: "Competitors", price: "~$38+/month", features: ["🔴 Limited Accessibility", "🔴 VM Hosting", "🔴 Basic Technology", "🔴 Manual MetaTrader Setup", " 🔴 Slow Setup", "🔴 Not Optimized"] }
          ].map(({ title, price, price2, price3, features }, index) => (
            <div key={index} className={`flex flex-col ${title === 'DANTIS CLOUD' ? 'bg-glass bg-opacity-70' : 'bg-[#302D41] bg-opacity-50'} py-5 px-10 text-center rounded-xl ${index === 0 ? 'lg:col-span-2' : ''}`}>
              <h3 className={`text-lg lg:text-2xl font-bold my-2 ${index === 0 ? 'liner_gradient lg:text-4xl' : ''}`}>{title}</h3>
              <p className="text-lg lg:text-xl font-bold my-2">{price}<br /><span className="text-sm">{price2}</span><br /><span className="text-sm">{price3}</span></p>
              <ul className="text-left">
                {features.map((feature, index) => (
                  <li key={index} className="mt-4 mb-4">{feature}</li>
                ))}
              </ul>
              {title === 'DANTIS CLOUD' && (
                <Link 
                  to="/customer/login" 
                  className="btnPrimary1 w-fit mx-auto items-center mt-5 bg-purple-500 animate-pulse animate-shake"
                  onClick={() => window.gtag('event', 'click', { 'event_category': 'Button', 'event_label': 'Get Cloud for $1', })}
                >
                  Get Cloud for $1
                </Link>
              )}
            </div>
          ))}
        </div>

        <div className="grid lg:grid-cols-1 gap-5 mt-20">
          <div className="flex flex-col bg-glass py-5 px-10 rounded-xl">
            <h1 className="text-xl lg:text-3xl font-bold uppercase my-5">
              WHAT is Dantis Cloud?
            </h1>
            <p>
              <strong>🌐 Global Accessibility:</strong> Dantis Cloud is available 24/7 from your browser anywhere in the world. Trade anytime, anywhere!<br /><br />
              <strong>☁️ Cloud-Based Efficiency:</strong> Host your MetaTrader on Dantis Cloud for maximum efficiency and uninterrupted trading.<br /><br />
              <strong>🚀 Advanced Technology:</strong> Dantis Cloud is the most advanced MetaTrading cloud hosting service. Experience the future of trading!<br /><br />
              <strong>🖥️ Pre-Installed MetaTrader:</strong> With MetaTrader already installed and running, you can start trading immediately. No setup hassles!<br /><br />
              <strong>⏱️ Quick Setup:</strong> Get started in just 5 seconds with just one click. Trading has never been this easy!<br /><br />
              <strong>📈 Optimized for Success:</strong> With your trading account logged in and your files uploaded, Dantis Cloud is optimized for your success.<br /><br />
              <strong>Sign Up</strong> or Log in to Dantis Cloud today and experience the future of trading!
            </p>
            <Link 
              to="/customer/login" 
              className="btnPrimary1 w-fit mx-auto items-center mt-5 bg-purple-500 animate-pulse animate-shake" // Added 'bg-purple-500' for purple background
              onClick={() => window.gtag('event', 'click', { 'event_category': 'Button', 'event_label': 'Get Cloud for $1', })}
            >
              Get Cloud for $1
            </Link>
          </div>
        </div>

        <div className="grid lg:grid-cols-3 gap-5 mt-10">
          <h2 className="text-2xl font-bold uppercase text-center mb-5 col-span-full">What Our Users Say</h2>
          {[
            { name: "Luke Bradley", review: "I've been using Dantis Cloud for a few months now and it's been a game changer. The platform is easy to use and the support team is always there to help. Highly recommended!" },
            { name: "Thandi Busiswa", review: "Dantis Cloud has made my trading experience so much smoother. I love the convenience and ease of use. It's a must-have for any trader!" },
            { name: "Craig Mount", review: "The efficiency and reliability of Dantis Cloud is unmatched. I've seen a significant improvement in my trading performance since I started using it." }
          ].map(({ name, review }, index) => (
            <div key={index} className="flex flex-col bg-glass py-5 px-10 text-center rounded-xl">
              <h3 className="text-lg lg:text-xl font-bold my-2">{name}</h3>
              <p>
                "{review}"
              </p>
            </div>
          ))}
        </div>
        <Helmet>
      <meta charSet="utf-8" />
      <title>Dantis Cloud. Cloud for Metatrader. MT4/MT5</title>
      <meta name="description" content="Set up MetaTrader on the cloud in one click. MT4/MT5" />
      <meta name="keywords" content="
        Dantis Cloud,
        finance AI, cloudzy, forexVPS, forex vps, vps for forex, mt5 on the cloud, mt4 on the cloud 
        AI trading, 
        trading algorithms, 
        algorithmic trading, 
        expert advisor, 
        trading advisor, 
        financial advisor, 
        trading indicator, 
        technical indicators, 
        cloud trading, 
        cloud-based trading, 
        MetaTrader, 
        MetaTrader 4, 
        MetaTrader 5, 
        MT4, 
        MT5, 
        forex trading, 
        stock trading, 
        commodities trading, 
        futures trading, 
        trading platform, 
        automated trading, 
        robo-advisor, 
        financial technology, 
        fintech, 
        trade execution, 
        backtesting, 
        trading strategy, 
        risk management, 
        market analysis, 
        trading signals, 
        trading software, 
        trading tools, 
        day trading, 
        swing trading, 
        scalping, 
        position trading, 
        high-frequency trading, 
        algorithmic trading software, 
        trading system, 
        trading bot, 
        AI trading bot, 
        machine learning trading, 
        deep learning trading, 
        quantitative trading, 
        trading analytics, 
        predictive analytics, 
        trading data, 
        big data trading, 
        cloud computing, 
        SaaS, 
        PaaS, 
        IaaS, 
        cloud services, 
        cloud technology, 
        cloud infrastructure, 
        cloud storage, 
        cloud security, 
        cloud applications, 
        cloud solutions, 
        cloud networking, 
        cloud data, 
        cloud database, 
        cloud software, 
        cloud systems, 
        cloud architecture, 
        cloud migration, 
        cloud integration, 
        cloud optimization, 
        cloud scalability, 
        cloud performance, 
        cloud strategy, 
        cloud transformation, 
        digital transformation, 
        cloud providers, 
        cloud platforms, 
        public cloud, 
        private cloud, 
        hybrid cloud, 
        multi-cloud, 
        cloud computing companies, 
        cloud industry, 
        cloud trends, 
        cloud innovation, 
        cloud technologies, 
        cloud IT, 
        cloud resources, 
        cloud management, 
        cloud computing technology, 
        cloud computing services, 
        cloud computing solutions, 
        cloud computing systems, 
        cloud computing applications, 
        cloud computing platforms, 
        cloud computing infrastructure, 
        cloud computing software, 
        cloud computing security, 
        cloud computing advantages, 
        cloud computing benefits, 
        cloud computing trends, 
        cloud computing future, 
        cloud computing industry, 
        cloud computing market, 
        cloud computing growth, 
        cloud computing business, 
        cloud computing strategy, 
        cloud computing challenges, 
        cloud computing issues, 
        cloud computing risks, 
        cloud computing impact, 
        cloud computing insights, 
        cloud computing news, 
        cloud computing research, 
        cloud computing reports, 
        cloud computing studies, 
        cloud computing statistics, 
        cloud computing facts, 
        cloud computing analysis, 
        cloud computing predictions, 
        cloud computing forecasts, 
        cloud computing projections, 
        cloud computing developments, 
        cloud computing breakthroughs, 
        cloud computing advancements, 
        cloud computing progress, 
        cloud computing evolution, 
        cloud computing revolution, 
        cloud computing transformation, 
        cloud computing innovation, 
        cloud computing exploration, 
        cloud computing experimentation, 
        cloud computing investigation, 
        cloud computing examination, 
        cloud computing observation, 
        cloud computing discovery, 
        cloud computing findings, 
        cloud computing conclusions, 
        cloud computing results, 
        cloud computing outcomes, 
        cloud computing success, 
        cloud computing achievements, 
        cloud computing accomplishments, 
        cloud computing milestones, 
        cloud computing victories, 
        cloud computing triumphs, 
        cloud computing wins, 
        cloud computing gains, 
        cloud computing improvements, 
        cloud computing enhancements, 
        cloud computing upgrades, 
        cloud computing updates, 
        cloud computing changes, 
        cloud computing modifications, 
        cloud computing alterations, 
        cloud computing adjustments, 
        cloud computing amendments, 
        cloud computing corrections, 
        cloud computing fixes, 
        cloud computing solutions, 
        cloud computing remedies, 
        cloud computing cures, 
        cloud computing treatments, 
        cloud computing therapies, 
        cloud computing interventions, 
        cloud computing actions, 
        cloud computing measures, 
        cloud computing steps, 
        cloud computing procedures, 
        cloud computing methods, 
        cloud computing techniques, 
        cloud computing approaches, 
        cloud computing strategies, 
        cloud computing tactics, 
        cloud computing plans, 
        cloud computing schemes, 
        cloud computing programs, 
        cloud computing projects, 
        cloud computing initiatives, 
        cloud computing efforts, 
        cloud computing endeavors, 
        cloud computing enterprises, 
        cloud computing ventures, 
        cloud computing operations, 
        cloud computing activities, 
        cloud computing tasks, 
        cloud computing jobs, 
        cloud computing duties, 
        cloud computing responsibilities, 
        cloud computing roles, 
        cloud computing functions, 
        cloud computing services, 
        cloud computing works, 
        cloud computing deeds, 
        cloud computing performances, 
        cloud computing executions, 
        cloud computing implementations, 
        cloud computing applications, 
        cloud computing uses, 
        cloud computing utilizations, 
        cloud computing employments, 
        cloud computing exploitations, 
        cloud computing manipulations, 
        cloud computing controls, 
        cloud computing managements, 
        cloud computing administrations, 
        cloud computing supervisions, 
        cloud computing directions, 
        cloud computing guidances, 
        cloud computing instructions, 
        cloud computing teachings, 
        cloud computing trainings, 
        cloud computing educations, 
        cloud computing learnings, 
        cloud computing studies, 
        cloud computing researches, 
        cloud computing investigations, cloud computing examinations, 
        cloud computing inspections, 
        cloud computing checks, 
        cloud computing audits, 
        cloud computing reviews, 
        cloud computing assessments, 
        cloud computing evaluations, 
        cloud computing appraisals, 
        cloud computing analyses, 
        cloud computing explorations, 
        cloud computing experimentations, 
        cloud computing tests, 
        cloud computing trials, 
        cloud computing probations, 
        cloud computing practices, 
        cloud computing exercises, 
        cloud computing drills, 
        cloud computing rehearsals, 
        cloud computing preparations, 
        cloud computing arrangements, 
        cloud computing organizations, 
        cloud computing coordinations, 
        cloud computing integrations, 
        cloud computing unifications, 
        cloud computing combinations, 
        cloud computing mergers, 
        cloud computing fusions, 
        cloud computing amalgamations, 
        cloud computing consolidations, 
        cloud computing incorporations, 
        cloud computing absorptions, 
        cloud computing assimilations, 
        cloud computing adaptations, 
        cloud computing conformations, 
        cloud computing compliances, 
        cloud computing obediences, 
        cloud computing submissions, 
        cloud computing surrenders, 
        cloud computing yields, 
        cloud computing resignations, 
        cloud computing relinquishments, 
        cloud computing abandonments, 
        cloud computing desertions, 
        cloud computing departures, 
        cloud computing exits, 
        cloud computing withdrawals, 
        cloud computing retreats, 
        cloud computing retirements, " />
        </Helmet>
      </Container>
    </FullContainer>
  );
}

export default FAQ;
