import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import TextField from "./TextField";
import { Formik, Form } from "formik";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import authSlice from "../store/slices/auth";


export default function SignupForm({ showPopup }) {
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useHistory();
  
  // Add a state for user role and set its initial value to 'customer'
  const [userRole, setUserRole] = useState('customer');

  return (
    <div className="flex items-center justify-center flex-col w-full">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        //validationSchema={FORM_VALIDATION}f
        onSubmit={async (values, actions) => {
          const apiUrl = `${process.env.REACT_APP_API_URL}/${userRole}/login`;
          
          let response;
          try {
            response = await axios.post(apiUrl, values);
            console.log(response);
          } catch (err) {
            console.error(err.response.data);
            alert("Invalid Email or Password or check PARTNER or CUSTOMER");
            actions.setSubmitting(false);
            return;
          }
          
          if (response.data.status) {
            dispatch(
              authSlice.actions.setAuthTokens({
                token: response.data.access,
                refreshToken: response.data.access,
              })
            );
            dispatch(authSlice.actions.setAccount(response.data.user));
            console.log("Dispatched Set")
          } else {
            alert(`Login Failed: Invalid Email or Password, or check PARTNER or CUSTOMER`);
            actions.setSubmitting(false);
            return;
          }
          
          // Redirection logic
          let { from } = history.location.state || { from: { pathname: `/${userRole}/${userRole}dashboard` } };
          console.log("from;", from);
          
          // Redirect to the 'from' path or the user dashboard
          //history.push(from);
          if (from) {
            history.push(from);
          } else {
              // Handle the case when 'from' is undefined
              // Redirect to a default location
              history.push(`/${userRole}/${userRole}dashboard`);
          }
          
          actions.setSubmitting(false);
        }}
        
        /*onSubmit={async (values, actions) => {
          console.log(values);
          
          // Update the API URL based on the user role
          const apiUrl = `${process.env.REACT_APP_API_URL}/${userRole}/login`;
          console.log(apiUrl);
          
          const response = await axios
          .post(apiUrl, values)
          .then(function (res) {
            console.log(res);
            if (res.data.status) {
              dispatch(
                authSlice.actions.setAuthTokens({
                  token: res.data.access,
                  refreshToken: res.data.access,
                })
              );
              dispatch(authSlice.actions.setAccount(res.data.user));
              console.log("Dispatched Set")
              if (res.data.status) {
                // Check if there's a 'from' state in the history
                let { from } = history.location.state || { from: { pathname: `/${userRole}/${userRole}dashboard` } };
                console.log("from;", from);
                // If 'from' state contains a product code, redirect to the product on the /market page
                if (from.pathname.startsWith('/customer/market')) {
                  console.log('Redirecting to:', from);
                  history.push(from);
                  console.log('Redirection should have occurred');
                } else {
                  // If 'from' state does not contain a product code, redirect to the user dashboard
                  history.push(`/${userRole}/${userRole}dashboard`);
                }
              }
            } else {
              alert(`Login Failed: Invalid Email or Password, or check PARTNER or CUSTOMER`);
            }
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          })
          .catch(function (err) {
            console.error(err.response.data);
            alert("Invalid Email or Password or check PARTNER or CUSTOMER");
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          });
          console.log(response);
        }}*/
      >
        <Form className="grid grid-cols-1 gap-y-1 gap-x-1 w-full mt-1">
          {/* Add a dropdown or radio buttons for user to select their role */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <label style={{ marginRight: '10px' }} className="text-primary" >Role</label>
            <select 
              style={{ borderRadius: '5px', color: '#ffffff', backgroundColor: '#4a4a4a' }}
              onChange={(e) => setUserRole(e.target.value)}
              defaultValue="customer"
            >
              <option value="customer">Customer</option>
              <option value="partner">Partner</option>
            </select>
          </div>
          <TextField name="email" type="email" label="Email" />
          <TextField name="password" type="password" label="Password" />
          <div className="w-full">
            <p className="text-primary underline mt-2">Forgot Password</p>
          </div>
          <input className="mt-5 btnPrimary" type="submit" value="Sign In" />
        </Form>
      </Formik>

      {/* Submit Button */}

      <div className="text-center mt-3 capitalize">
        New on our platform?{" "}
        <span>
        <Link to={{
          pathname: "/customer/signup",
          state: { from: history.location.state?.from || `/${userRole}/${userRole}dashboard` }
        }}
          onClick={showPopup}
          className="text-primary underline"
        >
          Signup
        </Link>{" "}
        </span>
      </div>
      <div className="text-center mt-3 capitalize">
        <span>
          <Link to="/" onClick={showPopup} className="text-primary underline">
            Go back
          </Link>{" "}
        </span>
        to home page
      </div>
    </div>
  );
}


/*
export default function SignupForm({ showPopup }) {
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="flex items-center justify-center flex-col w-full">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        //validationSchema={FORM_VALIDATION}f
        onSubmit={async (values, actions) => {
          console.log(values);
          console.log(`${process.env.REACT_APP_API_URL}/customer/login`)
          const response = await axios
            .post(`${process.env.REACT_APP_API_URL}/customer/login`, values)
            .then(function (res) {
              console.log(res);
              if (res.data.status) {
                dispatch(
                  authSlice.actions.setAuthTokens({
                    token: res.data.access,
                    refreshToken: res.data.access,
                  })
                );
                dispatch(authSlice.actions.setAccount(res.data.user));
                console.log("Disptached Set")
                if (res.data.status) {
                  history.push("/customer/customerdashboard");
                }else{
                  alert("Invalid Email & Password combination");
                }
              } else {
                alert(`Login Failed ${JSON.stringify(res)}`);
              }
            })
            .catch(function (err) {
              console.error("There was an error!", err);
            });
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
          console.log(response);
        }}
      >
        <Form className="grid grid-cols-1 gap-y-1 gap-x-1 w-full mt-1">
          <TextField name="email" type="email" label="Email" />
          <TextField name="password" type="password" label="Password" />
          <div className="w-full">
            <p className="text-primary underline mt-2">Forgot Password</p>
          </div>
          <input className="mt-5 btnPrimary" type="submit" value="Sign In" />
        </Form>
      </Formik>

      

      <div className="text-center mt-3 capitalize">
        New on our platform?{" "}
        <span>
          <Link to="/customer/signup" onClick={showPopup} className="text-primary underline">
            Signup
          </Link>{" "}
        </span>
      </div>
      <div className="text-center mt-3 capitalize">
        <span>
          <Link to="/" onClick={showPopup} className="text-primary underline">
            Go back
          </Link>{" "}
        </span>
        to home page
      </div>
    </div>
  );
}*/
