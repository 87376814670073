import React from "react";
import { FullContainer, PartnerSignup } from "../components";

export default function PSignup() {
  return (
    <FullContainer >
      <div className="grid lg:grid-cols-2 w-full min-h-screen">
        <div className="bg-signup bg-cover bg-center"></div>
        <div className="flex flex-col items-center p-10">
          
          <h1 className="heading1 mt-5 capitalize text-center">Sign Up and Start</h1>
          <p className="mt-5 text-center text-gray-400 mb-10">Welcome to the cutting-edge Dantis licensing platform</p>
          <PartnerSignup />
        </div>
      </div>
    </FullContainer>
  );
}
