import { useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import store from "../../store";
//import store from "../store";
import { Chart, LineController, TimeScale, LinearScale, PointElement, LineElement } from 'chart.js';
import axios from "axios";
import moment from 'moment';
import { registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // Adapter for using Moment.js as the date adapter
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StarIcon from '@material-ui/icons/Star';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaTrash } from 'react-icons/fa';
import CheckoutForm from "../Stripe/CheckoutForm";
import formatUnixTimestamp from "../../utils/functions";
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);
// Register necessary plugins
Chart.register(...registerables);

const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

function getCurrentDate() {
  const currentDate = new Date();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const year = String(currentDate.getFullYear()).substr(-2);

  return `${month}/${day}/${year}`;
}

function getCurrentDateDifference(tradeDetails) {
  if (tradeDetails.length > 0) {
    const todayDifference = tradeDetails[0].profit - tradeDetails[0].loss;
    return todayDifference;
  }
  return 0;
}

export default function PartnerCustomer() {
  const { token } = store.getState().auth;
  const [tradeDetails, setTradeDetails] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const auth = useSelector((state) => state.auth);
  const [accountInfo, setAccountInfo] = useState({});
  const [fetched, setFetching] = useState(false);
  const [dailyRecord, setRecord] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  console.log("Here is customer info",customerInfo);
  const [email,setEmail]=useState('');
  const [showModal, setShowModal] = useState(false);
 
  

  // 
  /*useEffect(() => {
    const { token, account } = store.getState().auth;
    // Fetch trade details from the backend API
    axios.get(`${process.env.REACT_APP_API_URL}/getAccountHistory`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    ) // Adjust the URL to match your API endpoint
      .then((response) => { //console.log(response.data.records); 
        setRecord(response.data.records)
      })
      .catch(error => console.error("Error fetching trade details:", error));
    getAccountInfo();
  }, []);*/

  // Initialize history
  const history = useHistory();

  const takeToBroker = () => {
    //window.location.replace('http://localhost:3000/Setup');
    // Navigate to the "Setup" page
    history.push("/customer/market");
  };

  const takeToBrokerAI = () => {
    //window.location.replace('http://localhost:3000/Setup');
    // Navigate to the "Setup" page
    history.push("/login");
  };

  const takeToPartner = () => {
    //window.location.replace('http://localhost:3000/Setup');
    // Navigate to the "Setup" page
    history.push("/partner/login");
  };


  const takeToCloud =async () => {
   await startPaymentProcess();

  };

  // stripe handler
  const [clientSecret, setClientSecret] = useState("");
  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#212d63',
      colorText: '#ffffff',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  const startPaymentProcess = async () => {

    setShowModal(true)
    await fetch(`${process.env.REACT_APP_API_URL}/create-cloud-subscription`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
      body: JSON.stringify({
        email: customerInfo.email,
        promotekit_referral: window.promotekit_referral, // promo referral added
      }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);

        console.log('this is new account data', clientSecret)
      }).catch((err) => {
        // setProcess(false)
      })


  };


  // saved card handle
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [productData, setProductData] = useState({});
  const [expireDate, setExpireDate] = useState(null);
  console.log('Saved payment methods', savedPaymentMethods);
  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };
  const stripe = useStripe();
  const handlePayWithSavedCard = async (id) => {
    const result = window.confirm("Are you sure you want pay with this card , This cannot be undone?");

    if (result === true) {
      // User clicked OK
      console.log(id);

      await fetch(`${process.env.REACT_APP_API_URL}/create-cloud-subscription-saved-card`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          email: customerInfo.email,
          promotekit_referral: window.promotekit_referral, // promo referral added
          paymentMethodId: id,
        }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
      })
        .then((res) => res.json())
        .then(async(data) => {
          setClientSecret(data.clientSecret);
          if (
            data.clientSecret
          ) {
            const { paymentIntent } = await stripe.handleCardPayment(data.clientSecret);
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              alert("Error: " + result.error.message);
              //console.log(result.error.message);

              // this.setState({
              //     isLoading: false
              // })
            } else {
              // The payment has been processed!
              if (paymentIntent.status === 'succeeded') {

                window.alert("Successfully purchased")
                setShowModal(false);
                history.push("/customer/cloudsetup");

              }
            }
          }
          console.log('this is new account data', clientSecret)
        }).catch((err) => {
          // setProcess(false)
        })



    } else {
      // User clicked Cancel or closed the dialog
      // Do nothing or handle accordingly
    }
  };

  const renderCardIcon = (brand) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return <FaCcVisa size={30} />;
      case 'mastercard':
        return <FaCcMastercard size={30} />;
      case 'amex':
        return <FaCcAmex size={30} />;
      default:
        return null;
    }
  };
  // Function to handle payment with saved card
  // 








  /*const [AccountInfo, SetAccountInfo] = useState({
    FullName:"",
    accountid: 0,
    user_id: 0,
    email: 0,
    status: 0,
    expiryDate: 0,
    accountBalance: 0,
    pnl: 0,
    brokerLink: "www.exness.com",
  });
  const getAccountInfo = () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/UserProfileInfo/`,
          {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          }
        )
        .then(function (res) {
          console.log(res);
          const result = res.data;
          SetAccountInfo({ ...result });
        })
        .catch(function (err) {
          console.error("There was an error!", err);
        });
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };

  // Initialize state with values from localStorage or default values
const [status, setStatus] = useState(localStorage.getItem('status') || '');

// Function to fetch status from server
const fetchStatus = async (accountid) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/status/${AccountInfo.accountid}`);

    if (response.status !== 200) {
      console.error("Failed to fetch status");
    } else {
      const statusData = await response.json();
      const status = statusData.records[0].status; // Assuming 'status' is a field in the record
      console.log(statusData)
      setStatus(status);
      localStorage.setItem('status', status);
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};

// Use useEffect to fetch status when accountid has been fetched and every 15 minutes
useEffect(() => {
  if (AccountInfo.accountid !== 0) {
    const accountid = AccountInfo.accountid; // Replace with actual accountid
    fetchStatus(accountid);

    // Fetch status every 15 minutes
    const intervalId = setInterval(() => {
      fetchStatus(accountid);
    }, 15 * 60 * 1000); // 15 minutes in milliseconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }
}, [AccountInfo.accountid]); // Add AccountInfo.accountid as a dependency*/

  /*
  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(async(response) => {
        // Update state with customer info
        setCustomerInfo(response.data);
        await fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionInformation`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: token,
          },
          body: JSON.stringify({
            subscriptionId: response.data.cloudSubscriptionId,
          }), 
        })
          .then((res) => res.json())
          .then((data) => {
            setExpireDate(data.current_period_end)
          }).catch((err) => {
            // setProcess(false)
          })
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
      });
  }, []);*/

  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(async(response) => {
        // Update state with customer info
        setCustomerInfo(response.data);
        console.log(response.data);
        // Check if cloudSubscriptionId is not null
        if (response.data.cloudSubscriptionId) {
          await fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionInformation`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
            body: JSON.stringify({
              subscriptionId: response.data.cloudSubscriptionId,
            }), 
          })
            .then((res) => res.json())
            .then((data) => {
              setExpireDate(data.current_period_end)
            }).catch((err) => {
              // setProcess(false)
            })
        }
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
      });
  }, []);

  /*// Create a new state for the customer info
  const [customerInfo, setCustomerInfo] = useState({});
  
  // Fetch customer info when component mounts
  useEffect(() => {
      const { token, account } = store.getState().auth;
      const email = account.email; // Retrieve the email address
      console.log(account.email)
  
      axios.get(
          `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
              headers: {
                  "content-type": "application/json",
                  authorization: token,
              },
              params: {
                  email: email, // Include the email address as a query parameter
              },
          })
          .then(response => {
              // Update state with customer info
              setCustomerInfo(response.data);
          })
          .catch(error => {
              console.error('Error fetching customer info:', error);
          });
  }, []);*/

  // Create a new state for the products
  const [Products, setProducts] = useState([]);

  // Fetch products when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    console.log('product token;', token);
    axios.get(`${process.env.REACT_APP_API_URL}/product/products`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // Function to handle license cancellation
  const cancelLicense = (license_id) => {
    const { token, account } = store.getState().auth;
    axios.delete(`{process.env.REACT_APP_API_URL}/license/license/${license_id}`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log('Success:', response.data);
        // Update LicenseInfo or handle response as needed
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const [showPopup, setShowPopup] = useState(false);
  const [accountId, setAccountId] = useState(0); //useState(AccountInfo.accountid);

  const handleManageClick = () => {
    setShowPopup(true);
  };

  /*const handleSaveClick = () => {
    // Save the accountId here
    // Then close the popup
    setShowPopup(false);
  };*/

  /*const handleSaveClick = () => {
    // Assuming accountId is defined and holds the account id you want to save
    sessionStorage.setItem('accountId', accountId);

    // Then close the popup
    setShowPopup(false);
  };*/

  const handleSaveClick = async () => {
    console.log('Save button clicked!');
    console.log('accountId:', accountId);
  
    // Define your endpoint
    const endpoint = '/license';
  
    // Get the token from the store
    const { token } = store.getState().auth;
    console.log(customerInfo.customer_id);
  
    // Define the data you want to send in the body of the request
    const data = {
      account_number: accountId,
      license_code: LicenseInfo.license_id,
      //customer_id: license.customer_id,
    };
  
    // Send a PUT request to the endpoint
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/license/license`, data, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token,
        },
      });
  
      // You can use the response here if needed
  
    } catch (error) {
      console.error('There was an error with the axios operation: ', error);
    }
  
    // Then close the popup
    setShowPopup(false);
  };


  const classes = useStyles();
  const isCloudSubscriptionActive = customerInfo.cloudSubscriptionStatus;

  const [licenses, setLicenses] = useState([]);
  const getUserLicenses = () => {
    console.log("Token: ", token);
    axios.get(`${process.env.REACT_APP_API_URL}/license/license`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log('This is license data', response.data);
        console.log("Licenses fetched successfully");

        /*// Fetch product details for each license
        const licensesWithProductNames = response.data.map(license => {
          return axios.get(`${process.env.REACT_APP_API_URL}/product/products/${license.product_code}`, {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          })
            .then(productResponse => {
              console.log(`Product details for license ${license.license_code}:`, productResponse.data);
              // Add the product_name to the license
              license.product_name = productResponse.data.product_name;
              license.product_file = productResponse.data.product_file;

              // Fetch subscription information for each license
              return fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionInformation`, {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                  authorization: token,
                },
                body: JSON.stringify({
                  subscriptionId: license.subscriptionId,
                }),
              })
                .then(res => res.json())
                .then(data => {
                  console.log(`Subscription information for license ${license.license_code}:`, data);
                  // Set expiration date for the license
                  license.expireDate = data.current_period_end;
                  return license;
                })
                .catch(error => {
                  console.error("There was an error fetching the subscription information!", error);
                  return license;
                });
            })
            .catch(error => {
              console.error("There was an error fetching the product details!", error);
              return license;
            });
        });*/

        // Fetch product details for each license
        const licensesWithProductNames = response.data.map(license => {

          if (license === null) {
            console.log("License is null, skipping...");
            return Promise.resolve(null);
          }

          return axios.get(`${process.env.REACT_APP_API_URL}/product/products/${license.product_code}`, {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          })
            .then(productResponse => {
              console.log(`Product details for license ${license.license_code}:`, productResponse.data);
              // Add the product_name to the license
              license.product_name = productResponse.data.product_name;
              license.product_file = productResponse.data.product_file;

              // Check if subscriptionId exists before making the request
              if (license.subscriptionId) {
                // Fetch subscription information for each license
                return fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionInformation`, {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: token,
                  },
                  body: JSON.stringify({
                    subscriptionId: license.subscriptionId,
                  }),
                })
                  .then(res => res.json())
                  .then(data => {
                    console.log(`Subscription information for license ${license.license_code}:`, data);
                    // Set expiration date for the license
                    license.expireDate = data.current_period_end;
                    return license;
                  })
                  .catch(error => {
                    console.error("There was an error fetching the subscription information!", error);
                    return license;
                  });
              } else {
                return license;
              }
            })
            .catch(error => {
              console.error("There was an error fetching the product details!", error);
              return license;
            });
        });

        // Wait for all product and subscription details to be fetched, then update the state
        Promise.all(licensesWithProductNames)
          .then(licenses => {
            //setLicenses(licenses);
            setLicenses(licenses.filter(license => license !== null));
          });
      })
      .catch(error => {
        console.error("There was an error fetching licenses!", error);
      });
  };
  useEffect(() => {
    // Fetch products from the database when the component mounts
    getUserLicenses();
  }, []);

    // Create a new state for the license
    const [LicenseInfo, setLicenseInfo] = useState({});

    // Fetch license info when component mounts
    useEffect(() => {
      console.log('this is for licensInfo');
      const { token, account } = store.getState().auth;
      axios.get(`${process.env.REACT_APP_API_URL}/license/license`, {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      })
        .then(response => {
          setLicenseInfo(response.data[0]);
          console.log('licensinfo: ', response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }, []);

const [CloudData, setCloudData] = useState([]);

// Fetch cloud data when component mounts
useEffect(() => {
  console.log('customer ID; ', customerInfo.customer_id);
  const { token, account } = store.getState().auth;
  axios.get(`${process.env.REACT_APP_API_URL}/cloud/Cloud/${customerInfo.customer_id}`, {
    headers: {
      "content-type": "application/json",
      authorization: token,
    },
  })
    .then(response => {
      setCloudData(response.data);
      console.log('clouddata', response.data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}, []);

  return (
    <>
      {/* Main Content */}
      <div className='px-5 py-6'>
      <div style={{ position: 'relative' }} className='mt-1 mb-5'>
          <button
            style={{ position: 'absolute', right: '20px' }}
            className='capitalize btnSecondaryShadow bg-glass1'
            onClick={takeToPartner}
          >
            Switch to Partner
          </button>
        </div>
        <div className='grid md:grid-cols-12 gap-x-3 mt-16 gap-y-6'>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Hello {customerInfo.first_name} </h1>
            <p className='capitalize mt-2'>Latest account ID
              {/*<button
                className='capitalize btnSecondary2 ml-12' onClick={handleManageClick}>
                Manage
              </button>
              {showPopup && (
                <div className='popup'>
                  <input type='text' value={accountId} onChange={e => setAccountId(e.target.value)} style={{ color: 'black' }}  />
                  <button className='capitalize btnSecondary2 ml-12' onClick={handleSaveClick}>Save</button>
                </div>
              )}*/}
            </p>
            <h4 className='text-2xl font-bold mt-10'>
              {LicenseInfo && LicenseInfo.allowed_account}
          </h4>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass2' style={{ position: 'relative' }}>
            {isCloudSubscriptionActive ? (
              <FiberManualRecordIcon className={classes.icon} style={{ color: 'green' }} />
            ) : (
              <StarIcon className={classes.icon} style={{ color: 'red' }} />
            )}
            <h1 className='text-3xl'>Dantis Cloud</h1>
            <p className='capitalize mt-2'>
             1-click Setup, Web Browser Access, Runs 24/7. {" "}
            </p>
            <p className='capitalize mt-2'>
              Try 7 days for $1 {" "}
            </p>
            <button
              className='capitalize btnSecondaryShadow mt-10 animate-pulse' onClick={customerInfo.cloudSubscriptionStatus ? ()=>{
                history.push("/customer/cloudsetup");
              }:takeToCloud}>
              {customerInfo.cloudSubscriptionStatus ? "Go to Cloud" : "Get cloud"}
            
            </button>
            </div>
          {/*<div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray' style={{ position: 'relative' }}>
            {true ? (
              <FiberManualRecordIcon className={classes.icon} style={{ color: 'green' }} />
            ) : (
              <StarIcon className={classes.icon} style={{ color: 'red' }} />
            )}
            <h1 className='text-3xl'>Dantis Cloud</h1>
            <p className='capitalize mt-2'>
              1-click Setup, Web Browser Access, Runs 24/7. {" "}
            </p>
            <p className='capitalize mt-2'>
              Try 7 days for $1 {" "}
            </p>
            <button
              className='capitalize btnSecondary mt-10' onClick={customerInfo.cloudSubscriptionStatus ? () => {
                history.push("/customer/cloudsetup");
              } : takeToCloud}>
              {customerInfo.cloudSubscriptionStatus ? "Go to Cloud" : "Get cloud"}
            </button>
          </div>*/}
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass2'>
            <h1 className='text-3xl fontsize-bold'>Dantis AI Trading🚀</h1>
            <p className='capitalize mt-2'>
              Experience high Intelligence. {" "}
            </p>
            <p className='capitalize mt-2'>
              7days FREE, then $99/mn {" "}
            </p>
            <button
              className='capitalize btnSecondaryShadow mt-10 animate-pulse' onClick={takeToBrokerAI}>
              Start for Free
            </button>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass2'>
            <h1 className='text-2xl'>Market Place🌐</h1>
            <p className='capitalize mt-2'>
              Shop for Tools. {" "}
            </p>
            <button
              className='capitalize btnSecondaryShadow mt-10 animate-pulse' onClick={takeToBroker}>
              Get Tools
            </button>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Cloud Subscription Renewal</h1>
            <p className='capitalize mt-2'>Currently renews on  </p>
            <h4 className='text-2xl font-bold mt-10'>
            {/* date */}
            {
                customerInfo.cloudSubscriptionStatus &&
                formatUnixTimestamp(expireDate) 
            }
             
            </h4>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Your Active Products </h1>
            <h4 className='text-2xl font-bold mt-10'>
              {licenses.map(license => license.product_name).join(', ')}
            </h4>
          </div>
          <div className='col-span-12'>
            <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
              <div className='grid grid-cols-1 md:grid-cols-2 '>
                {/*<div>
                  <p>Account ID:</p>
                  <p className='mt-2 md:mt-3'>{LicenseInfo && LicenseInfo.allowed_account}</p>
                </div>*/}
                <div className='md:col-span-1'>
                  <p className='mt-2 md:mt-3 text-2xl'>Cloud Urls:</p>
                  <p className='mt-2 md:mt-3'> If requested, Username is "dantis" & Password is your Trading acct. password</p>
                  <p className='mt-2 md:mt-3'>
                  {CloudData.length > 0 && 
                    CloudData.filter(data => data.customer_id === customerInfo.customer_id).map(filteredData =>
                      <p className='mt-2 md:mt-3'>
                        {filteredData.kube_endpoint && 
                          <a href={`https://${filteredData.kube_endpoint.toLowerCase()}`} target="_blank" rel="noopener noreferrer">
                            {filteredData.kube_endpoint.toLowerCase()}
                          </a>
                        }
                      </p>
                    )
                  }
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* Modal payment */}
      {/* Payment modal */}
      {showModal ? (
        <>
          {/* Backdrop */}
          <div className="fixed inset-0 z-50 bg-black opacity-25"></div>

          {/* Modal container */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto flex-wrap fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-lg">
              {/* Modal content */}
              <div className="border border-gray-200 bg-[#1e142e] rounded-lg shadow-lg outline-none focus:outline-none">
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b border-solid border-gray-200 rounded-t">
                  <h3 className="text-xl md:text-2xl font-semibold">Make Payment</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 hover:text-black hover:bg-gray-100 rounded-full focus:outline-none focus:bg-gray-100 focus:text-black"
                    onClick={() => setShowModal(false)}
                  >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                {/* Body */}
                <div className="p-4">
                  <div className="mb-4">
                    {/* Payment method selection */}
                    <div className="flex flex-col space-y-2">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                        onClick={() => {
                          handlePaymentMethodSelect('saved')
                          fetch(`${process.env.REACT_APP_API_URL}/getPaymentMethods`, {
                            method: "POST",
                            headers: {
                              "content-type": "application/json",
                              authorization: token,
                            },
                            body: JSON.stringify({

                              email: customerInfo.email,

                            }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
                          })
                            .then((res) => res.json())
                            .then((data) => {
                              if (data.paymentMethods.length === 0) {
                                window.alert("No payment methods,Please pay with new card")

                              }
                              setSavedPaymentMethods(data.paymentMethods)


                            }).catch((err) => {
                              // setProcess(false)
                            })
                        }}
                      >
                        Pay with saved card
                      </button>
                      <button
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                        onClick={() => handlePaymentMethodSelect('new')}
                      >
                        Pay with new card
                      </button>
                      {
                        selectedPaymentMethod === null ? <></> :
                          <>
                            <button
                              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                              onClick={() => handlePaymentMethodSelect(null)}
                            >
                              Back
                            </button>
                          </>
                      }

                    </div>

                    {/* Saved card selection */}
                    {/* Saved card selection */}
                    {selectedPaymentMethod === 'saved' && (
                      <div className="flex flex-col space-y-2 mt-4">
                        {savedPaymentMethods?.map((method) => (
                          <div
                            onClick={() => {
                              handlePayWithSavedCard(method.id)

                            }}
                            className="border justify-between flex space-x-3  items-center cursor-pointer p-1 rounded-md border-l-white" key={method.id}>
                            {renderCardIcon(method.brand)} {/* Render card icon */}

                            <span className="">Ending with {method.last4} Expires at {method.expMonth}/{method.expYear}</span>
                            {/* <button> <FaTrash></FaTrash></button> */}
                          </div>



                        ))}
                      </div>
                    )}
                  </div>
                  {/* Payment form for new card */}
                  {selectedPaymentMethod === 'new' && clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm clientSecretNew={clientSecret} email={customerInfo.email} userName={customerInfo.first_name} />
                    </Elements>
                  )}
                </div>

                {/* Footer */}
                <div className="flex items-center justify-end p-4 border-t border-solid border-gray-200 rounded-b">
                  <button
                    className="text-red-500 bg-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-2 rounded hover:bg-gray-100 focus:bg-gray-100"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}


const DataGrid = (Records) => {
  // Calculate total profit
  const totalProfit = Records.Records.reduce((total, record) => total + (Number(record.profit) - Number(record.loss)), 0);

  // Prepare data for the chart
  const chartData = {
    labels: Records.Records.map(record => formatDate(record.date)),
    datasets: [
      {
        type: 'line', // Specify the chart type here
        label: 'Profit',
        data: Records.Records.map(record => Number(record.profit) - Number(record.loss)),
        backgroundColor: 'rgba(255,255,255,0.8)', // Changed to white
        borderColor: 'rgba(255,255,255,1)', // Changed to white
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className='grid grid-cols-4 font-bold bg-dprimary px-6 py-4'>
        <p className='col-span-1'>Date</p>
        <p className='col-span-1'>Profit</p>
        <p className='col-span-1'>EA</p>
        <p className='col-span-1'>Accound ID</p>
      </div>
      {Records?.Records.map((record) => (
        <div className='grid grid-cols-4 text-sm px-6 py-4' key={record.date}>
          <p className='col-span-1 date'>{formatDate(record.date)}</p>
          <p className='col-span-1 profit'>{`$${Number(record.profit) - Number(record.loss)}`}</p>
          <p className='col-span-1 capitalize ea'>dantis one</p>
          <p className='col-span-1 capitalize account-id'>{`${record.accountid}`}</p>
        </div>
      ))}
      <div className='grid grid-cols-4 text-sm px-6 py-4 font-bold bg-dprimary'>
        <p className='col-span-1'>Total Profit</p>
        <p className='col-span-1'>{`$${totalProfit}`}</p>
        <p className='col-span-1'></p>
        <p className='col-span-1'></p>
      </div>
      <div className='my-4'>
        <Bar data={chartData} />
      </div>
    </>
  );
};


function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function removeTime(date) {
  date = Date.parse(date) / 1000
  console.log(date);
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
}

