import React from "react";
import { Link } from "react-router-dom";
import TextField from "./TextField";
import { Formik, Form } from "formik";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import authSlice from "../store/slices/auth";

export default function SignupForm({ showPopup }) {
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="flex items-center justify-center flex-col w-full">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        //validationSchema={FORM_VALIDATION}f
        onSubmit={async (values, actions) => {
          console.log(values);
          console.log(`${process.env.REACT_APP_API_URL}/partner/login`)
          const response = await axios
            .post(`${process.env.REACT_APP_API_URL}/partner/login`, values)
            .then(function (res) {
              console.log(res);
              if (res.data.status) {
                dispatch(
                  authSlice.actions.setAuthTokens({
                    token: res.data.access,
                    refreshToken: res.data.access,
                  })
                );
                dispatch(authSlice.actions.setAccount(res.data.user));
                console.log("Disptached Set")
                if (res.data.status) {
                  history.push("/partner/partnerdashboard");
                }
              } else {
                //alert(`Login Failed ${JSON.stringify(res)}`);
                alert(`Login Failed: Invalid Email or Password. Check PARTNER or CUSTOMER`);
              }
            })
            .catch(function (err) {
              console.error(err.response.data);
              alert("Invalid Email or Password. Check PARTNER or CUSTOMER");
            });
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
          console.log(response);
        }}
      >
        <Form className="grid grid-cols-1 gap-y-1 gap-x-1 w-full mt-1">
          <TextField name="email" type="email" label="Email" />
          <TextField name="password" type="password" label="Password" />
          <div className="w-full">
            <p className="text-primary underline mt-2">Forgot Password</p>
          </div>
          <input className="mt-5 btnPrimary" type="submit" value="Sign In" />
        </Form>
      </Formik>

      {/* Submit Button */}

      <div className="text-center mt-3 capitalize">
        New on our platform?{" "}
        <span>
          <Link to="/partner/signup" onClick={showPopup} className="text-primary underline">
            Signup
          </Link>{" "}
        </span>
      </div>
      <div className="text-center mt-3 capitalize">
        <span>
          <Link to="/" onClick={showPopup} className="text-primary underline">
            Go back
          </Link>{" "}
        </span>
        to home page
      </div>
    </div>
  );
}
