import { FaHeadset, FaTh } from "react-icons/fa";

import { BiLogOut } from "react-icons/bi";
import { HiOutlineChartBar, HiOutlineCash } from "react-icons/hi";

export const sideMenu = [
  {
    to: "/customer/customerdashboard",
    label: "Dashboard",
    Icon: FaTh,
    permission: ["all"],
  },
  {
    to: "/customer/licenses",
    label: "Licenses",
    Icon: BiLogOut,
    permission: ["all"],
  },

  {
    to: "/customer/products",
    label: "Products",
    Icon: FaHeadset,
    permission: ["all"],
    active: false,
  },
  {
    to: "/customer/payment",
    label: "Payments History",
    Icon: FaHeadset,
    permission: ["all"],
    active: false,
  },
];
