/*import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const getInitialState = () => {
  const storedState = JSON.parse(localStorage.getItem("authState")) || {};
  return {
    token: storedState.token || false,
    refreshToken: storedState.refreshToken || false,
    account: storedState.account || false,
    isAdmin: storedState.isAdmin || false,
  };
};
const initialState = getInitialState();

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(state, action) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      localStorage.setItem("authState", JSON.stringify(state));
    },
    setAccount(state, action) {
      state.account = action.payload;
      localStorage.setItem("authState", JSON.stringify(state));
    },
    // setLogout(state) {
    Logout(state) {
      state.account = 0;
      state.refreshToken = 0;
      state.token = 0;
      state.isAdmin = 0;
      localStorage.removeItem("authState");
    },
  },
});

export default authSlice;*/

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const getInitialState = () => {
  const storedState = JSON.parse(localStorage.getItem("authState")) || {};
  return {
    token: storedState.token || false,
    refreshToken: storedState.refreshToken || false,
    account: storedState.account || false,
    isAdmin: storedState.isAdmin || false,
    isGuest: storedState.isGuest || true, // Add this line
  };
};
const initialState = getInitialState();

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(state, action) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      localStorage.setItem("authState", JSON.stringify(state));
    },
    setAccount(state, action) {
      state.account = action.payload;
      localStorage.setItem("authState", JSON.stringify(state));
    },
    setGuest(state, action) { // Add this function
      state.isGuest = action.payload;
      localStorage.setItem("authState", JSON.stringify(state));
    },
    Logout(state) {
      state.account = 0;
      state.refreshToken = 0;
      state.token = 0;
      state.isAdmin = 0;
      state.isGuest = true; // Add this line
      localStorage.removeItem("authState");
    },
  },
});

export const { setAuthTokens, setAccount, setGuest, Logout } = authSlice.actions;

export default authSlice;

