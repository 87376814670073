import { Link, Navigate } from "react-router-dom";
import Textfield from "./TextField";
import Button from "./Button";
import BasicSelect from "./Dropdown";
import DatePicker from "./DatePicker";
import RadioButton from "./RadioButton";
import axios from "axios";
import store from "../store";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import authSlice from "../store/slices/auth";
import Checkbox from "@mui/material/Checkbox";
import countryList from "react-select-country-list";
import React, { useEffect, useState, useRef } from "react";
//import { useState, useMemo } from "react";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";


const INITIAL_FORM_STATE_TEST = {
  firstname: "",
  lastname: "",
  phonenumber: "",
  dob: "",
  email: "",
  password: "",
  repassword: "",
  residentCountry: "",
  country: "",
  platform: "",
  accountId: "",
};
/*const FORM_VALIDATION = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  phonenumber: Yup.string(),
  dob: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  repassword: Yup.string().required("Required"),
  residentCountry: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  platform: Yup.string().required("Required"),
  accountId: Yup.number(),
});*/

const FORM_VALIDATION = Yup.object().shape({
  firstname: Yup.string(),
  lastname: Yup.string(),
  phonenumber: Yup.string(),
  dob: Yup.string(),
  email: Yup.string(),
  password: Yup.string().required("Required"),
  repassword: Yup.string().required("Required"),
  residentCountry: Yup.string(),
  country: Yup.string(),
  platform: Yup.string(),
  accountId: Yup.number(),
});
export default function SignupForm({ showPopup }) {

  // Create a new state for the customer info and loading status
  const dispatch = useDispatch();
  const [customerInfo, setCustomerInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        // Update state with customer info
        setCustomerInfo(response.data);
        console.log("CustomerInfo", response.data);
        // Set loading status to false after data is fetched
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
        // Set loading status to false even if there's an error
        setIsLoading(false);
      });
  }, []);

  //const navigate = useNavigate();
  const history = useHistory();
  const countries = countryList()
    .getData()
    .map((x) => {
      return { id: x.value, value: x.label };
    });
  const platforms = [
    //{ id: "mt4", value: "MT4" },
    {
      id: "mt5",
      value: "MT5",
    },
  ];
  const resident = [
    { id: "US", value: "US" },
    {
      id: "nonUS",
      value: "Not US",
    },
  ];
  const label = { inputProps: { "aria-label": "Terms and Condition" } };

  // In your render method
  if (isLoading) {
    return <div>Loading...</div>; // Or some loading spinner
  }

  return (
    <div>
      <Formik
        initialValues={{
          firstname: customerInfo.first_name || '',
          lastname: customerInfo.last_name || '',
          phonenumber: customerInfo.phone_number || '',
          dob: '',
          email: customerInfo.email || '',
          password: '',
          repassword: '',
          residentCountry: 'US',
          country: '',
          platform: '',
          accountId: '',
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, actions) => {
        {/*initialValues={{
          ...INITIAL_FORM_STATE_TEST,
        }}
        validationSchema={FORM_VALIDATION}
      onSubmit={async (values, actions) => {*/}
          // Add console.log to display form values before submitting
          console.log("Form Values:", values);
          if (!values.toggle) {
            alert(`Kindly Accept Term and Conditions`);
            return;
          }
          if (values.repassword != values.password) {
            alert("Un Matched Password");
            return;
          }

          // Add console.log to display axios request data
          console.log("Axios Request Data:", {
            url: `${process.env.REACT_APP_API_URL}/signup`,
            data: values,
          });
          
          /*const response = await axios
            .post(`${process.env.REACT_APP_API_URL}/signup`, values)
            .then(function (res) {
              if (res.data.status) {
                alert("Signed Up, Please Login Now");
                history.push("/login");
              }
            })
            .catch(function (err) {
              alert(JSON.stringify(err.response.data))
            });*/
            const response = await axios
            .post(`${process.env.REACT_APP_API_URL}/signup`, values)
            .then(function (res) {
              if (res.data.status) {
                // User signed up successfully
                // Now log in the user with the same credentials
                return axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                  email: values.email,
                  password: values.password,
                });
              }
            })
            .then(function (res) {
              if (res.data.status == "ok") {
                // User logged in successfully
                // Dispatch the necessary actions and redirect to dashboard
                dispatch(
                  authSlice.actions.setAuthTokens({
                    token: res.data.accessToken,
                    refreshToken: res.data.refreshToken,
                  })
                );
                dispatch(authSlice.actions.setAccount(res.data.user));
                console.log("Dispatched Set")
                if (res.data.user.isAdmin) {
                  history.push("/admin");
                } else {
                  history.push("/dashboard");
                }
              } else {
                alert(`Login Failed ${JSON.stringify(res)}`);
              }
            })
            .catch(function (err) {
              alert(JSON.stringify(err.response.data))
            });
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        }}
        enableReinitialize // Add this prop
        >
      {({ isSubmitting }) => (
        <Form className='flex items-center justify-center flex-col w-full'>
          <div className='grid grid-cols-2 w-full gap-y-1 gap-x-3'>
            {/*<Textfield name='firstname' label='First Name' />
            <Textfield name='lastname' label='Last Name' />
            <Textfield name='phonenumber' label='Phone Number' />*/}
            <Textfield name='accountId' label='MT5 Trading Account Id' />
            <DatePicker name='dob' />
          </div>
          {/*<div className='text-center mt-3 capitalize'>
              Get an MT5 Trading Account in 2 steps!{" "}
              <span>
                <Link
                  to={{ pathname: "http://clients.aafxtrading.com/ref/?id=2132602808" }} target="_blank"
                  onClick={showPopup}
                  className='text-primary underline'>
                  Click Here
                </Link>{" "}
              </span>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(21).png"
                    alt="MT5 Download"
                    style={{ width: '150px', height: 'auto', marginRight: '10px' }} // Adjust width and height as needed
                  />
                  <img
                    src="/img/image(22).png"
                    alt="MT5 Download"
                    style={{ width: '150px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
            </div>
            <div className='text-center mt-3 capitalize'>
              Get an MT5 Trading Account for non-US! {" "}
              <span>
                <Link
                  to={{ pathname: "https://one.exness-track.com/a/xkddvx0f7c" }} target="_blank"
                  onClick={showPopup}
                  className='text-primary underline'>
                  Click Here
                </Link>{" "}
              </span>
              </div>
            <br />*/}
          {/*<div className='grid grid-cols-1 gap-y-1 gap-x-1 w-full mt-1'>
            <Textfield name='email' label='Email' />
          </div>*/}
          <div className='grid grid-cols-2 gap-y-1 gap-x-3 w-full mt-1'>
            <Textfield name='password' label='Password' type='password' />
            <Textfield name='repassword' label='Password' type='password' />
            {/*<RadioButton 
              name='residentCountry'
              label='Resident Country'
              values={resident}
            />*/}
            <BasicSelect name='country' label='Country' values={countries} />
            <RadioButton
                name='platform'
                label='Platform'
                values={platforms}
              />
          </div>
          {/*<div className='loginFormField bg-white flex items-center justify-between text-black gap-5 my-1'>
            <div>
              <RadioButton
                name='platform'
                label='Platform'
                values={platforms}
              />
            </div>
          </div>*/}
          <label className='mb-3 mt-3'>
            <Field type="checkbox" name="toggle" />
            <a href="url">Term and Conditions</a>
          </label>
          <Button className='mt-5 btnPrimary' type='submit' disabled={isSubmitting || isLoading}>
            Sign Up
          </Button>
        </Form>
      )}
      </Formik>
      
      <div className='text-center mt-3 capitalize'>
        Already have an account?{" "}
        <span>
          <Link
            to='/login'
            onClick={showPopup}
            className='text-primary underline'>
            Login
          </Link>{" "}
        </span>
      </div>
      <div className='text-center mt-3 capitalize'>
        <span>
          <Link to='/' onClick={showPopup} className='text-primary underline'>
            Go back
          </Link>{" "}
        </span>
        to home page
      </div>
    </div>
  );
}
