import React from 'react';
import { FullContainer } from "../components";

class MT5WebTerminal extends React.Component {
    render() {
        return (
                <iframe 
                    src="https://metatraderweb.app/trade?demo_all_servers=1&startup_mode=open_demo&lang=en&startup_version=5&save_password=on&colorScheme=green_on_black" 
                    allowFullScreen="allowFullScreen" 
                    style={{width: '100%', height: '100%', border: 'none', display: 'block'}}
                ></iframe>
        );
    }
}

export default MT5WebTerminal;
