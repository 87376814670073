import React from "react";

function ImageHoverChange({ image, hoverImage }) {
  return (
    <div className="relative flex justify-center">
      <img src={image} alt="sideImage" className="h-auto" />
      <img
        src={hoverImage}
        alt="Images"
        className="absolute inset-0 opacity-0 hover:opacity-100 transition-all mx-auto duration-500"
      />
    </div>
  );
}

export default ImageHoverChange;
