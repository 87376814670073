import * as React from "react";
import {
  ApplePay,
  GooglePay,
  CreditCard,
  GiftCard,
  Ach,
  PaymentForm,
} from "react-square-web-payments-sdk";
import { payments } from "@square/web-sdk";
import axios from "axios";
import store from "../store";

function MyPaymentForm() {
  const style = {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "100vh",
  };
  const appId = "sq0idp-7yFN8V2A7_xAXCMeOypfhw";
  const locationId = "LAV37DYHD45MY";

  // Add a state to track the transaction status
  const [transactionStatus, setTransactionStatus] = React.useState(null);

  // Add a ref to keep track of the CreditCard component
  const cardRef = React.useRef(null);

  React.useEffect(() => {
    // Add a delay before focusing on the CreditCard input
    setTimeout(() => {
      if (cardRef.current && cardRef.current.isReady()) {
        cardRef.current.focus();
      }
    }, 5000); // Adjust the delay as needed
  }, []);

  const handlePayment = async (paymentToken, buyer) => {
    const { token, account } = store.getState().auth;
    console.log(`Payment token ${JSON.stringify(paymentToken)}`);
    console.log(`Buy ${JSON.stringify(buyer)}`);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/processPayment`,
        { paymentToken: paymentToken, buy: buyer },
        {
          headers: {
            "content-type": "application/json",
            authorization: token,
          },
        }
      );
      console.log("Response from /processPayment endpoint:", response.data);
      
      // Update the transaction status based on the response
      setTransactionStatus('Success');
    } catch (error) {
      console.error("Error in /processPayment endpoint:", error);
      
      // Update the transaction status in case of an error
      setTransactionStatus('Failure');
    }
  };

  function buildPaymentRequest(payments) {
    return payments.PaymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: '1.00',
        label: 'Total',
      },
    });
  }

  return (
    <div style={style}>
      <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass2'>
      <h1 className='text-2xl' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', color: 'white' }}>Subscribe to Dantis AI</h1>
      <h1 className='capitalize mt-2 mb-6' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', color: 'grey', fontSize: '20px', }}>$99/month </h1>
      <PaymentForm
        applicationId={appId}
        cardTokenizeResponseReceived={(paymentToken, buyer) => handlePayment(paymentToken, buyer)}
        createVerificationDetails={() => ({
          amount: "99.00",
          billingContact: {
            addressLines: ["123 Main Street", "Apartment 1"],
            familyName: "Doe",
            givenName: "John",
            countryCode: "US",
            city: "London",
          },
          currencyCode: "USD",
          intent: "CHARGE",
        })}
        createPaymentRequest={() => ({
          countryCode: 'US',
          currencyCode: 'USD',
          total: {
            amount: '99.00',
            label: 'Total',
          },
        })}
        locationId={locationId}
      >
        <CreditCard ref={cardRef} />
        <ApplePay />
        <GooglePay />
        <Ach />
        <GiftCard />
      </PaymentForm>

      {/* Display the transaction status */}
      {transactionStatus && (
        <p>
          Transaction Status: <strong>{transactionStatus}</strong>
        </p>
      )}
      </div>
    </div>
  );
}

export default MyPaymentForm;
