import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import store from "../../store";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function DashMain() {
  const auth = useSelector((state) => state.auth);
  const [accounts, setAccounts] = useState([]);
  const [fetched, setFetching] = useState(false);
  const getAccountInfo = () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/fetchAccountDetails/`, 
          {
          headers: {
            authorization: token,
          },
        })
        .then(function (res) {
          console.log(res.data);
          setAccounts(res.data);
        })
        .catch(function (err) {
          console.error("There was an error!", err);
        });
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };
  useEffect(() => {
    getAccountInfo();
  }, []);

  // Calculate the total balance
  const totalBalance = accounts.reduce((total, account) => total + Number(account.accountBalance), 0);

    // Initialize history
    const history = useHistory();

    const takeToBroker1 = () => {
      //window.location.replace('http://localhost:3000/Setup');
          // Navigate to the "Setup" page
          history.push("/dashboard/adminsetup");
        };

  return (
    <>
      <div className='px-5 py-6'>
        <div className='grid md:grid-cols-12 gap-x-3 gap-y-6'>
      
          <div className=' justify-center col-span-12 md:col-span-12 p-6 rounded-lg bg-dsecondray'>
            <h1 className='text-2xl'>Dantis AI Admin Settings</h1>
            <p className='capitalize mt-2'>
              Be Careful while Adjusting.{" "}
            </p>
            <button
              className='capitalize btnSecondary mt-10' onClick={takeToBroker1}>
              Settings
            </button>
          </div>
        </div>
      </div>
  
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Account ID</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((row) => (
              <TableRow key={row.accountid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.accountid}
                </TableCell>
                <TableCell align="right">{row.accountBalance}</TableCell>
              </TableRow>
            ))}
            {/* Add the total balance row */}
            <TableRow>
              <TableCell component="th" scope="row">
                Total Balance
              </TableCell>
              <TableCell align="right">{totalBalance}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  
    </>
  );
  
            };
