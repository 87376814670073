import React, { useEffect } from "react";
import { useState } from "react";
import { DashboardHead } from "../components";
import PartnerSidebar from "../components/CustomerDashboardComponents/sidebar/index";

function CustomerDashboard({ children }) {
  const [show_navlabel, setShowNavbar] = useState(true);
  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
  };

  useEffect(() => {
    // Dynamically create a script element for the chatbot
    const script = document.createElement("script");
    script.id = "64f00959494bff9a99310a24";
    script.src = "https://app.droxy.ai/chat.min.js";
    script.defer = true;

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this effect runs only once

  return (
    <>
      <div
        className={`grid ${show_navlabel ? "grid-cols-body" : "grid-cols-bodyClosed"} h-screen`}
        style={{
          gridTemplateColumns: show_navlabel ? "260px 1fr" : "0 1fr", // Adjust sidebar width
        }}
      >
        <PartnerSidebar show_navlabel={show_navlabel} />

        <div className="relative">
          <DashboardHead toggleNavbar={toggleNavbar} show_navlabel={show_navlabel} />

          {children}
        </div>
      </div>
      {/* Add a media query for smaller screens */}
      <style>
        {`
          @media (max-width: 768px) {
            .grid-cols-body {
              grid-template-columns: 1fr; // Full width for small screens
            }
          }
        `}
      </style>
    </>
  );
}

export default CustomerDashboard;