import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import store from "../../store";
import { Helmet } from 'react-helmet';
import { setAuthTokens, setGuest } from "../../store/slices/auth"
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaTrash } from 'react-icons/fa';
import CheckoutForm from "../Stripe/CheckoutForm";
import { FaFacebookF, FaTwitter, FaInstagram, FaTiktok, FaShareAlt, FaTelegram } from 'react-icons/fa';
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);

function Marketplace() {
  const { token, isGuest } = store.getState().auth; // Retrieve isGuest from the store
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const stripe = useStripe();

  const history = useHistory();
  // stripe
  const [clientSecret, setClientSecret] = useState("");
  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#212d63',
      colorText: '#ffffff',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  // saved card handle
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [productData,setProductData]=useState({});

  const dispatch = useDispatch();

  // Define loginAsGuest as a const
    const loginAsGuest = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/guest-login`)
        .then(response => {
            // The response will contain the access and refresh tokens
            const { accessToken, refreshToken } = response.data;

            // Dispatch the setAuthTokens action to save the tokens in your Redux state and local storage
            dispatch(setAuthTokens({ token: accessToken, refreshToken: refreshToken }));

            // Dispatch the setGuest action to set isGuest to true in your Redux state and local storage
            dispatch(setGuest(true));
        })
        .catch(error => {
            console.error("There was an error!", error);
        });
    };

    // Use useEffect to call loginAsGuest when the component mounts
    useEffect(() => {
        loginAsGuest();
    }, []);

    const authHeader = `Bearer ${isGuest ? 'guest' : token}`;

    // Add 'token' as a dependency to the useEffect hook
    useEffect(() => {
      if (!token) {
        // If the token is not available, return early
        return;
      }
    
      const authHeader = `Bearer ${isGuest ? 'guest' : token}`;
      console.log('product token; ', token);
      console.log('Authorization Header: ', authHeader); // Add this line
    
      // Fetch products from the database when the component mounts
      axios.get(`${process.env.REACT_APP_API_URL}/product/productsmarket`, {
        headers: {
          "content-type": "application/json",
          authorization: authHeader,
        },
      })
      .then(async response => {
        console.log('Product',response.data)
    
        // Fetch admin info for each product
        const productsWithAdminInfo = await Promise.all(response.data.map(async product => {
          const adminResponse = await axios.get(`${process.env.REACT_APP_API_URL}/partner/adminInfo/${product.admin_id}`, {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          });
          console.log(`Fetched admin info for product ${product.admin_id}:`, adminResponse.data);
    
          // Add admin info to product
          return { ...product, admin: adminResponse.data };
        }));
    
        setProducts(productsWithAdminInfo);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
    }, [token]); // Add 'token' as a dependency here    

    /*useEffect(() => {
        if (!token) {
        // If the token is not available, return early
        return;
        }

        const authHeader = `Bearer ${isGuest ? 'guest' : token}`;
        console.log('product token; ', token);
        console.log('Authorization Header: ', authHeader); // Add this line

        // Fetch products from the database when the component mounts
        axios.get(`${process.env.REACT_APP_API_URL}/product/productsmarket`, {
        headers: {
            "content-type": "application/json",
            authorization: authHeader,
        },
        })
        .then(response => {
            setProducts(response.data);
        })
        .catch(error => {
            console.error("There was an error!", error);
        });
    }, [token]); // Add 'token' as a dependency here
    */


  const [fetched, setFetching] = useState(false);

  const [AccountInfo, SetAccountInfo] = useState({
    FullName: "",
    accountid: 0,
    user_id: 0,
    email: 0,
    status: 0,
    expiryDate: 0,
    customerid: 0,
    accountBalance: 0,
    pnl: 0,
    brokerLink: "www.exness.com",
  });
  console.log(AccountInfo.email);
  const getAccountInfo = () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/customer/customerInfo`,
          {
            headers: {
              "content-type": "application/json",
              authorization: authHeader, // Use 'Bearer guest' if the user is a guest, otherwise use the token
            },
          }
        )
        .then(function (res) {
          console.log(res);
          const result = res.data;
         setEmail(result.email);
         setFirstName(result.first_name);
         
        })
        .catch(function (err) {
          console.error("There was an error!", err);
        });
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };

  useEffect(()=>{
    getAccountInfo()
  },[])

  // Create a new state for the customer info
  const [customerInfo, setCustomerInfo] = useState({});

  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: authHeader, // Use 'Bearer guest' if the user is a guest, otherwise use the token
      },
    })
      .then(response => {
        // Update state with customer info
        setCustomerInfo(response.data);
        console.log("CustomerInfo", response.data);
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
      });
  }, []);

  const createSubscriber = (product) => {
    // Concatenate the first name and last name to make full_name
    const full_name = customerInfo.first_name + ' ' + customerInfo.last_name;

    // Prepare the Subscriber data
    const SubData = {
      full_name: full_name,
      product_code: product.product_code,
      email: customerInfo.email,
      product_name: product.product_name,
    };

    // Send a POST request to the "/Subscribers" endpoint
    axios.post(`${process.env.REACT_APP_API_URL}/subscriber/Subscribers`, SubData, {
      headers: {
        "content-type": "application/json",
        authorization: authHeader, // Use 'Bearer guest' if the user is a guest, otherwise use the token
      },
    })
      .then(response => {
        alert('Subscriber has been created successfully!');
      })
      .catch(error => {
        console.error("There was an error!", error);
        alert('Failed to create subscriber!');
      });
  };

  const createLicense = async (product) => {
    // Prepare the license data
    const licenseData = {
      admin_id: product.admin_id,
      product_code: product.product_code,
      customer_id: customerInfo.customer_id,
      allowed_account: 1234, // Set this value
    };
    setProductData({
      product,
      licenseData,
    })
    console.log(product);

    //await startPaymentProcess(product, licenseData)
  };

  const takeToCloud1 = (adminId) => {
    // Navigate to the "community" page
      history.push("/customer/commview", { adminId: adminId });
  };

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  // State for product details
  const [productDetails, setProductDetails] = useState('');

  const handleShowDetails = (details) => {
    // Set the product details
    setProductDetails(details);
    // Open the modal
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleLicenseClick = (product) => {
    localStorage.setItem('productCode', product.product_code);
    history.push({
      pathname: '/customer/login',
      state: { from: `/customer/market/${product.product_code}` }
    });
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  // Filter the products based on the search term and selected filters
  const filteredProducts = products.filter(product =>
    product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedType !== null ? product.type === selectedType : true) &&
    (selectedPlatform !== null ? product.platform === selectedPlatform : true)
  );

  return (
    <div className='px-5 py-6'>
      <Helmet>
        <title>Dantis Marketplace</title>
        <meta property="og:title" content="The Best EAs and Indicators in one place." />
        <meta property="og:description" content="Explore our range of amazing products." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="The Best EAs and Indicators in one place." />
        <meta name="twitter:description" content="Explore our range of amazing products." />
      </Helmet>
      <div className='mt-4 mb-4 rounded-lg bg-glass' style={{ display: 'flex', justifyContent: 'center' }}>
        <li><input type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder="Search by name" className=' rounded-lg' style={{ color: 'black' }}/></li>
        <select value={selectedType} onChange={e => setSelectedType(e.target.value === "Reset" ? null : e.target.value)} style={{ color: 'black' }} className=' rounded-lg'>
          <option value="Reset">Filter by type</option>
          <option value="Expert Advisor">Expert Advisor</option>
          <option value="Indicator">Indicator</option>
        </select>
        <select value={selectedPlatform} onChange={e => setSelectedPlatform(e.target.value === "Reset" ? null : e.target.value)}  style={{ color: 'black' }} className=' rounded-lg'>
          <option value="Reset">Filter by platform</option>
          <option value="MT4">MT4</option>
          <option value="MT5">MT5</option>
        </select>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-3 gap-y-6 mt-10'>
        {filteredProducts.map((product, index) => (
          <div key={product.product_code} className='col-span-1 p-6 rounded-lg bg-glass' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
            {product.image_url && 
              <img src={`${process.env.REACT_APP_API_URL}/${product.image_url}`} alt={product.product_name} style={{ width: '300px', height: '150px' }} />
            }
            <div className='mt-5' style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
              <div>
                <h1 className='text-2xl'>{product.product_name}</h1>
                <p className='capitalize'>{product.type}</p>
                <p className='capitalize'>{product.platform}</p>
                
              </div>
              <h4 className='text-2xl font-bold'>
                ${product.price}<span className='text-xl font-normal'>/{product.interval}</span>
              </h4>
            </div>
            <p className='capitalize mt-4 mb-2'>By {product.admin.first_name} {product.admin.last_name}</p>
            <div className='mt-4 mb-4' style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className='capitalize btnSecondary5' onClick={() => handleLicenseClick(product)}>
                License
              </button>
            </div>
            <Link to={`/product/${product.product_code}`} style={{ textDecoration: 'underline' }}>View Product</Link>
            <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <FaFacebookF onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_DOMAIN_URL}/products/${product.product_code}`, '_blank')} />
              <FaTwitter onClick={() => window.open(`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_DOMAIN_URL}/products/${product.product_code}`, '_blank')} />
              <FaInstagram onClick={() => window.open(`https://www.instagram.com/`, '_blank')} />
              <FaTiktok onClick={() => window.open(`https://www.tiktok.com/`, '_blank')} />
              <FaTelegram onClick={() => window.open(`https://telegram.me/share/url?url=${process.env.REACT_APP_DOMAIN_URL}/products/${product.product_code}`, '_blank')} />
              <FaShareAlt onClick={() => {
                navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN_URL}/products/${product.product_code}`);
                window.alert('URL has been copied to clipboard!');
              }} />
            </div>
            {isModalOpen && (
              <div className='modal'>
                <div className='modal-content'>
                  <span className='close' style={{ fontSize: '30px', position: 'absolute', right: '10px' }} onClick={handleCloseModal}>×</span>
                  <p>{productDetails}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
  
}

export default Marketplace;