import { FaHeadset, FaTh } from "react-icons/fa";

import { BiLogOut } from "react-icons/bi";
import { HiOutlineChartBar, HiOutlineCash } from "react-icons/hi";

export const sideMenu = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: FaTh,
    permission: ["all"],
  },
  /*
  {
    to: "/dashboard/dantis-ai",
    label: "Dantis AI",
    Icon: HiOutlineChartBar,
    permission: ["all"],
    children: [
      {
        to: "/dashboard/dantis-ai",
        label: "Dantis one",
        permission: ["all"],
      },

      {
        to: "/dantis-ai",
        label: "Dantis Zeus",
        permission: ["all"],
      },
      {
        to: "/dashboard/dantis-ai",
        label: "Dantis Hedge",
        permission: ["all"],
      },
    ],
  },*/
  
  /*{
    to: "/dashboard/profit",
    label: "Account Settings",
    Icon: BiLogOut,
    permission: ["all"],
  },*/
  
  {
    to: "/dashboard/subscription",
    label: "Subscription",
    Icon: FaHeadset,
    permission: ["all"],
    active: false,
  },

];
