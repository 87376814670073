import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import FormData from 'form-data';
import axios from "axios";
import store from "../../store";

function CommunityPage({ onMessageSubmit }) {
  const { token } = store.getState().auth;
  const [fetched, setFetching] = useState(false);
  const [connectedAccountStatus,setConnectedAccountStatus]= useState(false);
  const [products, setProducts] = useState('');
  const [email,setEmail]=useState('');
  const [first_name,setfirst_name]=useState('');
  const [admin_id, setAdmin_id]=useState('');

  console.log(email)
  console.log(products);
  //console.log(first_name);

  const getAccountInfo = async () => {
    const { token, account } = store.getState().auth;
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/partner/adminInfo`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    );
    const { email, first_name, last_name, phone_number, totalLicenses, pending, available } = res?.data;
   setEmail(email);
   setfirst_name(first_name)

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/getAccountId`,
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    const { connectedAccountId } = response.data;
      const connectedAID=connectedAccountId;
    if(connectedAccountId === false){
      setConnectedAccountStatus(false);
    } else if (typeof (connectedAccountId) === "string"){
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/accountStatus`,
        { connectedAccountId: connectedAID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const { status } = response.data;
      console.log('Connected id status', status);
      if(status){
        setConnectedAccountStatus(true);
      }
    } else{
      setConnectedAccountStatus(false);
    }

  }

  useEffect(() => { 
    getAccountInfo();
  }, []);


  const [product_codes, setProductCodes] = useState([]); // Add this line

  // Fetch products from the database
  const fetchProducts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/product/products`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
    .then(response => {
      const product_codes = response.data.map(product => product.product_code);
      const admin_id = response.data[0].admin_id; // Get the admin_id of the first product
      setAdmin_id(admin_id);
      console.log('admin is: ',admin_id);
      setProductCodes(product_codes); // Update the state here
    })
    .catch(error => console.error(error));
  };
  
  useEffect(() => {
    fetchProducts();
  }, []);
  
  const [subscribers, setSubscribers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    Promise.all(product_codes.map(product_code =>
      axios.get(`${process.env.REACT_APP_API_URL}/subscriber/comsubscribers?product_code=${product_code}`)
    ))
    .then(responses => {
      const allSubscribers = responses.flatMap(response => response.data);
      setSubscribers(allSubscribers);
    })
    .catch(error => {
      console.error(error);
      setError('An error occurred while fetching subscribers.');
    });
  }, [product_codes]);

  /*useEffect(() => {
    Promise.all(product_codes.map(product_code =>
      axios.get(`${process.env.REACT_APP_API_URL}/subscriber/comsubscribers?product_code=${product_code}`)
    ))
    .then(responses => {
      // responses is an array of responses for each product_code
      responses.forEach(response => {
        console.log(response.data);
      });
    })
    .catch(error => console.error(error));
  }, [product_codes]); // Add product_codes as a dependency
  */

const [messages, setMessages] = useState([{ text: 'Welcome to our community!', timestamp: new Date() }, { text: 'Post your Name first and then a description of your tool.', timestamp: new Date() }]);
const [message, setMessage] = useState('');

//const [messages, setMessages] = useState([]);
//const [message, setMessage] = useState('');

// Fetch messages from the database
const fetchMessages = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/subscriber/Messages?admin_id=${admin_id}`, {
    headers: {
      "content-type": "application/json",
      authorization: token,
    },
  })
  .then(response => {
    // Sort messages by timestamp
    const sortedMessages = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    setMessages(sortedMessages);
  })
  .catch(error => console.error(error));
};

useEffect(() => {
  if (admin_id) {
    fetchMessages();
  }
}, [admin_id]);


const handleSubmit = (event) => {
  event.preventDefault();
  // Create a new message in the database
  const now = new Date();
  const timestamp = `${now.toLocaleDateString()} ${now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  axios.post(`${process.env.REACT_APP_API_URL}/subscriber/Messages`, { text: message, timestamp, admin_id }, {
    headers: {
      "content-type": "application/json",
      authorization: token,
    },
  })
  .then(response => {
    // Add the new message to the existing messages
    setMessages([...messages, response.data]);
    // Fetch the messages again after submitting a new message
    fetchMessages();
    setMessage('');
  })
  .catch(error => console.error(error));
};

return (
  <>
   {
      connectedAccountStatus ?
      <>
          <FullContainer className="mt-0" style={{ width: '100%' }}>
            <Container style={{ width: '100%' }}>
              <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                Your Community 
              </h1>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <div style={{ overflowY: 'auto', marginBottom: 'auto' }}>
                  {messages.slice().reverse().map((message, index) => (
                    <div key={index} className="bg-glass-only" style={{ borderRadius: '5px', padding: '10px', margin: '30px' }}>
                      <p style={{ textAlign: 'left' }}>{message.text}</p>
                      <div style={{ textAlign: 'right' }}>
                        {message && message.timestamp && <p style={{ fontSize: '0.8em', color: 'gray' }}>{new Date(message.timestamp).toLocaleString()}</p>}
                        {message.pinned && <span style={{ fontSize: '0.8em', color: 'red' }}>Pinned</span>}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="bg-glass-only" style={{ position: 'fixed', right: '30px', bottom: '0', padding: '10px', marginBottom: '100px', width: 'auto' }}>
                  <form onSubmit={handleSubmit} style={{ marginTop: 'auto' }}>
                    <input
                      type="text"
                      name="message"
                      placeholder="Write a post..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      style={{ color: 'black', marginRight: '10px', borderRadius: '5px' }}
                    />
                    <button type="submit" className="btnSecondary4"> Post</button>
                  </form>
                </div>
              </div>
            </Container>
          </FullContainer>
          </>:
      <>
          <FullContainer className="mt-0" style={{ width: '100%' }}>
            <Container style={{ width: '100%' }}>
              <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                Please go to payment page and confirm the stripe onboarding.
              </h1>
             
            </Container>
          </FullContainer>
      </>
   }
  </>
);
  }

export default CommunityPage;
