import Container from "./Container";
import FullContainer from "./FullContainer";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <FullContainer className="bg-about bg-cover bg-center mt-20 py-16">
      <Container>
        <div className="grid lg:grid-cols-2">
          <div>
            <h1 className="text-5xl font-bold mb-5">Why Dantis AI?</h1>
            <ul className="text-lg">
              <li>• <strong className="liner_gradient">Effortless Cloud Hosting:</strong>  Host your MT4/MT5 platforms on the cloud with a single click.</li>
              <li>• <strong className="liner_gradient">Seamless Tool Marketplace:</strong> Discover and subscribe to trading tools easily.</li>
              <li>• <strong className="liner_gradient">Global Accessibility:</strong> Trade from anywhere via a simple URL.</li>
            </ul>
            <button className="btnSecondary mt-5" onClick={() => window.gtag('event', 'click', { 'event_category': 'Button', 'event_label': 'Try for Free', })}><Link to="/customer/signup">Try for Free</Link></button>
          </div>
          <div></div>
        </div>
      </Container>
    </FullContainer>
  );
}

