import React, { useEffect } from "react";
import { FullContainer, CustomerSignup } from "../components";
import ReactGA from 'react-ga';

export default function CSignup() {
  useEffect(() => {
    ReactGA.initialize('G-8T9WDH6YR2');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <FullContainer >
      <div className="grid lg:grid-cols-2 w-full min-h-screen">
        <div className="bg-signup bg-cover bg-center"></div>
        <div className="flex flex-col items-center p-10">
          <h1 className="heading1 mt-5 capitalize text-center">Sign Up and Start</h1>
          <p className="mt-5 text-center text-gray-400 mb-2">Please select your <strong className="heading3 mt-5 capitalize">ROLE</strong> and create an account</p>
          <p className=" text-center text-gray-400 mb-10">Select <strong className="heading3 mt-5 capitalize">customer</strong> role for <strong className="heading3 mt-5 capitalize">Dantis Cloud</strong></p>
          <CustomerSignup />
        </div>
      </div>
    </FullContainer>
  );
}
