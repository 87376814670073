import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FullContainer from "./FullContainer";

export default function Navbar() {
  const location = useLocation();
  const [sidebar, openSidebar] = useState(false);
  const handleSidebar = () => {
    openSidebar(!sidebar);
  };

  return (
    <FullContainer className="z-10 py-12">
      <div className="flex items-center justify-between px-9 lg:px-0 lg:grid grid-cols-nav w-full">
        <div className="lg:px-20">
          <Link to="/" className="flex items-center">
            <img src="/img/favicon.png" className="w-8" alt="" />
          </Link>
        </div>
        <div className="px-8 py-3 hidden lg:flex items-center justify-between shadow-black shadow-lg rounded-full w-full bg-primary/10 backdrop-blur-sm space-x-8">
          <li
            className={` ${
              location.pathname === "/" ? "btnPrimarySmall" : ""
            }`}
          >
            <Link to="/">Home</Link>
          </li>
          {/*<li
            className={` ${
              location.pathname === "/about" ? "btnPrimarySmall" : ""
            } whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
          >
            <Link to="/about">About Us</Link>
          </li>
          <li
            className={` ${
              location.pathname === "/contact" ? "btnPrimarySmall" : ""
            } whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
          >
            <Link to="/contact">Contact Us</Link>
          </li>*/}
          <li
            className={` ${
              location.pathname === "/trade" ? "btnPrimarySmall" : ""
            }`}
          >
            <Link to="trade">Trade</Link>
          </li>
          <li
            className={` ${
              location.pathname === "/marketplace" ? " animate-highlight btnPrimarySmall" : ""
            }`}
          >
            <Link to="/marketplace">Marketplace</Link>
          </li>
          <li
            className={` ${
              location.pathname === "/faq" ? " animate-highlight btnPrimarySmall" : ""
            }`}
          >
            <Link to="/faq">Cloud</Link>
          </li>
          {/*<li
            className={` ${
              location.pathname === "/privacy" ? "btnPrimarySmall" : ""
            }`}
          >
          <Link to="/privacy">Privacy</Link>
          </li>*/}
          <li
            className={` ${
              location.pathname === "/documentations" ? "btnPrimarySmall" : ""
            }`}
          >
            <Link to="/documentations">Docs</Link>
          </li>
          <li
            className={` ${
              location.pathname === "/customer/login" ? "btnPrimarySmall" : ""
            } whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
          >
            <Link to="/customer/login">Sign In</Link>
          </li>
        </div>
        <div>
          <Bars3Icon
            onClick={handleSidebar}
            className="text-primary h-8 lg:hidden"
          />
        </div>
      </div>

      {/* NavBar Phone */}
      {sidebar && (
        <div className="lg:hidden fixed z-50 top-0 left-0 px-8 py-5 h-screen bg-gray-900 w-full flex flex-col items-center">
          <div className="flex justify-between lg:grid grid-cols-3 w-full items-center my-9">
            {/* Logo */}
            <Link to="/" className="flex items-center">
              <img src="/img/favicon.png" className="w-8" alt="" />
            </Link>
            <XMarkIcon
              onClick={handleSidebar}
              className="text-primary h-8 lg:hidden"
            />
          </div>
          <Link title="Home" className="navLink" to="/">
            Home
          </Link>
          <Link title="Trade" className="navLink" to="/trade">
            Trade🚀
          </Link>
          <Link title="Marketplace" className="navLink" to="/marketplace">
            Marketplace🚀
          </Link>
          <Link title="Dantis Cloud" className="navLink" to="/faq">
            Cloud🚀
          </Link>
          <Link title="About Us" className="navLink" to="/about">
            About Us
          </Link>
          <Link title="Contact Us" className="navLink" to="/contact">
            Contact Us
          </Link>
          
          <Link title="Privacy" className="navLink" to="/privacy">
            Privacy
          </Link>
          <Link title="Documentations" className="navLink" to="/documentations">
            Docs
          </Link>
          <Link title="Login" className="navLink" to="/customer/login">
            Login
          </Link>
          <Link title="Create Account" className="navLink" to="/customer/signup">
            Create Account
          </Link>
        </div>
      )}
    </FullContainer>
  );
}
