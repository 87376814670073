import { useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import store from "../../store";
import { Chart,  LineController, TimeScale, LinearScale, PointElement, LineElement } from 'chart.js';
import axios from "axios";
import moment from 'moment';
import { registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // Adapter for using Moment.js as the date adapter
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Bar } from 'react-chartjs-2';

// Register necessary plugins
Chart.register(...registerables);

function getCurrentDate() {
  const currentDate = new Date();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const year = String(currentDate.getFullYear()).substr(-2);

  return `${month}/${day}/${year}`;
}

function getCurrentDateDifference(tradeDetails) {
  if (tradeDetails.length > 0) {
    const todayDifference = tradeDetails[0].profit - tradeDetails[0].loss;
    return todayDifference;
  }
  return 0;
}
const { token, account } = store.getState().auth;
// Function to send data to the /deletecloud endpoint
const deleteCloud = async (accountid) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/taLogin/deletecloud`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": token,
      },
      body: JSON.stringify({ login: accountid }),
    });

    if (response.ok) {
      console.log(`Service and deployment my-app-${accountid}-3000 deleted.`);
    } else {
      console.error('Failed to delete service and deployment.');
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};

export default function DashMain() {
  const [tradeDetails, setTradeDetails] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const auth = useSelector((state) => state.auth);
  const [accountInfo, setAccountInfo] = useState({});
  const [fetched, setFetching] = useState(false);
  const [dailyRecord, setRecord] = useState([])
  useEffect(() => {
    const { token, account } = store.getState().auth;
    // Fetch trade details from the backend API
    axios.get(`${process.env.REACT_APP_API_URL}/getAccountHistory`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    ) // Adjust the URL to match your API endpoint
      .then((response) => { //console.log(response.data.records); 
        setRecord(response.data.records)
      })
      .catch(error => console.error("Error fetching trade details:", error));
    getAccountInfo();
  }, []);

  // Initialize history
  const history = useHistory();

  const takeToBroker = () => {
    //window.location.replace('http://localhost:3000/Setup');
        // Navigate to the "Setup" page
        history.push("/dashboard/Setup");
  };
  const [AccountInfo, SetAccountInfo] = useState({
    FullName:"",
    accountid: 0,
    user_id: 0,
    email: 0,
    status: 0,
    expiryDate: 0,
    accountBalance: 0,
    pnl: 0,
    brokerLink: "www.exness.com",
  });
  const getAccountInfo = () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/UserProfileInfo/`,
          {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          }
        )
        .then(function (res) {
          console.log(res);
          const result = res.data;
          SetAccountInfo({ ...result });
          // Get the current date
          const currentDate = new Date();
          console.log('currentdate;', currentDate);
          // Parse the expiry date from the account info
          const expiryDate = new Date(result.expiryDate);
          console.log('expirydate;', expiryDate);
          // Add one day to the expiry date
          expiryDate.setDate(expiryDate.getDate() + 1);
          console.log('expirydate+1;', expiryDate);

          // If the current date is greater than the expiry date, call deleteCloud
          if (currentDate > expiryDate) {
            deleteCloud(result.accountid);
          }
        })
        .catch(function (err) {
          console.error("There was an error!", err);
        });
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };

  // Initialize state with values from localStorage or default values
const [status, setStatus] = useState(localStorage.getItem('status') || '');

// Function to fetch status from server
const fetchStatus = async (accountid) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/status/${AccountInfo.accountid}`);

    if (response.status !== 200) {
      console.error("Failed to fetch status");
    } else {
      const statusData = await response.json();
      const status = statusData.records[0].status; // Assuming 'status' is a field in the record
      console.log(statusData)
      setStatus(status);
      localStorage.setItem('status', status);
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};

// Use useEffect to fetch status when accountid has been fetched and every 15 minutes
useEffect(() => {
  if (AccountInfo.accountid !== 0) {
    const accountid = AccountInfo.accountid; // Replace with actual accountid
    fetchStatus(accountid);

    // Fetch status every 15 minutes
    const intervalId = setInterval(() => {
      fetchStatus(accountid);
    }, 15 * 60 * 1000); // 15 minutes in milliseconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }
}, [AccountInfo.accountid]); // Add AccountInfo.accountid as a dependency

  // Calculate the difference between the current date and the expiry date
  const currentDate = new Date();
  const expiryDate = new Date(AccountInfo.expiryDate);
  const diffInDays = Math.ceil((expiryDate - currentDate) / (1000 * 60 * 60 * 24));


  return (
    <>
      {/* Main Content */}
      <div className='px-5 py-6'>
      <div className='col-span-12'>
        <div className='px-3 rounded-lg bg-cover bg-center bg-glass1 mb-3'>
            {/* Display the message if the difference is less than or equal to 7 days */}
            {diffInDays <= 7 && (
              <div className=''>
                <p>Your free trial is ending in {diffInDays} days. Please  SUBSCRIBE  to avoid breaks in service.</p>
              </div>
            )}
          </div>
        </div>
        <div className='grid md:grid-cols-12 gap-x-3 gap-y-6'>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass2'>
            <h1 className='text-2xl'>Hello {AccountInfo.FullName} </h1>
            <p className='capitalize mt-2'>Your account balance is </p>
            <h4 className='text-2xl font-bold mt-10'>
              ${AccountInfo.accountBalance}
            </h4>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass2'>
            <h1 className='text-2xl'>Floating Equity</h1>
            <p className='capitalize mt-2'>PnL </p>
            <h4 className='text-2xl font-bold mt-10'>${AccountInfo.pnl}</h4>
          </div>
          <div className='col-span-12 md:col-span-4 p-6 rounded-lg bg-glass'>
            <h1 className='text-2xl'>Dantis AI Settings</h1>
            <p className='capitalize mt-2'>
              Adjust these to your liking.{" "}
            </p>
            <button
              className='capitalize btnSecondaryShadow mt-10 animate-pulse' onClick={takeToBroker}>
              Settings
            </button>
          </div>

          {/*<div className='col-span-12 md:col-span-6 rounded-lg bg-dsecondray overflow-hidden'>*/}
          <div className='col-span-12 md:col-span-12 rounded-lg bg-dsecondray overflow-hidden'>
              {dailyRecord.length > 0 ? (<DataGrid Records={dailyRecord} />) : (<div> </div>)}
          </div>

          {/*<div className='col-span-12 md:col-span-6 rounded-lg bg-dsecondray overflow-hidden'>
            {dailyRecord.length > 0 ? <ChartBlock Records={dailyRecord} /> : (<div>No data available for the chart. </div>)}
  </div>*/}
          

          <div className='col-span-12'>
            <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-glass2'>
              <div className='grid grid-cols-1 md:grid-cols-4 capitalize'>
              <div className='md:col-span-1'>
                  <p>Status:</p>
                  <p className='mt-2 md:mt-3'>{status}</p>
                </div>
                <div className='md:col-span-1'>
                  <p>Your Active EA:</p>
                  <p className='mt-2 md:mt-3'>dantis one</p>
                </div>
                <div className='md:col-span-1'>
                  <p>Subscription Expiry date:</p>
                  <p className='mt-2 md:mt-3'>{new Date(AccountInfo.expiryDate).toLocaleDateString()}</p>
                </div>
                <div className='md:col-span-1'>
                  <p>Account ID:</p>
                  <p className='mt-2 md:mt-3'>{AccountInfo.accountid}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const DataGrid = (Records) => { 
  // Calculate total profit
  const totalProfit = Records.Records.reduce((total, record) => total + (Number(record.profit) - Number(record.loss)), 0);

  // Prepare data for the chart
  let cumulativeProfit = 0;
  const chartData = {
    labels: Records.Records.map(record => formatDate(record.date)),
    datasets: [
      {
        type: 'line', // Specify the chart type here
        label: 'Profit',
        data: Records.Records.map(record => {
          cumulativeProfit += Number(record.profit) - Number(record.loss);
          return cumulativeProfit;
        }),
        backgroundColor: 'rgba(255,255,255,0.8)', // Changed to white
        borderColor: 'rgba(255,255,255,1)', // Changed to white
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className='grid grid-cols-4 font-bold bg-dprimary px-6 py-4'>
        <p className='col-span-1'>Date</p>
        <p className='col-span-1'>Profit</p>
        <p className='col-span-1'>EA</p>
        <p className='col-span-1'>Accound ID</p>
      </div>
      {Records?.Records.map((record) => (
        <div className='grid grid-cols-4 text-sm px-6 py-4' key={record.date}>
          <p className='col-span-1 date'>{formatDate(record.date)}</p>
          <p className='col-span-1 profit'>{`$${Number(record.profit) - Number(record.loss)}`}</p>
          <p className='col-span-1 capitalize ea'>dantis one</p>
          <p className='col-span-1 capitalize account-id'>{`${record.accountid}`}</p>
        </div>
      ))}
      <div className='grid grid-cols-4 text-sm px-6 py-4 font-bold bg-dprimary'>
        <p className='col-span-1'>Total Profit</p>
        <p className='col-span-1'>{`$${totalProfit}`}</p>
        <p className='col-span-1'></p>
        <p className='col-span-1'></p>
      </div>
      <div className='my-4'>
        <Bar data={chartData} />
      </div>
    </>
  );
};


/*const DataGrid = (Records) => { 
  // Calculate total profit
  const totalProfit = Records.Records.reduce((total, record) => total + (Number(record.profit) - Number(record.loss)), 0);
  return (
    <>
      <div className='grid grid-cols-4 font-bold bg-dprimary px-6 py-4'>
        <p className='col-span-1'>Date</p>
        <p className='col-span-1'>Profit</p>
        <p className='col-span-1'>EA</p>
        <p className='col-span-1'>Accound ID</p>
      </div>
      {Records?.Records.map((record) => (
        <div className='grid grid-cols-4 text-sm px-6 py-4' key={record.date}>
          <p className='col-span-1 date'>{formatDate(record.date)}</p>
          <p className='col-span-1 profit'>{`$${Number(record.profit) - Number(record.loss)}`}</p>
          <p className='col-span-1 capitalize ea'>dantis one</p>
          <p className='col-span-1 capitalize account-id'>{`${record.accountid}`}</p>
        </div>
      ))}
      <div className='grid grid-cols-4 text-sm px-6 py-4 font-bold bg-dprimary'>
        <p className='col-span-1'>Total Profit</p>
        <p className='col-span-1'>{`$${totalProfit}`}</p>
        <p className='col-span-1'></p>
        <p className='col-span-1'></p>
      </div>
    </>
  );
};*/
/*
const ChartBlock = (Records) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [lastRecordDate, setLastRecordDate] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (!Records || Records.length === 0 || !Records[0]?.date) {
      return;
    }

    const latestRecordDate = new Date(Records[0]?.date);

    if (lastRecordDate === null || latestRecordDate > lastRecordDate) {
      const ctx = chartRef.current.getContext('2d');
      const data = {
        labels: Records.map((record) => formatDate(record.date)),
        datasets: [
          {
            label: 'Profit',
            data: Records.map((record) => record.profit - record.loss),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            fill: false,
          },
        ],
      };

      const options = {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'll',
            },
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Profit',
            },
          },
        },
      };
      
      if (chartInstance) {
        chartInstance.data.labels = data.labels;
        chartInstance.data.datasets = data.datasets;
        chartInstance.options.scales = options.scales;
        chartInstance.update();
      } else {
        const newChartInstance = new Chart(ctx, {
          type: 'line',
          data,
          options,
        });
        setChartInstance(newChartInstance);
      }
      
      setLastRecordDate(latestRecordDate);
    }
  }, [Records, chartInstance, lastRecordDate]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};*/
/*
const ChartBlock = (Records) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (!Records || Records.length === 0) return;

    const ctx = chartRef.current.getContext('2d');

    // Destroy the previous chart instance if it exists
    if (chartInstance) {
      chartInstance.destroy();
    }

    const data = {
      labels: Records?.Records.map((record) => formatDate(record.date)),
      datasets: [
        {
          label: 'Profit',
          data: Records?.Records.map((record) => (Number(record.profit) - Number(record.loss))),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          fill: false,
        },
      ],
    }; 

    const options = {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: "ll",
          },
          title: {
            display: true,
            text: "Date",
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Profit",
          },
        },
      },
    };

    const newChartInstance = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });

    setChartInstance(newChartInstance);

    return () => {
      // Cleanup on component unmount
      if (newChartInstance) {
        newChartInstance.destroy();
      }
    };
  }, [Records, chartInstance]);

  return <canvas ref={chartRef} />;
};*/

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function removeTime(date) {
  date = Date.parse(date) / 1000
  console.log(date);
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
}


