import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function PartnerDropdown(props) {
  const {
    label,
    options,
    Icon,
    show_navlabel,
    to,
    activeMenuItem,
    setActiveMenuItem,
  } = props;
  console.log("props", activeMenuItem);

  const user = "";

  const isAllow = (item) => {
    if (item?.permission?.length > 0) {
      if (
        item?.permission?.filter(
          (item) => item === "all" || item === user?.role
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Menu as="div" className="drop_down_menu">
      <div>
        <Menu.Button className="hover:bg-gray-100 p-[13px] rounded-lg flex items-center justify-between focus:outline-none w-full hover:no-underline hover:text-black">
          <div className="flex items-center w-full">
            <Icon />
            {show_navlabel && <span className="ml-3">{label}</span>}
          </div>
          <FaAngleDown />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            show_navlabel
              ? "dropdown_options bg-beta rounded-xl p-2 text-white"
              : "dropdown_options_small border-2 border-secondary text-white bg-beta rounded-xl"
          }
        >
          {options.map((item, key) => {
            let Icon = item?.Icon || null;
            console.log("item", Icon);
            const isActive = activeMenuItem === item.label;

            return isAllow(item) ? (
              <div key={key}>
                <Link
                  className={`pageLink ${isActive ? "bg-dprimary" : ""}`}
                  title={item.label}
                  to={`${item.to}`}
                  onClick={() => setActiveMenuItem(item.label)}
                >
                  {Icon && <Icon />}
                  {show_navlabel && <span className="ml-3">{item.label}</span>}
                </Link>
              </div>
            ) : null;
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
