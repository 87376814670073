import { useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import store from "../../store";
//import store from "../store";
import { Chart, LineController, TimeScale, LinearScale, PointElement, LineElement } from 'chart.js';
import axios from "axios";
import moment from 'moment';
import { registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // Adapter for using Moment.js as the date adapter
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Bar } from 'react-chartjs-2';



const customerList = [
  {
    customer_id: "1",
    first_name: "XXX",
    last_name: "YYY",
    email: "xxx@test.com",
  },
  {
    customer_id: "2",
    first_name: "XXX",
    last_name: "YYY",
    email: "xxx@test.com",
  },
  {
    customer_id: "3",
    first_name: "XXX",
    last_name: "YYY",
    email: "xxx@test.com",
  }
]

export default function PartnerCustomer() {

  const auth = useSelector((state) => state.auth);
  const [fetched, setFetching] = useState(false);

  // Initialize history
  const history = useHistory();
  // Initialize state with values from localStorage or default values

  // Function to handle license cancellation
  const cancelLicense = (license_id) => {
    const { token, account } = store.getState().auth;
    axios.delete(`/license/license/${license_id}`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log('Success:', response.data);
        // Update LicenseInfo or handle response as needed
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th className='text-left'>Customer ID</th>
          <th className='text-left'>Full Name</th>
          <th className='text-left'>Email</th>
        </tr>
      </thead>
      <tbody>
        {customerList.map((customer, index) => (
          <tr key={index} className='bg-dsecondray'>
            <td className='p-6'>{customer.customer_id}</td>
            <td className='p-6'>{customer.first_name}</td>
            <td className='p-6'>{customer.last_name}</td>
            <td className='p-6'>{customer.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}


