import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../../store";

function Setup() {

  const auth = useSelector((state) => state.auth);
  // Get the token from your auth state
  //const { token } = useSelector((state) => state.auth);
  // Get the token from your Redux store
  const { token } = store.getState().auth;

  const [fetched, setFetching] = useState(false);
  const [dailyRecord, setRecord] = useState([])
  //const { token, account } = store.getState().auth;

  const [AccountInfo, SetAccountInfo] = useState({
    FullName: "",
    accountid: 0,
    user_id: 0,
    email: 0,
    status: 0,
    expiryDate: 0,
    accountBalance: 0,
    pnl: 0,
    brokerLink: "www.exness.com",
  });
  const getAccountInfo = () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/UserProfileInfo/`,
          {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          }
        )
        .then(function (res) {
          console.log(res);
          const result = res.data;
          SetAccountInfo({ ...result });
        })
        .catch(function (err) {
          console.error("There was an error!", err);
        });
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };
  useEffect(() => {
    // Fetch trade details from the backend API
    axios.get(`${process.env.REACT_APP_API_URL}/getAccountHistory`,
      {
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      }
    ) // Adjust the URL to match your API endpoint
      .then((response) => { //console.log(response.data.records); 
        setRecord(response.data.records)
      })
      .catch(error => console.error("Error fetching trade details:", error));
    getAccountInfo();
  }, []);


  // Assuming you have state variables for the input fields
  const [initialLot, setInitialLot] = useState(0.01);
  const [additiveLot, setAdditiveLot] = useState(0.01);
  const [dailyProfit, setDailyProfit] = useState(1);
  const [protectedEquity, setProtectedEquity] = useState(25);

  // Function to fetch data from the database
  const fetchData1 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tradingsettings/${AccountInfo.accountid}`, {
        headers: {
          'content-type': 'application/json',
          authorization: token,
        }
      });

      if (response.status === 200) {
        const data = response.data.records[0]; // Assuming the first record is the latest
        setInitialLot(data.initialLot);
        setAdditiveLot(data.additiveLot);
        setDailyProfit(data.dailyProfit);
        setProtectedEquity(data.protectedEquity);
      } else {
        console.error("Failed to fetch settings");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  // Use useEffect to call fetchData1 when the accountid has been fetched
  useEffect(() => {
    if (AccountInfo.accountid !== 0) {
      fetchData1();
    }
  }, [AccountInfo.accountid]); // Add AccountInfo.accountid as a dependency


  // Function to handle form submit
  const handleSubmit1 = async (event) => {
    //event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tradingsettings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify({
          accountid: AccountInfo.accountid,
          initialLot: initialLot,
          additiveLot: additiveLot,
          dailyProfit: dailyProfit,
          protectedEquity: protectedEquity
        })
      });

      if (response.status !== 200) {
        console.error("Failed to update settings");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  // Use the useEffect hook to submit the form when the component loads
  useEffect(() => {
    handleSubmit1();
  }, []);

  /*
  // Fetch settings from server when component mounts
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tradingsettings/${AccountInfo.accountid}`, {
          headers: {
            "Authorization": token,
            "Cache-Control": "no-cache"
          }
        });
  
        if (response.status === 200) {
          const data = await response.json();
          if (data.records.length > 0) {
            // Assuming the response contains an array of records and you're interested in the first one
            const settings = data.records[0];
            setInitialLot(settings.initialLot);
            setAdditiveLot(settings.additiveLot);
            setDailyProfit(settings.dailyProfit);
            setProtectedEquity(settings.protectedEquity);
          } else {
            alert("No settings found for this account. The form contains default values.");
          }
        } else {
          console.error("Failed to fetch settings");
        }
      } catch (error) {
        console.error("There was an error!", error);
      }
    };
  
    //fetchSettings();
    // Fetch settings when the page is focused
    const handleFocus = () => {
      fetchSettings();
    };
  
    window.addEventListener('focus', handleFocus);
  
    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);*/

  // Assuming you have state variables for the input fields
  const [passWord, setPassWord] = useState('password');
  const [showPassword, setShowPassword] = useState(false);
  const [serverAddress, setServerAddress] = useState('Exness-MT5Real9');
  const [login, setLogin] = useState(1234);
  const [source, setsource] = useState('www.exness.com');

  // Function to fetch data from the database
  const fetchData3 = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tradelogin/${AccountInfo.accountid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        }
      });

      if (response.status === 200) {
        const data = await response.json();
        const record = data.records[0]; // Get the first record
        setPassWord(record.passWord);
        setServerAddress(record.serverAddress);
        setLogin(record.login);
        setsource(record.source);
      } else {
        console.error("Failed to fetch settings");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  // Call fetchData3 immediately when the component mounts
  //fetchData3();

  // Use useEffect to call fetchData1 when the accountid has been fetched
  useEffect(() => {
    if (AccountInfo.accountid !== 0) {
      fetchData3();
    }
  }, [AccountInfo.accountid]); // Add AccountInfo.accountid as a dependency


  // Function to send data to the /deletecloud endpoint
const deleteCloud = async (event) => {
  event.preventDefault();
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/taLogin/deletecloud`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": token
      },
      body: JSON.stringify({ login }),
    });

    if (response.ok) {
      console.log(`Service and deployment my-app-${login}-3000 deleted.`);
    } else {
      console.error('Failed to delete service and deployment.');
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};

  /*// Function to handle form submit
  const handleSubmit3 = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tradelogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify({
          accountid: AccountInfo.accountid,
          passWord: passWord,
          serverAddress: serverAddress,
          login: login,
        })
      });
  
      if (response.status !== 200) {
        console.error("Failed to update settings");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };*/

  const handleSubmit3 = async (event) => {
    event.preventDefault();

    const data = JSON.stringify({
      accountid: AccountInfo.accountid,
      passWord: passWord,
      serverAddress: serverAddress,
      login: login,
      source: source,
    });

    const endpoints = [
      `${process.env.REACT_APP_API_URL}/tradelogin`,
      `${process.env.REACT_APP_API_URL}/taLogin/taLogin`
    ];

    for (const endpoint of endpoints) {
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": token
          },
          body: data
        });

        if (response.status !== 200) {
          console.error(`Failed to update settings for endpoint ${endpoint}`);
        }
      } catch (error) {
        console.error(`There was an error with endpoint ${endpoint}!`, error);
      }
    }
  };


  // Assuming you have state variables for the input fields
  const [monday, setMonday] = useState(true);
  const [tuesday, setTuesday] = useState(true);
  const [wednesday, setWednesday] = useState(true);
  const [thursday, setThursday] = useState(true);
  const [friday, setFriday] = useState(true);
  const [saturday, setSaturday] = useState(true);
  const [sunday, setSunday] = useState(true);

  // Function to fetch data from the database
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/timesettings/${AccountInfo.accountid}`, {
        headers: {
          'content-type': 'application/json',
          authorization: token,
        }
      });

      if (response.status === 200) {
        setMonday(response.data.monday);
        setTuesday(response.data.tuesday);
        setWednesday(response.data.wednesday);
        setThursday(response.data.thursday);
        setFriday(response.data.friday);
        setSaturday(response.data.saturday);
        setSunday(response.data.sunday);
      } else {
        console.error("Failed to fetch settings");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  // Use useEffect to call fetchData1 when the accountid has been fetched
  useEffect(() => {
    if (AccountInfo.accountid !== 0) {
      fetchData();
    }
  }, [AccountInfo.accountid]); // Add AccountInfo.accountid as a dependency


  // Function to handle form submit
  const handleSubmit = async (event) => {
    //event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/timesettings`,
        {
          accountid: AccountInfo.accountid,
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday
        },
        {
          headers: {
            'content-type': 'application/json',
            authorization: token,
          }
        });

      if (response.status !== 200) {
        console.error("Failed to update settings");
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  
  // Use the useEffect hook to submit the form when the component loads
  useEffect(() => {
    handleSubmit();
  }, []);

  /*
  useEffect(() => {
    // Fetch time settings from server when component mounts
    const fetchTimeSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/timesettings/${AccountInfo.accountid}`, {
          headers: {
            "Authorization": token,
            "Cache-Control": "no-cache"
          }
        });
  
        if (response.status === 200) {
          const data = response.data;
          if (data.records.length > 0) {
            // Assuming the response contains an array of records and you're interested in the first one
            const timeSettings = data.records[0];
            setMonday(timeSettings.monday);
            setTuesday(timeSettings.tuesday);
            setWednesday(timeSettings.wednesday);
            setThursday(timeSettings.thursday);
            setFriday(timeSettings.friday);
            setSaturday(timeSettings.saturday);
            setSunday(timeSettings.sunday);
          } else {
            alert("No time settings found for this account. The form contains default values.");
          }
        } else {
          console.error("Failed to fetch time settings");
        }
      } catch (error) {
        console.error("There was an error!", error);
      }
    };
  
    // Fetch time settings when the page is focused
    const handleFocus = () => {
      fetchTimeSettings();
    };
  
    window.addEventListener('focus', handleFocus);
  
    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []); // Runs on mount and whenever the page is focused
  */

  return (

    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <FullContainer className="mt-0" style={{ width: '100%' }}>
        <Container style={{ width: '100%' }}>
          <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
            Dantis AI Settings
          </h1>
          <div className="grid lg:grid-cols-1 gap-2 w-full mb-6">
            <div className='col-span-12'>
              <form onSubmit={handleSubmit3}>
                <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
                  <ul className='list-disc grid grid-cols-1 capitalize'>
                    <li className='flex flex-col md:flex-row items-center justify-center'>
                      <div className='tooltip'>
                        <p className='mr-2'>Login</p>
                        <span className='tooltiptext'>This is your trading account login number. Must be same as account ID</span>
                      </div>
                      <input type="number" value={login} onChange={(e) => setLogin(e.target.value)} className='mt-2 md:mt-3 rounded-lg px-2' style={{ color: 'black' }} />
                    </li>
                    <li className='flex flex-col md:flex-row items-center justify-center'>
                      <div className='tooltip'>
                        <p className='mr-2'>Password</p>
                        <span className='tooltiptext'>This is your trading account password</span>
                      </div>
                      <input type={showPassword ? "text" : "password"} value={passWord} onChange={(e) => setPassWord(e.target.value)} className='mt-2 md:mt-3 rounded-lg px-2' style={{ color: 'black' }} />
                      <button className='btnSecondary4' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'Hide' : 'Show'}
                      </button>
                    </li>
                    <li className='flex flex-col md:flex-row items-center justify-center'>
                      <div className='tooltip'>
                        <p className='mr-2'>Server</p>
                        <span className='tooltiptext'>This is your trading account MT5 server</span>
                      </div>
                      <input type="text" value={serverAddress} onChange={(e) => setServerAddress(e.target.value)} className='mt-2 md:mt-3 rounded-lg px-2' style={{ color: 'black' }} />
                    </li>
                    <li className='flex flex-col md:flex-row items-center justify-center'>
                      <div className='tooltip'>
                        <p className='mr-2'>Broker URL</p>
                        <span className='tooltiptext'>This is your Broker's web URL </span>
                      </div>
                      <input type="text" value={source} onChange={(e) => setsource(e.target.value)} className='mt-2 md:mt-3 rounded-lg px-2' style={{ color: 'black' }} />
                    </li>
                    <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                      <button
                        type="submit" className='btnSecondary3' onClick={() => alert('Inputs have been submitted!')}>
                        Submit
                      </button>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>

          <div className='w-full col-span-12 md:col-span-12 rounded-lg bg-dsecondray overflow-hidden'>
            <form onSubmit={handleSubmit1}>
              <div className='grid grid-cols-3 font-bold bg-dprimary px-6 py-4'>
                <p className='col-span-1'>Setting</p>
                <p className='col-span-1'>Input</p>
                <button type="submit" className='btnSecondary2' onClick={() => alert('Inputs have been submitted!')}>
                  OK
                </button>
              </div>

              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <div className='tooltip'>
                  <p className='mr-2'>lot size </p>
                  <span className='tooltiptext'>This is the initial lot size. Maximum of 0.1</span>
                </div>
                <input type="number" value={initialLot} onChange={(e) => setInitialLot(e.target.value)} className='w-1/2 rounded-lg px-2' style={{ color: 'black' }} />
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <div className='tooltip'>
                  <p className='mr-2'>Additve Lot </p>
                  <span className='tooltiptext'>This is the additive lot size. Should be the same as the lot size or smaller.</span>
                </div>
                <input type="number" value={additiveLot} onChange={(e) => setAdditiveLot(e.target.value)} className='w-1/2 rounded-lg px-2' style={{ color: 'black' }} />
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <div className='tooltip'>
                  <p className='mr-2'>Daily Profit</p>
                  <span className='tooltiptext'>This is the percentage of Profit if your Account Balance to be made daily,a maximum of 3%</span>
                </div>
                <input type="number" value={dailyProfit} onChange={(e) => setDailyProfit(e.target.value)} className='w-1/2 rounded-lg px-2' style={{ color: 'black' }} />
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <div className='tooltip'>
                  <p className='mr-2'>protected equity</p>
                  <span className='tooltiptext'>This is the max. percentage of your account balance that can be risked in extreme market overturns. ProtectedEquity must be a positive number and integer. Suggested value: 20% </span>
                </div>
                <input type="number" value={protectedEquity} onChange={(e) => setProtectedEquity(e.target.value)} className='w-1/2 rounded-lg px-2' style={{ color: 'black' }} />
              </div>
            </form>

            <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-3 font-bold bg-dprimary px-6 py-1'>
                <p className='col-span-1'>Allow trading on;</p>
                <p className='col-span-1'></p>
                <button type="submit" className='btnSecondary2' onClick={() => alert('Inputs have been submitted!')}>
                  OK
                </button>
                {/*<button
                    className='btnSecondary2' onClick={handleOkClickTime}>
                    OK
                </button>*/}
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Monday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={monday} onChange={e => setMonday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Tuesday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={tuesday} onChange={e => setTuesday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Wednesday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={wednesday} onChange={e => setWednesday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Thursday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={thursday} onChange={e => setThursday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Friday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={friday} onChange={e => setFriday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Saturday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={saturday} onChange={e => setSaturday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className='grid grid-cols-3 text-sm px-6 py-4' >
                <p className='mr-2'>Sunday</p>
                <select className='w-1/2 rounded-md px-1' style={{ color: 'black' }} value={sunday} onChange={e => setSunday(e.target.value === 'true')}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </form>
            <div className="grid lg:grid-cols-1 gap-2 w-full mb-6 mt-6 overflow-hidden">
            <div className='col-span-12'>
              <form onSubmit={deleteCloud}>
                <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
                  <ul className='list-disc grid grid-cols-1 capitalize'>
                  <p className='flex flex-col mt-3 md:flex-row items-center justify-center'>Delete Account from Dantis Cloud</p>
                    <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                      <button
                        type="submit" className='btnSecondary3' onClick={() => alert('Delete has been sent!')}>
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>

          </div>



          {/*<FAQCARD
              text={
                <>
                  Download a free MT5 for PC (if you do not already have one){" "}
                  <a href="https://www.exness.com/metatrader-5/" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>HERE</span>
                  </a>
                  . <br />
                  watch the <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>Setup & Settings Tutorials</span>
                  </a>{" "}
                  below to set up faster or follow these instructions. <br />
                  You can set this up on your PC locally or on a VPS or cloud
                  platform to work 24/7 without your PC being on or connected (12
                  Months FREE). Click{" "}
                  <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>VPS</span>
                  </a>{" "}
                  to learn how.
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  1. Download <span style={{ fontWeight: 'bold' }}>MT5</span> on you PC <a href="https://www.exness.com/metatrader-5/" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>HERE</span>
                  </a> and open it.<br />
                  2. Download the <span style={{ fontWeight: 'bold' }}>Dantis 2.0.ex5</span> file from <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>HERE</span>
                  </a>{" "}.<br />
                  3. Add <span style={{ fontWeight: 'bold' }}>Dantis 2.0.ex5</span> to the <span style={{ fontWeight: 'bold' }}>"MQL5/Experts”</span> folder of your MT5 terminal Data Folder.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(1).png"
                    alt="MT5 Download"
                    style={{ width: '250px', height: 'auto', marginBottom: '10px' }} // Adjust width and height as needed
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(2).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto', marginBottom: '10px' }} // Adjust width and height as needed
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(3).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  4. Close and re-open your MT5 terminal.<br />
                  5. Click on <span style={{ fontWeight: 'bold' }}>“Algo-trading”</span> in the top left part of the MT5 terminal and drag the <span style={{ fontWeight: 'bold' }}>Dantis 2.0</span> file to the open <span style={{ fontWeight: 'bold' }}>EURUSD, AUDCAD, OR USOIL</span> chart window (choose one chart based on your Account Balance).<br />
                  6. If you do not have the <span style={{ fontWeight: 'bold' }}>USOIL</span> the symbol in the <span style={{ fontWeight: 'bold' }}>Market Watch</span> tab, you can add it by typing <span style={{ fontWeight: 'bold' }}>USOIL or WTI or CrudeOil</span> in the <span style={{ fontWeight: 'bold' }}>+ click to add</span> box in the <span style={{ fontWeight: 'bold' }}>Market Watch</span> tab. Do same for the other symbols.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(20).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  7. Check the following boxes in the popup view.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(9).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto', marginBottom: '10px' }} // Adjust width and height as needed
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(7).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  8. Alter these settings values to your liking to get the best value from <span style={{ fontWeight: 'bold' }}>Dantis</span>.
                      Watch the <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>Settings Tutorial video</span>
                  </a>{" "} for better explanations on each item.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(8).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />*/}

        </Container>
      </FullContainer>
    </div>
  );
}


export default Setup;

const FAQCARD = ({ title, text }) => {
  const [showFullText, setShowFullText] = useState(false);

  return (
    <div className="flex flex-col bg-[#302D41] py-5 px-10" style={{ width: '100%' }}>
      <h1 className="text-xl lg:text-3xl font-bold uppercase my-5">{title}</h1>
      <p>{text}</p>
      {/* {text.split(" ").length > 20 && (
        <button
          onClick={() => setShowFullText(!showFullText)}
          className="w-[20%] btnSecondary mt-5 ml-auto"
        >
          {showFullText ? "Less" : "More"}
        </button>
      )} */}
    </div>
  );
};
