import React, { useEffect } from "react";
import { Container, FullContainer, Navbar, ProductPage } from "../components";
import ImageHoverChange from "../components/ImageHoverChange";

function ProductPage1() {
  useEffect(() => {
    // Dynamically create a script element for the chatbot
    const script = document.createElement("script");
    script.id = "64f00959494bff9a99310a24";
    script.src = "https://app.droxy.ai/chat.min.js";
    script.defer = true;

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this effect runs only once
  
  return (
    <FullContainer className="mt-0">
      <Navbar />
      <img
        src="/img/hero.webp"
        className="absolute top-0 left-0 h-full w-full"
        alt=""
      />
      <ProductPage />
      <Container>
      
        <div className="grid grid-cols-1 flex-col items-center justify-center mt-20">

          <ImageHoverChange
            className="mx-auto flex"
            image={"/img/ABOUT-PIC.webp"}
            hoverImage="/img/ABOUT-PIChover.webp"
          />
        </div>
      </Container>
    </FullContainer>
  );
}

export default ProductPage1;
