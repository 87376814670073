import Container from "./Container";
import FullContainer from "./FullContainer";
import { ClockIcon } from "@heroicons/react/24/outline";
import ImageHoverChange from "./ImageHoverChange";

export default function Support() {
  return (
    <FullContainer>
      <Container>
        <div className="flex flex-col-reverse lg:grid grid-cols-2">
          <div className="flex flex-col justify-center p-10">
            <h2 className="heading2 mb-3 flex items-center">
              <ClockIcon className="w-8 mr-2" /> 24/7 support
            </h2>
            <p>
              Dantis AI offers 24/7 support to their clients to ensure they
              receive the help they need, whenever they need it. With 24/7
              support, clients can get assistance with any issues or questions
              they may have about the platform or their trades, at any time of
              the day or night.
            </p>
          </div>
          <ImageHoverChange
            image={"/img/pic3.webp"}
            hoverImage={"/img/pic3hover.webp"}
          />
        </div>
      </Container>
    </FullContainer>
  );
}
