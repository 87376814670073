import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../../store";
import { FaCcAmex, FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "../Stripe/CheckoutForm";
import { loadStripe } from "react-stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);




/*const ProductList = [
  {
    product_name: "License 1",
    product_code: "Test Code",
    price: "10",
  },
  {
    product_name: "License 1",
    product_code: "Test Code",
    price: "10",
  },
  {
    product_name: "License 1",
    product_code: "Test Code",
    price: "10",
  },
]*/


function Product() {
  const { token } = store.getState().auth;
  //const [product, setProduct] = useState(ProductList);
  const [product, setProduct] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const stripe = useStripe();

  // stripe
  const [clientSecret, setClientSecret] = useState("");
  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#212d63',
      colorText: '#ffffff',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  const startPaymentProcess = async (product, licenseData) => {

    setShowModal(true)
    await fetch(`${process.env.REACT_APP_API_URL}/create-subscription`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
      body: JSON.stringify({
        connectedAccountId: product.accountId,
        productId: product.productId,
        email: email,
        metadata: licenseData,
      }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);

        console.log('this is new account data', clientSecret)
      }).catch((err) => {
        // setProcess(false)
      })


  };


  // saved card handle
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [productData, setProductData] = useState({});
  console.log('Saved payment methods', savedPaymentMethods);
  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };
  const handlePayWithSavedCard = async (id) => {
    const result = window.confirm("Are you sure you want pay with this card , This cannot be undone?");

    if (result === true) {
      // User clicked OK
      console.log(id);

      await fetch(`${process.env.REACT_APP_API_URL}/create-subscription-saved-card`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          connectedAccountId: productData.product.accountId,
          productId: productData.product.productId,
          email: email,
          metadata: productData.licenseData,
          paymentMethodId: id,
        }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
      })
        .then((res) => res.json())
        .then(async (data) => {
          setClientSecret(data.clientSecret);
          if (
            data.clientSecret
          ) {
            const { paymentIntent } = await stripe.handleCardPayment(data.clientSecret);
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              alert("Error: " + result.error.message);
              //console.log(result.error.message);

              // this.setState({
              //     isLoading: false
              // })
            } else {
              // The payment has been processed!
              if (paymentIntent.status === 'succeeded') {
                setShowModal(false);
                window.alert("Successfully purchased")
              }
            }
            
          }
          console.log('this is new account data', clientSecret)
        }).catch((err) => {
          // setProcess(false)
        })



    } else {
      // User clicked Cancel or closed the dialog
      // Do nothing or handle accordingly
    }
  };

  const renderCardIcon = (brand) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return <FaCcVisa size={30} />;
      case 'mastercard':
        return <FaCcMastercard size={30} />;
      case 'amex':
        return <FaCcAmex size={30} />;
      default:
        return null;
    }
  };
  // Function to handle payment with saved card
  // Create a new state for the customer info
  const [customerInfo, setCustomerInfo] = useState({});

  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        // Update state with customer info
        setCustomerInfo(response.data);
        console.log("CustomerInfo", response.data);
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
      });
  }, []);

  // handle buy
  const createLicense = async (product) => {
    // Prepare the license data
    const licenseData = {
      admin_id: product.admin_id,
      product_code: product.product_code,
      customer_id: customerInfo.customer_id,
      allowed_account: 1234, // Set this value
    };
    setProductData({
      product,
      licenseData,
    })
    console.log(product);

    await startPaymentProcess(product, licenseData)

   
  };
  //

  /*const getProducts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/product/products`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        console.log('Product',response.data)
        setProduct(response.data);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  }*/
  const getProducts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/product/products`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(async response => {
        console.log('Product',response.data)
        
        // Fetch admin info for each product
        const productsWithAdminInfo = await Promise.all(response.data.map(async product => {
          const adminResponse = await axios.get(`${process.env.REACT_APP_API_URL}/partner/adminInfo/${product.admin_id}`, {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          });
          console.log(`Fetched admin info for product ${product.admin_id}:`, adminResponse.data);
          
          // Add admin info to product
          return { ...product, admin: adminResponse.data };
        }));
  
        setProduct(productsWithAdminInfo);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  }
  

  useEffect(() => {
    // Fetch products here and set to state
    getProducts();
    getAccountInfo();
  }, []);

  // get acc info
  const [fetched, setFetching] = useState(false);

  const [AccountInfo, SetAccountInfo] = useState({
    FullName: "",
    accountid: 0,
    user_id: 0,
    email: 0,
    status: 0,
    expiryDate: 0,
    customerid: 0,
    accountBalance: 0,
    pnl: 0,
    brokerLink: "www.exness.com",
  });
  console.log(AccountInfo.email);
  const getAccountInfo = () => {
    if (!fetched) {
      const { token, account } = store.getState().auth;
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/customer/customerInfo`,
          {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          }
        )
        .then(function (res) {
          console.log(res);
          const result = res.data;
          setEmail(result.email);
          setFirstName(result.first_name);

        })
        .catch(function (err) {
          console.error("There was an error!", err);
        });
      setTimeout(() => {
        setFetching(false);
      }, 1000);
    }
  };
  //

  const createSubscriber = (product) => {
    // Concatenate the first name and last name to make full_name
    const full_name = customerInfo.first_name + ' ' + customerInfo.last_name;

    // Prepare the Subscriber data
    const SubData = {
      full_name: full_name,
      product_code: product.product_code,
      email: customerInfo.email,
      product_name: product.product_name,
    };

    // Send a POST request to the "/Subscribers" endpoint
    axios.post(`${process.env.REACT_APP_API_URL}/subscriber/Subscribers`, SubData, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        alert('Subscriber has been created successfully!');
      })
      .catch(error => {
        console.error("There was an error!", error);
        alert('Failed to create subscriber!');
      });
  };

  return (
    <>
      <table className='w-full'>
      <thead className='bg-glass'>
        <tr>
          <th className='text-left'>Product Name</th>
          <th className='text-left'>Provider</th>
          <th className='text-left'>Price</th>
          <th className='text-left'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {product?.map((product, index) => (
          <tr key={product.product_code} className='bg-dsecondray'>
            <td className='p-6'>{product.product_name}</td>
            <th className='text-left'>{product.admin.first_name} {product.admin.last_name}</th>
            <td className='p-6'>${product.price}</td>
            <td className='p-6'>
              <button className='capitalize btnSecondaryShadow animate-pulse mt-10' onClick={() => { createLicense(product); createSubscriber(product); }}>License</button>
            </td>
          </tr>
        ))}
      </tbody>

    </table>
    <div>
        {/* Payment modal */}
        {showModal ? (
          <>
            {/* Backdrop */}
            <div className="fixed inset-0 z-50 bg-black opacity-25"></div>

            {/* Modal container */}
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto flex-wrap fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-full max-w-lg">
                {/* Modal content */}
                <div className="border border-gray-200 bg-[#1e142e] rounded-lg shadow-lg outline-none focus:outline-none">
                  {/* Header */}
                  <div className="flex items-center justify-between p-4 border-b border-solid border-gray-200 rounded-t">
                    <h3 className="text-xl md:text-2xl font-semibold">Make Payment</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 hover:text-black hover:bg-gray-100 rounded-full focus:outline-none focus:bg-gray-100 focus:text-black"
                      onClick={() => setShowModal(false)}
                    >
                      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  {/* Body */}
                  <div className="p-4">
                    <div className="mb-4">
                      {/* Payment method selection */}
                      <div className="flex flex-col space-y-2">
                        <button
                          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                          onClick={() => {
                            handlePaymentMethodSelect('saved')
                            fetch(`${process.env.REACT_APP_API_URL}/getPaymentMethods`, {
                              method: "POST",
                              headers: {
                                "content-type": "application/json",
                                authorization: token,
                              },
                              body: JSON.stringify({

                                email: email,

                              }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
                            })
                              .then((res) => res.json())
                              .then((data) => {
                                if (data.paymentMethods.length === 0) {
                                  window.alert("No payment methods,Please pay with new card")

                                }
                                setSavedPaymentMethods(data.paymentMethods)


                              }).catch((err) => {
                                // setProcess(false)
                              })
                          }}
                        >
                          Pay with saved card
                        </button>
                        <button
                          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                          onClick={() => handlePaymentMethodSelect('new')}
                        >
                          Pay with new card
                        </button>
                        {
                          selectedPaymentMethod === null ? <></> :
                            <>
                              <button
                                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                                onClick={() => handlePaymentMethodSelect(null)}
                              >
                                Back
                              </button>
                            </>
                        }

                      </div>

                      {/* Saved card selection */}
                      {/* Saved card selection */}
                      {selectedPaymentMethod === 'saved' && (
                        <div className="flex flex-col space-y-2 mt-4">
                          {savedPaymentMethods?.map((method) => (
                            <div
                              onClick={() => {
                                handlePayWithSavedCard(method.id)

                              }}
                              className="border justify-between flex space-x-3  items-center cursor-pointer p-1 rounded-md border-l-white" key={method.id}>
                              {renderCardIcon(method.brand)} {/* Render card icon */}

                              <span className="">Ending with {method.last4} Expires at {method.expMonth}/{method.expYear}</span>
                              {/* <button> <FaTrash></FaTrash></button> */}
                            </div>



                          ))}
                        </div>
                      )}
                    </div>
                    {/* Payment form for new card */}
                    {selectedPaymentMethod === 'new' && clientSecret && (
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm clientSecretNew={clientSecret} email={email} userName={firstName} />
                      </Elements>
                    )}
                  </div>

                  {/* Footer */}
                  <div className="flex items-center justify-end p-4 border-t border-solid border-gray-200 rounded-b">
                    <button
                      className="text-red-500 bg-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-2 rounded hover:bg-gray-100 focus:bg-gray-100"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
    </div>
    </>
    
  );
}

export default Product;