import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../../store";


function CommunityView() {
  const { token } = store.getState().auth;
  const [customerInfo, setCustomerInfo] = useState({});
  const history = useHistory();
  const location = useLocation();
  const [adminId, setAdminId] = useState(location.state.adminId);


    // Check if adminId exists, if not, redirect to previous page
    if (!adminId) {
        history.goBack();
    }
  // Fetch customer info when component mounts
  useEffect(() => {
    const { token, account } = store.getState().auth;
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
      headers: {
        "content-type": "application/json",
        authorization: token,
      },
    })
      .then(response => {
        // Update state with customer info
        setCustomerInfo(response.data);
      })
      .catch(error => {
        console.error('Error fetching customer info:', error);
      });
  }, []);


const [messages, setMessages] = useState([{ text: 'Welcome to our community!', timestamp: new Date() }, { text: 'Feel free to share your thoughts here.', timestamp: new Date() }]);
const [message, setMessage] = useState('');

//const [messages, setMessages] = useState([]);
//const [message, setMessage] = useState('');

// Fetch messages from the database
const fetchMessages = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/subscriber/Messages?admin_id=${adminId}`, {
    headers: {
      "content-type": "application/json",
      authorization: token,
    },
  })
  .then(response => {
    // Sort messages by timestamp
    const sortedMessages = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    setMessages(sortedMessages);
  })
  .catch(error => console.error(error));
};

useEffect(() => {
  if (adminId) {
    fetchMessages();
  }
}, [adminId]);


return (
  <>
          <FullContainer className="mt-0" style={{ width: '100%' }}>
            <Container style={{ width: '100%' }}>
              <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
                Tool Community
              </h1>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <div style={{ overflowY: 'auto', marginBottom: 'auto' }}>
                  {messages.slice().reverse().map((message, index) => (
                    <div key={index} className="bg-glass-only" style={{ borderRadius: '5px', padding: '10px', margin: '30px' }}>
                      <p style={{ textAlign: 'left' }}>{message.text}</p>
                      <div style={{ textAlign: 'right' }}>
                        {message && message.timestamp && <p style={{ fontSize: '0.8em', color: 'gray' }}>{new Date(message.timestamp).toLocaleString()}</p>}
                        {message.pinned && <span style={{ fontSize: '0.8em', color: 'red' }}>Pinned</span>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Container>
          </FullContainer>
          </>
);
}

export default CommunityView; 