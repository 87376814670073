import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../../store";




function Payment() {
    const { token } = store.getState().auth;
    const [paymentHistory, setPaymentHistory] = useState([]);
    const[email,setEmail]=useState('');
    const history = useHistory();

    useEffect(() => {
        // Fetch products from the database when the component mounts
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/customer/customerInfo`,
                {
                    headers: {
                        "content-type": "application/json",
                        authorization: token,
                    },
                }
            )
            .then( async function (res) {
                console.log(res);
                const result = res.data;
                setEmail(result.email);
               
                await fetch(`${process.env.REACT_APP_API_URL}/paymentHistory`, {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        authorization: token,
                    },
                    body: JSON.stringify({
                        email: result.email,
                    }), 
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data.paymentHistory)
                        setPaymentHistory(data.paymentHistory);
                    }
                        
                        )

            })
            .catch(function (err) {
                console.error("There was an error!", err);
            });
        
    }, []);


    return (
        <table className='w-full'>
            <thead className='bg-glass'>
                <tr>
                    <th className='text-left'>ID</th>
                    <th className='text-left'>Customer ID</th>
                    <th className='text-left'>Date</th>
                    <th className='text-left'>Amount</th>
                    <th className='text-left'>Status</th>
                </tr>
            </thead>
            <tbody>
                {paymentHistory?.map((payment, index) => (
                    <tr key={index} className='bg-dsecondray'>
                        <td className='p-6'>{payment.paymentIntentId}</td>
                        <td className='p-6'>{payment.customerId}</td>
                        <td className='p-6'>{payment.date}</td>
                        <td className='p-6'>{payment.amount}</td>
                        <td className='p-6'>{payment.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Payment;
