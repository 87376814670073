import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import { FullContainer } from '../components';

export default function PorC() {
  const [selectedLogo, setSelectedLogo] = useState(null);

  // Define the paths for the "/mac" and "/windows" pages
  const macPath = '/clogin';
  const windowsPath = '/plogin';

  // Determine the path based on the selected logo
  const nextPath = selectedLogo === 'mac' ? macPath : windowsPath;

  return (
    <FullContainer>
      <div className="grid lg:grid-cols-2 w-full min-h-screen">
        <div className="bg-acountId bg-cover bg-center"></div>
        <div className="flex flex-col items-center justify-center p-10">
          <p className="heading flex items-center gap-2">
            Last Step <img src="/img/step3.webp" alt="" />
          </p>
          <h1 className="heading1 mt-5 capitalize text-center">
            Select a Service
          </h1>
          <h5 className="mt-6 mb-6 text-sm text-center">
            Are you a product Creator (<strong>PARTNER</strong>) or product User (<strong>CUSTOMER</strong>)?
          </h5>
          <div className="flex gap-x-10 mt-5 justify-center">
            <button
              className={`logo ${selectedLogo === 'windows' ? 'selected' : ''} heading mt-5 capitalize text-center`}
              onClick={() => setSelectedLogo('windows')}
            >
              PARTNER
            </button>
            <button
              className={`logo ${selectedLogo === 'mac' ? 'selected' : ''} heading mt-5 capitalize text-center`}
              onClick={() => setSelectedLogo('mac')}
            >
              CUSTOMER
            </button>
          </div>
          {/* Add the Link components */}
          <Link className="mt-10 btnPrimary" to={nextPath}>
            Next
          </Link>
          <Link className="mt-5 text-primary underline" to="/selection">
            Go back
          </Link>
          <Link className="mt-5 text-primary underline" to="/">
            Go Home
          </Link>
        </div>
      </div>
    </FullContainer>
  );
}
