import Container from "./Container";
import FullContainer from "./FullContainer";
import ImageHoverChange from "./ImageHoverChange";

export default function Dantis() {
  return (
    <FullContainer className="">
      <Container>
        <div className="grid lg:grid-cols-2">
          <ImageHoverChange
            image={"/img/pic1.webp"}
            hoverImage="/img/pic1hover.webp"
          />
          <div className="flex flex-col justify-center p-10">
            <h2 className="heading2 mb-3">Dantis AI</h2>
            <p>
              Dantis AI scans billions of data points from financial market data of various sorts
              and sentiment then applies superior logic to produce its
              consistently exceptional results.
            </p>
          </div>
        </div>
      </Container>
    </FullContainer>
  );
}
