import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import store from "../../store";
import axios from "axios";
//import { each } from "chart.js/dist/helpers/helpers.core";

function AdminSetup() {
  // Get the token from your Redux store
const { token } = store.getState().auth;


// Assuming you have state variables for the input fields
const [NewsCloseOrder, setNewsCloseOrder] = useState('CloseAllRunningOrder');
const [High_Impact_News, setHigh_Impact_News] = useState(true);
const [High_Start_Time, setHigh_Start_Time] = useState(480);
const [High_Stop_Time, setHigh_Stop_Time] = useState(480);
const [show_high_line, setShow_high_line] = useState(true);
const [Medium_Impact_News, setMedium_Impact_News] = useState(true);
const [Medium_Start_Time, setMedium_Start_Time] = useState(240);
const [Medium_Stop_Time, setMedium_Stop_Time] = useState(60);
const [show_medium_line, setShow_medium_line] = useState(true);
const [Low_Impact_News, setLow_Impact_News] = useState(false);
const [Low_Start_Time, setLow_Start_Time] = useState(0);
const [Low_Stop_Time, setLow_Stop_Time] = useState(0);
const [show_low_line, setShow_low_line] = useState(true);
// Add all other fields
const handleChange = (event) => {
  setNewsCloseOrder(event.target.value);
};

// Function to handle form submit
const handleSubmit2 = async (event) => {
  event.preventDefault();

  try {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/newsSettings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": token
    },
    body: JSON.stringify({
      NewsCloseOrder: NewsCloseOrder,
      High_Impact_News: High_Impact_News,
      High_Start_Time: High_Start_Time,
      High_Stop_Time: High_Stop_Time,
      show_high_line: show_high_line,
      Medium_Impact_News: Medium_Impact_News,
      Medium_Start_Time: Medium_Start_Time,
      Medium_Stop_Time: Medium_Stop_Time,
      show_medium_line: show_medium_line,
      Low_Impact_News: Low_Impact_News,
      Low_Start_Time: Low_Start_Time,
      Low_Stop_Time: Low_Stop_Time,
      show_low_line: show_low_line,
      // Add all other fields
    })
  });

  if (response.ok) {
    const data = await response.json();
    // Update the state variables with the latest settings
    setNewsCloseOrder(data.records.NewsCloseOrder);
    setHigh_Impact_News(data.records.High_Impact_News);
    setHigh_Start_Time(data.records.High_Start_Time);
    setHigh_Stop_Time(data.records.High_Stop_Time);
    setShow_high_line(data.records.show_high_line);
    setMedium_Impact_News(data.records.Medium_Impact_News);
    setMedium_Start_Time(data.records.Medium_Start_Time);
    setMedium_Stop_Time(data.records.Medium_Stop_Time);
    setShow_medium_line(data.records.show_medium_line);
    setLow_Impact_News(data.records.Low_Impact_News);
    setLow_Start_Time(data.records.Low_Start_Time);
    setLow_Stop_Time(data.records.Low_Stop_Time);
    setShow_low_line(data.records.show_low_line);
  } else {
    console.error("Failed to update settings");
  }
  } catch (error) {
      console.error("There was an error!", error);
    }
};


// Assuming you have state variables for the input fields
const [id, setId] = useState(1);
const [ea, setEa] = useState('Dantis 2.0');
const [enableDailyStartEnd, setEnableDailyStartEnd] = useState(true);
/*const [startTime, setStartTime] = useState(new Date());
const [endTime, setEndTime] = useState(new Date());
const [enableMondayTime, setEnableMondayTime] = useState(false);
const [mondayStartTime, setMondayStartTime] = useState(new Date());
const [mondayEndTime, setMondayEndTime] = useState(new Date());
const [enableFridayTime, setEnableFridayTime] = useState(false);
const [fridayEndTime, setFridayEndTime] = useState(new Date());
const [fridayCloseTime, setFridayCloseTime] = useState(new Date());*/
const [DistanceAddition, setDistanceAddition] = useState(100);
const [LotAdditionAfter, setLotAdditionAfter] = useState(1);
const [DistanceAdditionAfter, setDistanceAdditionAfter] = useState(1);
const [MaxOrders, setMaxOrders] = useState(10);
const [Distance, setDistance] = useState(100);
const [TakeProfit, setTakeProfit] = useState(100);
const [disabledTradingOnDrawdown, setDisabledTradingOnDrawdown] = useState(5);
const [enableTradingAfterEquity, setEnableTradingAfterEquity] = useState(3.5);

// Function to handle form submit
const handleSubmit = async (event) => {
  event.preventDefault();
  // Log the state variables
  console.log({
    id,
    ea,
    enableDailyStartEnd,
    DistanceAddition,
    LotAdditionAfter,
    DistanceAdditionAfter,
    MaxOrders,
    Distance,
    TakeProfit,
    disabledTradingOnDrawdown,
    enableTradingAfterEquity
  });

  try {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/adminSettings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": token
    },
    body: JSON.stringify({
      
      id: id,
      ea: ea,
      enableDailyStartEnd: enableDailyStartEnd,
      DistanceAddition: DistanceAddition,
      LotAdditionAfter: LotAdditionAfter,
      DistanceAdditionAfter: DistanceAdditionAfter,
      MaxOrders: MaxOrders,
      Distance: Distance,
      TakeProfit: TakeProfit,
      disabledTradingOnDrawdown: disabledTradingOnDrawdown,
      enableTradingAfterEquity: enableTradingAfterEquity
    })
  });

  if (response.status !== 200) {
    console.error("Failed to update settings");
  }
} catch (error) {
  console.error("There was an error!", error);
}
};
  return (
    
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <FullContainer className="mt-0" style={{ width: '100%' }}>
        <Container style={{ width: '100%' }}>
          <h1 className="text-3xl font-bold uppercase text-center mb-10 mt-8">
            Dantis AI Admin Settings
          </h1>
          <div className='w-full col-span-12 md:col-span-12 rounded-lg bg-dsecondray overflow-hidden'>

          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-3 font-bold bg-dprimary px-6 py-4'>
              <p className='col-span-1'>Admin Settings</p>
              <p className='col-span-1'>Input</p>
              <button type="submit" className='btnSecondary2' onClick={() => alert('Inputs have been submitted!')}>
                  OK
              </button>
            </div>

            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>ID</p>
                <span className='tooltiptext'>This is the ID</span>
              </div>
              <input type="number" value={id} onChange={(e) => {console.log('New value for ea:', e.target.value); setId(e.target.value);}} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>EA</p>
                <span className='tooltiptext'>This is the EA</span>
              </div>
              <input type="text" value={ea} onChange={(e) => setEa(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>Enable Daily Start End</p>
                <span className='tooltiptext'>This is the enableDailyStartEnd</span>
              </div>
              <select className='w-1/2' style={{color: 'black'}} value={enableDailyStartEnd} onChange={e => setEnableDailyStartEnd(e.target.value === 'true')}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
            </div>
            {/*<div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>startTime</p>
                <span className='tooltiptext'>This is the startTime</span>
              </div>
              <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(new Date(e.target.value))} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>endTime</p>
                <span className='tooltiptext'>This is the endTime</span>
              </div>
              <input type="datetime-local" value={endTime} onChange={(e) => setEndTime(new Date(e.target.value))} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>enableMondayTime</p>
                <span className='tooltiptext'>This is the enableMondayTime</span>
              </div>
              <select className='w-1/2' style={{color: 'black'}} value={enableMondayTime} onChange={e => setEnableMondayTime(e.target.value === 'true')}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>mondayStartTime</p>
                <span className='tooltiptext'>This is the mondayStartTime</span>
              </div>
              <input type="datetime-local" value={mondayStartTime} onChange={(e) => setMondayStartTime(new Date(e.target.value))} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>mondayEndTime</p>
                <span className='tooltiptext'>This is the mondayEndTime</span>
              </div>
              <input type="datetime-local" value={mondayEndTime} onChange={(e) => setMondayEndTime(new Date(e.target.value))} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>enableFridayTime</p>
                <span className='tooltiptext'>This is the enableFridayTime</span>
              </div>
              <select className='w-1/2' style={{color: 'black'}} value={enableFridayTime} onChange={e => setEnableFridayTime(e.target.value === 'true')}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>fridayEndTime</p>
                <span className='tooltiptext'>This is the fridayEndTime</span>
              </div>
              <input type="datetime-local" value={fridayCloseTime} onChange={(e) => setFridayEndTime(new Date(e.target.value))} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>fridayEndTime</p>
                <span className='tooltiptext'>This is the fridayCloseTime</span>
              </div>
              <input type="datetime-local" value={fridayCloseTime} onChange={(e) => setFridayCloseTime(new Date(e.target.value))} className='w-1/2' style={{color: 'black'}}/>
            </div>*/}
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>Distance Addition</p>
                <span className='tooltiptext'>This is the Additiive Distance</span>
              </div>
              <input type="number" value={DistanceAddition} onChange={(e) => setDistanceAddition(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>Lot Addition After</p>
                <span className='tooltiptext'>This is the additive Lot interval</span>
              </div>
              <input type="number" value={LotAdditionAfter} onChange={(e) => setLotAdditionAfter(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>Distance Addition After</p>
                <span className='tooltiptext'>This is the Additiive Distance interval</span>
              </div>
              <input type="number" value={DistanceAdditionAfter} onChange={(e) => setDistanceAdditionAfter(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>MaxOrders</p>
                <span className='tooltiptext'>This is the Max pendig Orders</span>
              </div>
              <input type="number" value={MaxOrders} onChange={(e) => setMaxOrders(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>Distance</p>
                <span className='tooltiptext'>This is the Distance points</span>
              </div>
              <input type="number" value={Distance} onChange={(e) => setDistance(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>TakeProfit</p>
                <span className='tooltiptext'>This is the Daily profits</span>
              </div>
              <input type="number" value={TakeProfit} onChange={(e) => setTakeProfit(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>disabledTradingOnDrawdown</p>
                <span className='tooltiptext'>This is the disable Trading On Drawdown of </span>
              </div>
              <input type="number" value={disabledTradingOnDrawdown} onChange={(e) => setDisabledTradingOnDrawdown(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            <div className='grid grid-cols-3 text-sm px-6 py-4'>
              <div className='tooltip'>
                <p className='mr-2'>Enable Trading After Equity</p>
                <span className='tooltiptext'>This is the max percentage of your account balance that can be risked in extreme market overturns</span>
              </div>
              <input type="number" value={enableTradingAfterEquity} onChange={(e) => setEnableTradingAfterEquity(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
            </div>
            </form>

            <form onSubmit={handleSubmit2}>
                <div className='grid grid-cols-3 font-bold bg-dprimary px-6 py-1'>
                <p className='col-span-1'>News filters;</p>
                <p className='col-span-1'></p>
                <button type="submit" className='btnSecondary2' onClick={() => alert('Inputs have been submitted!')}>
                  OK
                </button>
              </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>NewsCloseOrder</p>
                    <select className='w-1/2' style={{color: 'black'}} value={NewsCloseOrder} onChange={handleChange}>
                      <option value="CloseAllRunningOrder">CloseAllRunningOrder</option>
                      <option value="LetTheOrderRun">LetTheOrderRun</option>
                      <option value="PauseTrading">PauseTrading</option>
                    </select>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>High_Impact_News</p>
                    <select className='w-1/2' style={{color: 'black'}}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>High_Start_Time</p>
                  <input type="number" value={High_Start_Time} onChange={(e) => setHigh_Start_Time(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>High_Stop_Time</p>
                  <input type="number" value={High_Stop_Time} onChange={(e) => setHigh_Stop_Time(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>show_high_line</p>
                    <select className='w-1/2' style={{color: 'black'}}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>Medium_Impact_News</p>
                    <select className='w-1/2' style={{color: 'black'}}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>Medium_Start_Time</p>
                  <input type="number" value={Medium_Stop_Time} onChange={(e) => setMedium_Stop_Time(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>Medium_Stop_Time</p>
                  <input type="number" value={Medium_Stop_Time} onChange={(e) => setMedium_Stop_Time(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>show_medium_line</p>
                    <select className='w-1/2' style={{color: 'black'}}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>Low_Impact_News</p>
                    <select className='w-1/2' style={{color: 'black'}}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>Low_Start_Time</p>
                  <input type="number" value={Low_Stop_Time} onChange={(e) => setLow_Stop_Time(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>Low_Stop_Time</p>
                  <input type="number" value={Low_Stop_Time} onChange={(e) => setLow_Stop_Time(e.target.value)} className='w-1/2' style={{color: 'black'}}/>
                </div>
                <div className='grid grid-cols-3 text-sm px-6 py-4' >
                  <p className='mr-2'>show_low_line</p>
                    <select className='w-1/2' style={{color: 'black'}}>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                </div>
                </form>
              </div>

  

            {/*<FAQCARD
              text={
                <>
                  Download a free MT5 for PC (if you do not already have one){" "}
                  <a href="https://www.exness.com/metatrader-5/" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>HERE</span>
                  </a>
                  . <br />
                  watch the <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>Setup & Settings Tutorials</span>
                  </a>{" "}
                  below to set up faster or follow these instructions. <br />
                  You can set this up on your PC locally or on a VPS or cloud
                  platform to work 24/7 without your PC being on or connected (12
                  Months FREE). Click{" "}
                  <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>VPS</span>
                  </a>{" "}
                  to learn how.
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  1. Download <span style={{ fontWeight: 'bold' }}>MT5</span> on you PC <a href="https://www.exness.com/metatrader-5/" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>HERE</span>
                  </a> and open it.<br />
                  2. Download the <span style={{ fontWeight: 'bold' }}>Dantis 2.0.ex5</span> file from <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>HERE</span>
                  </a>{" "}.<br />
                  3. Add <span style={{ fontWeight: 'bold' }}>Dantis 2.0.ex5</span> to the <span style={{ fontWeight: 'bold' }}>"MQL5/Experts”</span> folder of your MT5 terminal Data Folder.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(1).png"
                    alt="MT5 Download"
                    style={{ width: '250px', height: 'auto', marginBottom: '10px' }} // Adjust width and height as needed
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(2).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto', marginBottom: '10px' }} // Adjust width and height as needed
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(3).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  4. Close and re-open your MT5 terminal.<br />
                  5. Click on <span style={{ fontWeight: 'bold' }}>“Algo-trading”</span> in the top left part of the MT5 terminal and drag the <span style={{ fontWeight: 'bold' }}>Dantis 2.0</span> file to the open <span style={{ fontWeight: 'bold' }}>EURUSD, AUDCAD, OR USOIL</span> chart window (choose one chart based on your Account Balance).<br />
                  6. If you do not have the <span style={{ fontWeight: 'bold' }}>USOIL</span> the symbol in the <span style={{ fontWeight: 'bold' }}>Market Watch</span> tab, you can add it by typing <span style={{ fontWeight: 'bold' }}>USOIL or WTI or CrudeOil</span> in the <span style={{ fontWeight: 'bold' }}>+ click to add</span> box in the <span style={{ fontWeight: 'bold' }}>Market Watch</span> tab. Do same for the other symbols.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(20).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  7. Check the following boxes in the popup view.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(9).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto', marginBottom: '10px' }} // Adjust width and height as needed
                  />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(7).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />
            <FAQCARD
              text={
                <>
                  8. Alter these settings values to your liking to get the best value from <span style={{ fontWeight: 'bold' }}>Dantis</span>.
                      Watch the <a href="https://drive.google.com/drive/folders/1uwh8LDHXUNyxdDw-QFk8w6hqszPrKHA0?usp=sharing" target="_blank" rel="noopener noreferrer">
                  <span style={{ color: 'pink' }}>Settings Tutorial video</span>
                  </a>{" "} for better explanations on each item.<br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="/img/image(8).png"
                    alt="MT5 Download"
                    style={{ width: '450px', height: 'auto' }} // Adjust width and height as needed
                  />
                  </div>
                </>
              }
            />*/}
         
        </Container>
      </FullContainer>
    </div>
  );
}


export default AdminSetup;

const FAQCARD = ({ title, text }) => {
  const [showFullText, setShowFullText] = useState(false);

  return (
    <div className="flex flex-col bg-[#302D41] py-5 px-10" style={{ width: '100%' }}>
      <h1 className="text-xl lg:text-3xl font-bold uppercase my-5">{title}</h1>
      <p>{text}</p>
      {/* {text.split(" ").length > 20 && (
        <button
          onClick={() => setShowFullText(!showFullText)}
          className="w-[20%] btnSecondary mt-5 ml-auto"
        >
          {showFullText ? "Less" : "More"}
        </button>
      )} */}
    </div>
  );
};
