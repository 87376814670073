import React, { useEffect, useState } from "react";
import { Container, FullContainer, Navbar } from "..";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import store from "../../store";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "../Stripe/CheckoutForm";
import { FaCcAmex, FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { loadStripe } from "react-stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_PK}`);

//function CloudSetup() {
function Setup({ setupData }) {

    const auth = useSelector((state) => state.auth);
    // Get the token from your auth state
    //const { token } = useSelector((state) => state.auth);
    // Get the token from your Redux store
    const { token } = store.getState().auth;
    const history = useHistory();

    const [fetched, setFetching] = useState(false);
    const [dailyRecord, setRecord] = useState([])
    //const { token, account } = store.getState().auth;

    // Create a new state for the customer info
    const [customerInfo, setCustomerInfo] = useState({});
    console.log('here is customer info', customerInfo)
    // Fetch customer info when component mounts
    useEffect(() => {
        const { token, account } = store.getState().auth;
        axios.get(
            `${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
            headers: {
                "content-type": "application/json",
                authorization: token,
            },
        })
            .then(response => {
                // Update state with customer info
                setCustomerInfo(response.data);
                if (response.data.cloudSubscriptionStatus === false || null || undefined) {
                    // history.push("/customer/customerdashboard");

                }
            })
            .catch(error => {
                console.error('Error fetching customer info:', error);
            });
    }, []);

    // Assuming you have state variables for the input fields
    const [passWord, setPassWord] = useState('password');
    const [showPassword, setShowPassword] = useState(false);
    const [serverAddress, setServerAddress] = useState('Exness-MT5Real9');
    const [login, setLogin] = useState(1234);
    const [source, setsource] = useState('www.exness.com');
    const [type, setType] = useState("");
    const [platform, setPlatform] = useState("");
    // Initialize setupId with setupData.id
    const [setupId, setSetupId] = useState(setupData.id);

    // Define getCloudDetails function
    const getCloudDetails = async () => {
        console.log("customerid and setupId;", customerInfo.customer_id, setupId)
        if (!customerInfo || !customerInfo.customer_id || !setupId) {
            console.error('customerInfo or customerInfo.customer_id or setupId is undefined');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/cloud/Cloud/${customerInfo.customer_id}/${setupId}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
            });

            if (!response.ok) {
                console.error('API request failed', response);
                return;
            }

            const data = await response.json();
            console.log('getcloud; ', data);

            // Check if data is an array and has at least one object
            if (Array.isArray(data) && data.length > 0) {
                // Set the state variables with the response data or keep the current defaults
                setPassWord(data[0].passWord || passWord);
                setShowPassword(data[0].showPassword || showPassword);
                setServerAddress(data[0].serverAddress || serverAddress);
                setLogin(data[0].login || login);
                setsource(data[0].source || source);
                setType(data[0].fileType || type);
                setPlatform(data[0].platform || platform);
                setSetupId(data[0].setupId || setupId); // Include setupId here
            }

            return data;
        } catch (error) {
            console.error('Failed to fetch cloud details', error);
        }
    };


    useEffect(() => {
        // Call getCloudDetails function
        getCloudDetails();
    }, [customerInfo]); // Add customerInfo as a dependency

    const deleteCloud = async (login) => {
        // Delete cloud
        const deleteResponse = await fetch(`${process.env.REACT_APP_API_URL}/cloud/deletecloud`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: token,
            },
            body: JSON.stringify({
                login: login,
            }),
        });
        const deleteData = await deleteResponse.json();

        if (deleteData.message === "Cloud deleted successfully") {
            // Delete Cloud from database
            const deleteCloudResponse = await fetch(`${process.env.REACT_APP_API_URL}/cloud/Cloud/${customerInfo.customer_id}/${setupId}`, {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
            });

            const deleteCloudData = await deleteCloudResponse.json();

            if (deleteCloudData) {
                history.push("/customer/customerdashboard");
            }
        }
    };


    const handleSubmit3 = async (event) => {
        event.preventDefault();

        const data = JSON.stringify({
            customer_id: customerInfo.customer_id,
            email: customerInfo.email,
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            passWord: passWord,
            serverAddress: serverAddress,
            login: login,
            source: source,
            fileType: type,
            platform: platform,
            setupId: setupId.toString(), // Include setupId here
        });
        console.log({data});

        const endpoints = [
            `${process.env.REACT_APP_API_URL}/cloud/Cloud/${setupId}`,
            `${process.env.REACT_APP_API_URL}/cloud/customercloud`
        ];

        for (const endpoint of endpoints) {
            try {
                const response = await axios.post(endpoint, data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token
                    }
                });

                if (response.status !== 200) {
                    console.error(`Failed to update settings for endpoint ${endpoint}`);
                }
            } catch (error) {
                console.error(`There was an error with endpoint ${endpoint}!`, error);
            }
        }
    };

    const [uploadedFile, setUploadedFile] = useState(null);
    const handleFileUpload = async (file, customerId) => {
        const formData = new FormData();
        formData.append('file', file);

        const endpoint = `${process.env.REACT_APP_API_URL}/cloud/Cloud/${customerInfo.customer_id}/${setupId}`;

        try {
            const response = await fetch(endpoint, {
                method: "PUT",
                headers: {
                    "Authorization": token
                },
                body: formData
            });

            if (response.status !== 200) {
                console.error(`Failed to upload file for endpoint ${endpoint}`);
            }
        } catch (error) {
            console.error(`There was an error with endpoint ${endpoint}!`, error);
        }
    };

    /*const handleFileUpload = async (file, customerId) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const fileData = reader.result;
        const endpoint = `${process.env.REACT_APP_API_URL}/cloud/Cloud/${customerInfo.customerId}`;
    
        try {
          const response = await fetch(endpoint, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token
            },
            body: JSON.stringify({
              fileData: fileData
            })
          });
    
          if (response.status !== 200) {
            console.error(`Failed to upload file for endpoint ${endpoint}`);
          }
        } catch (error) {
          console.error(`There was an error with endpoint ${endpoint}!`, error);
        }
      };
      reader.readAsDataURL(file);
    };*/

    // cancel subscription handlers
    const cancelSubscription = async (subId) => {
        const confirm = window.confirm('Are you sure you want to cancel this subscription?');
        let mainSubId;
        if(subId){
            mainSubId=subId;
        }else{
            mainSubId = customerInfo.cloudSubscriptionId;
        }
        if (confirm) {
            await fetch(`${process.env.REACT_APP_API_URL}/deleteSubscription`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
                body: JSON.stringify({
                    subscriptionId: mainSubId,

                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.message === "Subscription canceled successfully") {
                        history.push("/customer/customerdashboard");
                    }
                }).catch((err) => {
                    // setProcess(false)
                })
        }
    };

    // Define a new function to fetch cloud details without setting state
    const fetchCloudDetails = async () => {
        if (!customerInfo || !customerInfo.customer_id) {
            console.error('customerInfo or customerInfo.customer_id is undefined');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/cloud/Cloud/${customerInfo.customer_id}/${setupId}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
            });

            if (!response.ok) {
                console.error('API request failed', response);
                return;
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch cloud details', error);
        }
    };


    



    return (

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FullContainer className="mt-0" style={{ width: '100%' }}>
                <Container style={{ width: '100%' }}>
                    <h1 className="text-3xl font-bold text-center mb-10 mt-8">
                        Dantis Cloud Account {setupData.setupId}
                    </h1>
                    <div className="grid lg:grid-cols-1 gap-2 w-full mb-6">
                        <div className='col-span-12'>
                            <form onSubmit={(event) => {
                                handleSubmit3(event);
                                if (uploadedFile) {
                                    handleFileUpload(uploadedFile);
                                } else {
                                    console.log("No file selected for upload");
                                }
                            }}>
                                <div className='p-6 md:p-8 rounded-lg bg-cover bg-center bg-detailsOfAccount'>
                                    <ul className='list-disc grid grid-cols-1 capitalize'>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Login</p>
                                                <span className='tooltiptext'>This is your trading account login number. Usually same as account ID</span>
                                            </div>
                                            <input type="number" value={login} onChange={(e) => setLogin(e.target.value)} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                                        </li>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Password</p>
                                                <span className='tooltiptext'>This is your trading account password</span>
                                            </div>
                                            <input type={showPassword ? "text" : "password"} value={passWord} onChange={(e) => setPassWord(e.target.value)} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                                            <button className='btnSecondary4' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? 'Hide' : 'Show'}
                                            </button>
                                        </li>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Server</p>
                                                <span className='tooltiptext'>This is your trading account MT5 server</span>
                                            </div>
                                            <input type="text" value={serverAddress} onChange={(e) => setServerAddress(e.target.value)} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                                        </li>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Broker URL</p>
                                                <span className='tooltiptext'>This is your Broker's web URL </span>
                                            </div>
                                            <input type="text" value={source} onChange={(e) => setsource(e.target.value)} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                                        </li>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Type</p>
                                                <span className='tooltiptext'>Expert Advisor or Indicator</span>
                                            </div>
                                            <select name="type" value={type} onChange={(e) => setType(e.target.value)} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }}>
                                                <option value="">Select a type</option>
                                                <option value="Expert Advisor">Expert Advisor</option>
                                                <option value="Indicator">Indicator</option>
                                            </select>
                                        </li>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Platform</p>
                                                <span className='tooltiptext'>MT5 or MT4</span>
                                            </div>
                                            <select name="platform" value={platform} onChange={(e) => setPlatform(e.target.value)} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }}>
                                                <option value="">Select a platform</option>
                                                <option value="MT5">MT5</option>
                                                <option value="MT4">MT4</option>
                                            </select>
                                        </li>
                                        <li className='flex flex-col md:flex-row items-center justify-center'>
                                            <div className='tooltip'>
                                                <p className='mr-2'>Upload File</p>
                                                <span className='tooltiptext'>Choose the EA or Indicator file from your computer</span>
                                            </div>
                                            <input type="file" onChange={(e) => setUploadedFile(e.target.files[0])} className='mt-2 md:mt-3 rounded-lg' style={{ color: 'black' }} />
                                        </li>
                                        <li className='flex flex-col mt-3 md:flex-row items-center justify-center'>
                                            <button
                                                type="submit" className='btnSecondary3' onClick={() => alert('Inputs have been submitted! In a few minutes, please return to dashboard to see the Cloud URL.')}>
                                                Submit
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                            {/* cancel button */}
                            <button
                                className='btnSecondary3'
                                onClick={async () => {
                                    await cancelSubscription(setupData.subscription_id);
                                   
                                }}
                            >
                                Cancel subscription
                            </button>
                        </div>
                    </div>

                </Container>
            </FullContainer>
        </div>
    );
}


function CloudSetup() {
    const { token } = store.getState().auth;
    const [setups, setSetups] = useState([]);
    console.log({ setups })
    const history = useHistory();
    const [customerInfo, setCustomerInfo] = useState({});

    // Fetch customer info when component mounts
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/customer/customerInfo`, {
            headers: {
                "content-type": "application/json",
                authorization: token,
            },
        })
            .then(response => {
                setCustomerInfo(response.data);
                if (response.data.cloudSubscriptionStatus === false || null || undefined) {
                    // history.push("/customer/customerdashboard");
                }
            })
            .catch(error => {
                console.error('Error fetching customer info:', error);
            });
    }, []);

    /*// Fetch setups from the database when customerInfo is updated
    useEffect(() => {
        if (customerInfo.customer_id) {
            axios.get(`${process.env.REACT_APP_API_URL}/cloud/setups/${customerInfo.customer_id}`, {
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
            })
                .then(response => {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        setSetups(response.data);
                    } else {
                        // If no setups are returned for the customer_id, post a new setup with setupId 1
                        console.log('Trigger for use effects')
                        axios.post(`${process.env.REACT_APP_API_URL}/cloud/Cloud/1`, {
                            customer_id: customerInfo.customer_id,
                            setupId: '1',
                            email: customerInfo.email,
                            first_name: customerInfo.first_name,
                            last_name: customerInfo.last_name,
                            passWord: 'passWord',
                            serverAddress: 'serverAddress',
                            login: 123,
                            source: 'source',
                            fileType: 'type',
                            subscription_id:'',
                            kube_endpoint:'kube_endpoint',
                            // other setup data
                        }, {
                            headers: {
                                "content-type": "application/json",
                                authorization: token,
                            },
                        })
                            .then(response => {
                                setSetups([response.data]);
                                console.log({response})
                            })
                            .catch(error => {
                                console.error('Error creating initial setup:', error);
                            });
                    }
                })
                .catch(error => {
                    console.error('Error fetching setups:', error);
                });
        }
    }, [customerInfo]);*/
    // Fetch setups from the database when customerInfo is updated
    useEffect(() => {
        if (customerInfo.customer_id) {
            axios.get(`${process.env.REACT_APP_API_URL}/cloud/setups/${customerInfo.customer_id}`, {
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
            })
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setSetups(response.data);
                } else {
                    // If no setups are returned for the customer_id, post a new setup with setupId 1
                    axios.post(`${process.env.REACT_APP_API_URL}/cloud/Cloud/1`, {
                        customer_id: customerInfo.customer_id,
                        email: customerInfo.email,
                        first_name: customerInfo.first_name,
                        last_name: customerInfo.last_name,
                        passWord: 'passWord',
                        serverAddress: 'serverAddress',
                        login: 1234,
                        source: 'source',
                        fileType: 'type',
                        platform: 'platform',
                        setupId: '1',
                        
                        // other setup data
                    }, {
                        headers: {
                            "content-type": "application/json",
                            authorization: token,
                        },
                    })
                    .then(response => {
                        setSetups([response.data]);
                    })
                    .catch(error => {
                        console.error('Error creating initial setup:', error);
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching setups:', error);
            });
        }
    }, [customerInfo]);


    // Update setups in the database whenever it changes
    useEffect(() => {
        setups.forEach(setup => {
            if (setup.setupId) {
                axios.put(`${process.env.REACT_APP_API_URL}/cloud/setups/${setup.setupId}`, setup, {
                    headers: {
                        "content-type": "application/json",
                        authorization: token,
                    },
                })
                    .catch(error => {
                        console.error(`Error updating setup with ID ${setup.setupId}:`, error);
                    });
            } else {
                console.error('Error: setupId is undefined for setup', setup);
            }
        });
    }, [setups]);

    const addSetup = async (btn) => {
        console.log({btn})
        // const newId = setups.length + 1 ;
        let lastItem = setups[setups.length - 1];
        const newId = parseInt(lastItem.setupId) + 1;

        // setSetups([...setups, { id: newId /* other setup data */ }]);
        if (btn === 'Add Setup for $20'){
            //payment api for 20$ / monthly
            await takeToCloud(newId, buttonText);
        } else if (btn === 'Add Setup for $18'){
            //payment api for 18$ / monthly
            await takeToCloud(newId, buttonText);
        }
    };

    let buttonText = "Add Setup";
    if (setups.length === 1) {
        buttonText = "Add Setup for $20";
    } else if (setups.length >= 2) {
        buttonText = "Add Setup for $18";
    }




    //payment processing
    const [clientSecret, setClientSecret] = useState("");
    const [showModal, setShowModal] = useState(false);
    const appearance = {
        theme: 'night',
        variables: {
            colorPrimary: '#212d63',
            colorText: '#ffffff',
        },
    };
    const options = {
        clientSecret,
        appearance,
    };

    const startPaymentProcess = async (newId, buttonText) => {

        setShowModal(true)
        await fetch(`${process.env.REACT_APP_API_URL}/create-cloud-subscription-ml`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: token,
            },
            body: JSON.stringify({
                email: customerInfo.email,
                setupId:newId,
                buttonText
                
            }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
        })
            .then((res) => res.json())
            .then((data) => {
                setClientSecret(data.clientSecret);

                console.log('this is new account data', clientSecret)
            }).catch((err) => {
                // setProcess(false)
            })


    };
    const takeToCloud = async (newId, buttonText) => {
        await startPaymentProcess(newId, buttonText);

    };

    // saved card handle
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
    const [productData, setProductData] = useState({});
    const [expireDate, setExpireDate] = useState(null);
    console.log('Saved payment methods', savedPaymentMethods);
    const handlePaymentMethodSelect = (method) => {
        setSelectedPaymentMethod(method);
    };
    const stripe = useStripe();
    const handlePayWithSavedCard = async (id, setupId, buttonText) => {
        const result = window.confirm("Are you sure you want pay with this card , This cannot be undone?");

        if (result === true) {
            // User clicked OK
            console.log(id);

            await fetch(`${process.env.REACT_APP_API_URL}/create-cloud-subscription-ml-saved-card`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: token,
                },
                body: JSON.stringify({
                    email: customerInfo.email,
                    paymentMethodId: id,
                    setupId,
                    buttonText
                }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
            })
                .then((res) => res.json())
                .then(async (data) => {
                    setClientSecret(data.clientSecret);
                    if (
                        data.clientSecret
                    ) {
                        const { paymentIntent } = await stripe.handleCardPayment(data.clientSecret);
                        if (result.error) {
                            // Show error to your customer (e.g., insufficient funds)
                            alert("Error: " + result.error.message);
                            //console.log(result.error.message);

                            // this.setState({
                            //     isLoading: false
                            // })
                        } else {
                            // The payment has been processed!
                            if (paymentIntent.status === 'succeeded') {

                                window.alert("Successfully purchased")
                                setShowModal(false);
                                history.push("/customer/cloudsetup");

                            }
                        }
                    }
                    console.log('this is new account data', clientSecret)
                }).catch((err) => {
                    // setProcess(false)
                })



        } else {
            // User clicked Cancel or closed the dialog
            // Do nothing or handle accordingly
        }
    };

    const renderCardIcon = (brand) => {
        switch (brand.toLowerCase()) {
            case 'visa':
                return <FaCcVisa size={30} />;
            case 'mastercard':
                return <FaCcMastercard size={30} />;
            case 'amex':
                return <FaCcAmex size={30} />;
            default:
                return null;
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <button className='btnSecondary5 animate-pulse  mt-10 mb-5 mr-10' onClick={() => addSetup(buttonText)}>{buttonText}</button>
            {setups.map(setup =>
                <Setup key={setup.id} setupData={setup} />
            )}
            {/*<h2>{buttonText}</h2>*/}
            {/* Modal payment */}
            {/* Payment modal */}
            {showModal ? (
                <>
                    {/* Backdrop */}
                    <div className="fixed inset-0 z-50 bg-black opacity-25"></div>

                    {/* Modal container */}
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto flex-wrap fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full max-w-lg">
                            {/* Modal content */}
                            <div className="border border-gray-200 bg-[#1e142e] rounded-lg shadow-lg outline-none focus:outline-none">
                                {/* Header */}
                                <div className="flex items-center justify-between p-4 border-b border-solid border-gray-200 rounded-t">
                                    <h3 className="text-xl md:text-2xl font-semibold">Make Payment</h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 hover:text-black hover:bg-gray-100 rounded-full focus:outline-none focus:bg-gray-100 focus:text-black"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                {/* Body */}
                                <div className="p-4">
                                    <div className="mb-4">
                                        {/* Payment method selection */}
                                        <div className="flex flex-col space-y-2">
                                            <button
                                                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    handlePaymentMethodSelect('saved')
                                                    fetch(`${process.env.REACT_APP_API_URL}/getPaymentMethods`, {
                                                        method: "POST",
                                                        headers: {
                                                            "content-type": "application/json",
                                                            authorization: token,
                                                        },
                                                        body: JSON.stringify({

                                                            email: customerInfo.email,

                                                        }), //You need to pass customerId((Saved on database when account is created) and priceId (price id will be found on stripe products on stripe dashboard or also save price id on Db when creating new product on stripe via stripe create product api)
                                                    })
                                                        .then((res) => res.json())
                                                        .then((data) => {
                                                            if (data.paymentMethods.length === 0) {
                                                                window.alert("No payment methods,Please pay with new card")

                                                            }
                                                            setSavedPaymentMethods(data.paymentMethods)


                                                        }).catch((err) => {
                                                            // setProcess(false)
                                                        })
                                                }}
                                            >
                                                Pay with saved card
                                            </button>
                                            <button
                                                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                                                onClick={() => handlePaymentMethodSelect('new')}
                                            >
                                                Pay with new card
                                            </button>
                                            {
                                                selectedPaymentMethod === null ? <></> :
                                                    <>
                                                        <button
                                                            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                                                            onClick={() => handlePaymentMethodSelect(null)}
                                                        >
                                                            Back
                                                        </button>
                                                    </>
                                            }

                                        </div>

                                        {/* Saved card selection */}
                                        {/* Saved card selection */}
                                        {selectedPaymentMethod === 'saved' && (
                                            <div className="flex flex-col space-y-2 mt-4">
                                                {savedPaymentMethods?.map((method) => (
                                                    <div
                                                        onClick={() => {
                                                            let lastItem = setups[setups.length - 1];
                                                            const newId = parseInt(lastItem.setupId) + 1;
                                                            handlePayWithSavedCard(method.id, newId, buttonText)

                                                        }}
                                                        className="border justify-between flex space-x-3  items-center cursor-pointer p-1 rounded-md border-l-white" key={method.id}>
                                                        {renderCardIcon(method.brand)} {/* Render card icon */}

                                                        <span className="">Ending with {method.last4} Expires at {method.expMonth}/{method.expYear}</span>
                                                        {/* <button> <FaTrash></FaTrash></button> */}
                                                    </div>



                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {/* Payment form for new card */}
                                    {selectedPaymentMethod === 'new' && clientSecret && (
                                        <Elements options={options} stripe={stripePromise}>
                                            <CheckoutForm clientSecretNew={clientSecret} email={customerInfo.email} userName={customerInfo.first_name} />
                                        </Elements>
                                    )}
                                </div>

                                {/* Footer */}
                                <div className="flex items-center justify-end p-4 border-t border-solid border-gray-200 rounded-b">
                                    <button
                                        className="text-red-500 bg-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-2 rounded hover:bg-gray-100 focus:bg-gray-100"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
}




export default CloudSetup;

const FAQCARD = ({ title, text }) => {
    const [showFullText, setShowFullText] = useState(false);

    return (
        <div className="flex flex-col bg-[#302D41] py-5 px-10" style={{ width: '100%' }}>
            <h1 className="text-xl lg:text-3xl font-bold uppercase my-5">{title}</h1>
            <p>{text}</p>
            {/* {text.split(" ").length > 20 && (
        <button
          onClick={() => setShowFullText(!showFullText)}
          className="w-[20%] btnSecondary mt-5 ml-auto"
        >
          {showFullText ? "Less" : "More"}
        </button>
      )} */}
        </div>
    );
};